import React from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/components/boxSelection.module.css";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { OddsDisplayEnum } from "generated/graphql";
import { renderDecimalOdds } from "util/miscUtils";

interface BoxSelectionProps {
  smallBoxPadding?: boolean;
  hideTitle?: boolean;
  smallText?: boolean;
  title?: string;
  active?: boolean;
  numberSelectorForTextSize?: number;
  value?: string | number;
  isClicked?: boolean;
  disableClick?: boolean;
  fixedHeight?: boolean;
  onClick?: () => void;
  [key: string]: any; // For additional props
}

const BoxSelection: React.FC<BoxSelectionProps> = React.memo(
  ({
    smallBoxPadding = false,
    hideTitle = false,
    smallText = false,
    title = "",
    active = false,
    disableClick = false,
    numberSelectorForTextSize = 5,
    value = "",
    isClicked = false,
    fixedHeight = false,
    onClick = () => {},
    ...props
  }) => {
    const { selectedOddsDisplay } = useGameStore(
      (state) => ({
        selectedOddsDisplay: state.selectedOddsDisplay,
      }),
      shallow,
    );

    // this is to format decimal value like if the value is 4.2 then format to  4.20 if the value is 4 then render to 4.00
    const formattedValue = value
      ? selectedOddsDisplay === OddsDisplayEnum.Decimal
        ? renderDecimalOdds(value)
        : value
      : "-";
    let typographySize = "";

    if (smallBoxPadding) {
      // below condition is only first first/last/anytime odds due to small box sizes
      const length = formattedValue?.toString().length;

      if (length > 7) {
        typographySize = "xs3";
      } else if (length > 5) {
        typographySize = "xs2";
      } else if (length > numberSelectorForTextSize) {
        typographySize = "xs";
      } else {
        typographySize = "lg";
      }
    } else {
      typographySize = formattedValue?.toString().length > numberSelectorForTextSize ? "xs" : "lg";
    }

    return (
      <section
        className={classNames(styles.boxRoot, {
          [styles.avoidClicking]: disableClick,
          [styles.active]: active,
          [styles.smBoxPadding]: smallBoxPadding,
          [styles.disableClick]: isClicked,
          [styles.fixedHeight]: fixedHeight,
        })}
        onClick={onClick}
        {...props}
      >
        {isClicked ? (
          <img
            className={classNames(styles.imgIcon, {
              [styles.smallSizeLoader]: smallBoxPadding,
            })}
            src={`icon/${active ? "dotsSpinningLoaderPurpleBlue" : "dotsSpinningLoader"}.gif`}
            alt="loading gif"
          />
        ) : (
          <div className={styles.boxInnerContent}>
            <Typography
              hide={hideTitle}
              size={title.length > 9 ? "xs3" : "xs"}
              color={active ? "navyBlue" : "gray"}
              fontFamily={active ? "avenirNextMedium" : "avenirFont"}
              isEllipsis={true}
              width="100%"
              isCapitalizeStartLetter
            >
              {title}
            </Typography>
            <Typography
              size={typographySize}
              color={active ? "navyBlue" : "white"}
              fontFamily={active ? "avenirNextBold" : "avenirNextSemiBold"}
            >
              {formattedValue}
            </Typography>
          </div>
        )}
      </section>
    );
  },
);

export default React.memo(BoxSelection);
