export interface EventPayload {
  msg?: string;
  translationKey?: string;
  success?: boolean;
  error?: any;
  data?: Record<string, any>;
  [key: string]: any;
}

interface AppEventProps {
  eventType: string;
  payload?: EventPayload;
}

type CallbackFunction = (event: CustomEvent<EventPayload>) => void;

class AppEvent {
  dispatch({ eventType, payload }: AppEventProps): void {
    window.dispatchEvent(new CustomEvent(eventType, { detail: { ...payload } }));
  }

  listen(eventType: string, callback: CallbackFunction = () => {}): void {
    window.addEventListener(eventType, callback as EventListener);
  }

  remove(eventType: string, callback: CallbackFunction = () => {}): void {
    window.removeEventListener(eventType, callback as EventListener);
  }
}

const appEventInstance = new AppEvent();

export default appEventInstance;
