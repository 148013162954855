import { useState } from "react";
import classNames from "classnames";
import ContentShell from "components/ContentShell";
import BetSlip from "pages/bets/components/BetSlip";
import styles from "styles/pages/bets/bets.module.css";
import Typography from "components/Typography";
import { DateFilterEnum, useCoinHistoryQuery, useMyBetsQuery } from "generated/graphql";
import useGameStore from "store/gameStore";
import { loaderTypes } from "core/enums";
import gameJson from "config/gameJSON.json";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import Button from "components/Button";
import sessionHelper from "service/sessionHelper";

const Bets = () => {
  const [myBetsHistory, setMyBetsHistory] = useState<Record<string, any[]>>({});
  const [pbfCoinHistory, setPbfCoinHistory] = useState<Record<string, any[]>>({});
  const [hasMoreBets, setHasMoreBets] = useState(false);
  const { setShowLoader, showLoader } = useGameStore((state) => ({
    setShowLoader: state.setShowLoader,
    showLoader: state.showLoader,
  }));
  const [betsFilters, setBetsFilter] = useState({
    betStatus: null,
    dateFilter: DateFilterEnum.Today,
    matchStatus: null,
    matchId: null,
    sortDir: null,
    sortKey: null,
    skipPagination: null,
    limit: 5,
    pageNo: 1,
  });

  const [isShowBetHistory, setIsShowBetHistory] = useState(true);

  const {
    data: myCoinsHistoryData,
    loading,
    error,
  } = useCoinHistoryQuery({
    variables: betsFilters,
  });

  const {
    data: myBetsData,
    loading: myBetsLoading,
    error: myBetsError,
  } = useMyBetsQuery({
    variables: betsFilters,
    skip: !isShowBetHistory,
    onCompleted: (data) => {
      setShowLoader({ isTrue: true, loaderFor: loaderTypes.default });
      const groupedData = data?.players?.myBets?.items?.reduce((acc: any, item: any) => {
        const date = item.createdAt.split("T")[0]; // Extract only the date part
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {});

      setMyBetsHistory(groupedData);
      setHasMoreBets(data?.players?.myBets?.items?.length === betsFilters.limit);
    },
  });

  const {
    data: myPbfCoinData,
    loading: pbfCoinLoading,
    error: pbfCoinError,
  } = useCoinHistoryQuery({
    variables: betsFilters,
    skip: isShowBetHistory,
    onCompleted: (data: any) => {
      const groupedData = data.players?.coinHistory?.items?.reduce((acc: any, item: any) => {
        const date = item.createdAt.split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {});

      setPbfCoinHistory(groupedData);
      setHasMoreBets(data?.players?.coinHistory?.items?.length === betsFilters.limit);
    },
  });

  const [activeTabId, setActiveTabId] = useState(gameJson.betHistoryFilterOptions[0].id);
  const onTabClick = (tabId: any) => {
    setMyBetsHistory({});
    setPbfCoinHistory({});
    setHasMoreBets(false);
    setActiveTabId(tabId);
    setBetsFilter((prev) => {
      return { ...prev, dateFilter: tabId, limit: 5 };
    });
  };

  // return if got an error .....
  if (myBetsError || pbfCoinError) {
    return (
      <section className={classNames(styles.centerErrorMsg)}>
        <Typography color="white" fontFamily="avenirNextBold" size="lg">
          {getContent(translationKeys.MY_BETS_ERROR)}
        </Typography>
      </section>
    );
  }

  const isBetAvailable = () => {
    let myBetsItem = isShowBetHistory ? myBetsData?.players?.myBets : myPbfCoinData?.players?.coinHistory;
    return myBetsItem?.items?.length === 0;
  };

  const handleLoadMore = () => {
    setBetsFilter((prev) => ({
      ...prev,
      limit: prev.limit + 5,
    }));
  };
  return (
    <ContentShell loading={showLoader.isTrue && showLoader.loaderFor === loaderTypes.betHistory}>
      <section className={classNames(styles.betSlipRoot)}>
        <div className={classNames(styles.betSlipHeader)}>
          <div
            className={classNames(styles.tab, {
              [styles.activeTab]: isShowBetHistory,
              [styles.inActiveTab]: !isShowBetHistory,
            })}
            onClick={() => setIsShowBetHistory(true)}
          >
            <Typography
              fontFamily="avenirNextSemiBold"
              color="white"
              size={getContent(translationKeys.BET_HISTORY).length > 9 ? "xs" : "md"}
              noWrap={true}
              isEllipsis={true}
              width="100%"
            >
              {getContent(translationKeys.BET_HISTORY)}{" "}
            </Typography>{" "}
          </div>
          <div
            className={classNames(styles.tab, {
              [styles.activeTab]: !isShowBetHistory,
              [styles.inActiveTab]: isShowBetHistory,
              [styles.hide]: !sessionHelper?.coinPurchaseAllowed,
            })}
            onClick={() => setIsShowBetHistory(false)}
          >
            <Typography fontFamily="avenirNextSemiBold" color="white" size="lg">
              {getContent(translationKeys.PBF_COINS)}
            </Typography>
          </div>
          <Typography fontFamily="avenirNextSemiBold" color="white" size="lg"></Typography>
        </div>
        <section
          className={classNames(styles.betsFilterList, {
            [styles.showHalfOlderTab]: activeTabId === DateFilterEnum.Last30Days,
            [styles.showFullOlderTab]: activeTabId === DateFilterEnum.Older,
          })}
        >
          {gameJson.betHistoryFilterOptions.map((data, tabsIndex) => (
            <div
              key={`${tabsIndex}-${data.id}`}
              onClick={() => onTabClick(data.id)}
              className={classNames(styles.list, {
                [styles.active]: data.id === activeTabId,
              })}
            >
              <Typography
                noWrap={true}
                color={data.id === activeTabId ? "purpleBlue" : "white"}
                fontFamily="avenirNextBold"
                size="md"
              >
                {getContent(data.label, [`${data?.days ? data?.days : ""}`] as any)}
              </Typography>
            </div>
          ))}
        </section>

        <section
          className={classNames(styles.betSlipContainer, {
            [styles.centerItems]: myBetsError || pbfCoinError,
          })}
        >
          {isBetAvailable() ? (
            <section className={classNames(styles.centerErrorMsg)}>
              <Typography color="white" size="md" fontFamily="avenirNextBold">
                {getContent(translationKeys.MY_BETS_NOT_FOUND)}
              </Typography>
            </section>
          ) : (
            <>
              {Object?.entries(isShowBetHistory ? myBetsHistory : pbfCoinHistory)?.map(([date, bets]) => {
                return (
                  <div className={styles.dateSlipHeader} key={`${date}-${bets.length}`}>
                    <header
                      className={classNames(styles.dateTime, {
                        [styles.hide]: activeTabId == DateFilterEnum.Today || !bets.length,
                      })}
                    >
                      <Typography color="white" size="md" fontFamily="avenirNextBold">
                        {date}
                      </Typography>
                    </header>

                    {bets.map((data: any, index: any) => {
                      if (bets[index]?.bets?.length === 0) return null; // Skip items with no bets
                      return (
                        <BetSlip
                          key={`${data.matchId}-${data.createdAt}`}
                          betSlipData={data as any}
                          isShowBetHistory={isShowBetHistory}
                          pbfCoinsData={data as any}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
          {hasMoreBets && (
            <div className={styles.loadMore}>
              <Button onClick={handleLoadMore} disabled={myBetsLoading || pbfCoinLoading}>
                <Typography color="navyBlue" fontFamily="degularBold" size="lg">
                  {myBetsLoading || pbfCoinLoading
                    ? `${getContent(translationKeys.LOADING_TITLE)} ...`
                    : getContent(translationKeys.LOAD_MORE)}
                </Typography>
              </Button>
            </div>
          )}
        </section>
      </section>
    </ContentShell>
  );
};

export default Bets;
