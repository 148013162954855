import { ApolloProvider } from "@apollo/client";
import { client } from "apollo";
import AppShell from "components/AppShell";
import NotificationProvider from "components/Notifications";
import SessionHelper from "components/SessionHelper";
import { HashRouter } from "react-router-dom";
import Router from "router/Router";
import "./App.css";

function App() {
  return (
    <HashRouter>
      <ApolloProvider client={client}>
        <SessionHelper>
          <NotificationProvider>
            <AppShell>
              <Router />
            </AppShell>
          </NotificationProvider>
        </SessionHelper>
      </ApolloProvider>
    </HashRouter>
  );
}

export default App;
