export const translationKeys = {
  ContinueText: "ContinueText",
  Vault_XP: "vaultXP",
  OVR_TEXT: "OvrTxt",
  VAULT_XP_UNLOCK_MESSAGE: "vaultXpUnlockMessage",
  OK_TEXT: "okText",
  DATA_NOT_FOUND: "notFound",
  ATTACK_TITLE: "AttackTitle",
  CREATIVITY_TITLE: "CreativityTitle",
  CHEMISTRY_TITLE: "ChemistryTitle",
  DEFENCE_TITLE: "DefenceText",
  ADD_MORE_BETS: "addMoreBets",
  AMERICAN: "American",
  AND_BTTS: "andBtts",
  ANYTIME_GOAL_SCORER: "AnyTimeGoalScorer",
  ANY_TIME: "anytime",
  ATT: "ATT",
  AUDIO: "audio",
  AWARDER: "awarded",
  BADGES: "BADGES",
  BACK_Text: "back",
  BETTING: "betting",
  BETTING_TEXT1: "bettingText1",
  BETTING_TEXT2: "bettingText2",
  BETTING_TEXT3: "bettingText3",
  BETTING_TEXT4: "bettingText4",
  BETTING_TEXT5: "bettingText5",
  BETTING_TEXT6: "bettingText6",
  BETTING_TEXT7: "bettingText7",
  BETTING_TEXT8: "bettingText8",
  BETTING_TEXT9: "bettingText9",
  BET_HISTORY: "betHistory",
  BET_PLACE_VIEW: "betPlaceView",
  BET_PLACED: "betPlaced",
  BET_REF: "betRef",
  BOOST: "boost",
  BOOSTS: "boosts",
  BOOSTS_TEXT: "boostsText",
  BOOSTS_TEXT1: "boostsText1",
  BOOSTS_TEXT2: "boostsText2",
  BOOSTS_TEXT3: "boostsText3",
  BOOSTS_TEXT4: "boostsText4",
  BOOSTS_TEXT5: "boostsText5",
  BOOSTS_TEXT6: "boostsText6",
  BOTH_TEAMS_TO_SCORE: "bothTeamsToScore",
  BTTS: "btts",
  CHEMISTRY_POINTS: "chemistryPoints",
  CORRECT_SCORE: "correctScore",
  DAILY_REAWRDS: "dailyRewards",
  DAILY_REWARDS_TEXT: "dailyRewardsText",
  DECIMAL: "Decimal",
  DESCRITPION: "description",
  DIVISIONS: "divisions",
  DIVISIONS_TEXT: "divisionsText",
  EURO: "euro",
  EXIT_GAME: "exit",
  FINAL_RESULT: "finalResult",
  FIRST: "first",
  FIRST_GOALSCORER: "firstGoalScorer",
  FIRST_GOALSCORER_CORRECT_SCORE: "firstGoalScorerCorrectScore",
  FIRST_LAST_ANYTIME_GOALSCORER: "firstLastAnytimeGoalScorer",
  FIRST_LAST_ANYTIME_GOALSCORER_TYPE: "firstLastAnytimeGoalScorerType",
  FIRST_PLAYER_CORRECTSCORE: "firstPLayerCorrectScore",
  FIRST_PLAYER_SCORE: "firstPlayerScore",
  FOR_EVERY: "forEvery",
  xp_AWARDED: "xpAwarded",
  FRACTIONAL: "Fractional",
  GAME_RULE: "gameRule",
  GOALS_OVER_UNDER: "goalsOverUnder",
  GREATER_THAN: "greaterThan",
  HANDICAPS: "handicaps",
  HELP: "help",
  ILLEGAL_POSITION: "illegalPosition",
  ILLEGAL_POSITION_DESC: "illegalPositionDesc",
  INFORMATION: "information",
  KITS: "KITS",
  LAST: "last",
  LAST_DAYS: "lastDays",
  LAST_GOALSCORER: "lastGoalScorer",
  LETTER_V: "v",
  MARKET: "market",
  MAX_PAYOUT: "Max Payout",
  MAX_STAKE: "Max Stake",
  MECHANICS: "mechanics",
  MECHANICS_TEXT1: "mechanicsText1",
  MECHANICS_TEXT2: "mechanicsText2",
  MECHANICS_TEXT3: "mechanicsText3",
  MECHANICS_TEXT4: "mechanicsText4",
  MECHANICS_TEXT5: "mechanicsText5",
  MECHANICS_TEXT6: "mechanicsText6",
  MECHANICS_TEXT7: "mechanicsText7",
  MECHANICS_TEXT8: "mechanicsText8",
  MECHANICS_TEXT9: "mechanicsText9",
  MIN_STAKE: "Min Stake",
  MY_BETS_ERROR: "myBetsError",
  MY_BETS_NOT_FOUND: "myBetsNotFound",
  MY_TEAM: "myTeam",
  MYTEAM_TEXT1: "myTeamText1",
  MYTEAM_TEXT2: "myTeamText2",
  MYTEAM_TEXT3: "myTeamText3",
  MYTEAM_TEXT4: "myTeamText4",
  MYTEAM_TEXT5: "myTeamText5",
  MYTEAM_TEXT6: "myTeamText6",
  MYTEAM_TEXT7: "myTeamText7",
  MYTEAM_TEXT8: "myTeamText8",
  MYTEAM_TEXT9: "myTeamText9",
  MYTEAM_TEXT10: "myTeamText10",
  MYTEAM_TEXT11: "myTeamText11",
  NO: "no",
  NOT_AWARDED: "notAwarded",
  NOT_FOUND: "notFound",
  ODDS: "Odds",
  ODDS_DISPLAY: "oddsDisplay",
  ODDS_TEXT: "odds",
  OLDER: "older",
  OVER: "over",
  OVERVIEW: "overview",
  OVERVIEW_TEXT1: "overViewText1",
  OVERVIEW_TEXT2: "overViewText2",
  PBF_COINS: "pbfCoins",
  PBF_COINS_TEXT: "pbfCoinsText",
  PBF_COINS_TEXT_ALT: "pbfCoinsTextAlt",
  PBF_COINS_TEXT1: "pbfCoinsText1",
  PBF_COINS_TEXT2: "pbfCoinsText2",
  PBF_COINS_TEXT3: "pbfCoinsText3",
  PLAYER_POSITION_AND_CHEM: "playerPositionAndChem",
  PLAYER_POSITION_TEXT: "playerPositionText",
  PLAYER_RATING: "playerRating",
  PLAYER_RATING_TEXT: "playerRatingText",
  PLAYER_TO_SCORE: "playerToScore",
  PLACE_BET: "placeBet",
  PLAYING_MATCH: "playingMatch",
  TEAM_CONFIGURATION: "teamConfiguration",
  TEAM_CONFIGURATION_TEXT: "teamConfigurationText",
  PLAYING_MATCH_TEXT: "playingMatchText",
  POINTS_MULTIPLIER: "pointsMultiplier",
  PRIMARY_POSITION: "primaryPosition",
  PRIMARY_POSITION_DESC: "primaryPositionDesc",
  RESET_GAME_BTN: "ResetGameBtn",
  RESULT_BOTH_TEAMS_SCORE: "resultBothTeamsScore",
  RTP_TEXT: "rtP",
  RTP_S: "rtps",
  RTP_VALUE1: "rtpValue1",
  RTP_VALUE2: "rtpValue2",
  RTP_VALUE3: "rtpValue3",
  RTP_VALUE4: "rtpValue4",
  RTP_VALUE5: "rtpValue5",
  RTP_VALUE6: "rtpValue6",
  RTP_VALUE7: "rtpValue7",
  RTP_VALUE8: "rtpValue8",
  RTP_VALUE9: "rtpValue9",
  SECONDARY_POSITION: "secondaryPosition",
  SECONDARY_POSITION_DESC: "secondaryPositionDesc",
  SELECT: "select",
  SELECTION: "selection",
  SELECTIONS: "selections",
  SETTINGS: "setting",
  SHOW_LESS: "showLess",
  SHOW_MORE: "showMore",
  SOUNDS: "sounds",
  STAKED: "staked",
  STAKE_TITLE: "StakeTitle",
  START_MATCH: "startMatch",
  STORES_TEXT: "storeText",
  STORES_TEXT_ALT: "storeTextAlt",
  STORES: "stores",
  TEAM_CHEMISTRY_RATING: "teamChemistryRating",
  TEAM_OVER_EFFECT: "teamOvrEffect",
  TERITARY_POSITION: "tertiaryPosition",
  TERITARY_POSITION_DESC: "tertiaryPositionDesc",
  THE_VAULT: "TheVaultText",
  TO_RETURN_TITLE: "ToReturnTitle",
  UNDER: "under",
  UNDER_OVER: "underOver",
  UP_TO: "upTo",
  VAULT_XP_BOOST: "vaultXpBoost",
  WIN_DRAW_WIN: "winDrawWin",
  XP: "xpText",
  XP_VALUE_1: "xpValue1",
  XP_VALUE_2: "xpValue2",
  XP_BOOST: "xpBoost",
  XP_BOOSTED_INFO: "xpBoostedInfo",
  DRAW_TEXT: "draw",
  YES: "yes",
  GAME_RULES_TITLE: "gameRule",
  NO_GOAL_SCORER: "noGoalScorer",
  GEN01: "GEN01",
  GEN03: "GEN03",
  GEN04: "GEN04",
  GEN05: "GEN05",
  GEN06: "GEN06",
  GEN09: "GEN09",
  PROF01: "PROF01",
  Stake00: "Stake00",
  Stake01: "Stake01",
  Stake02: "Stake02",
  Stake03: "Stake03",
  Stake04: "Stake04",
  Stake05: "Stake05",
  MoreDigitsError: "MoreDigitsError",
  LessCharError: "LessCharError",
  ProfanityError: "ProfanityError",
  OVER_ALL_TEXT: "overallText",
  My_TEAM_ATTACK_VALUE: "myTeamAttackValue",
  My_TEAM_DEFENCE_VALUE: "myTeamDefenceValue",
  My_TEAM_CREATIVITY_VALUE: "myTeamCreativityValue",
  PROMO_PACKS_TITLE: "promoPacks",
  PROMO_PACKS_TEXT: "promoPacksText",
  END_OF_SEASON_TITLE: "endOfSeasonTitle",
  END_OF_SEASON_TEXT: "endOfSeasonText",
  MIN_BET_WARN_MSG: "minBetWarnMsg",
  MAX_BET_WARN_MSG: "maxBetWarnMsg",
  MAX_PAYOUT_WARN_MSG: "maxPayoutWarnMsg",
  RTP_TITLE: "RTP",
  RTP_TITLE_TEXT: "rtpTitleText",
  COIN_PURCHASE_TITLE: "coinsPurchaseTitle",
  PBF_COINS_TITLE: "coinTitle",
  PBF_COIN_REF: "pbfCoinRef",
  VALUE_TTTLE: "value",
  SPENT_TITLE: "spentTitle",
  RESOLUTION_MSG: "resolutionMsg",
  // new added for LeaderBoard
  RANK_TITLE: "rankTitle",
  CHAMPIONSHIP_TITLE: "championShipTitle",
  COUNTRY_TITLE: "countryTitle",
  GLOBAL_TITLE: "globalTitle",
  CHAMPION_TITLE: "ChampionText",
  DIVISION_TITLE: "DivisionTitle",
  TEAM_TEXT: "TeamText",
  VOID_BET: "voidBet",
  LOAD_MORE: "loadMore",
  LOADING_TITLE: "loadingTitle",
  SESSION_EXPIRED: "sessionExpired",
  GENERIC_ERROR_MESSAGE: "GenericErrorMessage",
  VOID_TITLE_TEXT: "voidTitleText",
};
