import { logEvent } from "firebase/analytics";
import { AnalyticsEventParams, AnalyticsEvents } from "../analyticTypes/analyticsEventTypes";
import { getAnalyticsInstance } from "../firebase/firebaseConfig";
import sessionHelper from "./sessionHelper";

class FirebaseAnalytics {
  private static instance: FirebaseAnalytics;

  private constructor() {
    if (typeof window !== "undefined") {
      // Expose trackEvent globally for iframe and external scripts
      (window as any).trackEvent = this.trackEvent.bind(this);
    }
  }

  static getInstance(): FirebaseAnalytics {
    if (!FirebaseAnalytics.instance) {
      FirebaseAnalytics.instance = new FirebaseAnalytics();
    }
    return FirebaseAnalytics.instance;
  }

  trackEvent<T extends AnalyticsEvents>(eventName: T, eventParams?: AnalyticsEventParams) {
    const useAnalytics = sessionHelper.useAnalytics;

    if (!useAnalytics) {
      console.log(`[Analytics Event]: ${eventName}`, eventParams);
      return;
    }

    const analytics = getAnalyticsInstance();

    if (analytics) {
      logEvent(analytics, eventName, eventParams);
    }
  }
}

export default FirebaseAnalytics.getInstance();
