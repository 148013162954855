import useGameStore from "store/gameStore";

// Function to call getContent from the iframe
const getContent = (str: string, arr: string[] = [], toUpperCase = false) => {
  const iframeRef = useGameStore.getState().iframeRef;

  if (!iframeRef) {
    return str;
  }

  const iframeWindow: any = iframeRef?.contentWindow;

  if (
    iframeWindow &&
    //@ts-ignore
    iframeWindow?.getContent
  ) {
    // Call getContent directly from the iframe's global scope
    return iframeWindow.getContent(str, arr, toUpperCase);
  } else {
    console.error("getContent is not available on the iframe window.");
  }
};

export { getContent };
