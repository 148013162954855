import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/goalScorerOdds.module.css";
import { CardRarityEnum, OddsDisplayEnum } from "generated/graphql";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import sessionHelper from "service/sessionHelper";
import { debug, getTeamName } from "util/miscUtils";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { matchStatusEnums } from "core/enums";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";

const GoalScorerOdds = () => {
  let goalScorerOddsDetails = sessionHelper.oddsDetails?.goalscorerOdds;
  let keyName = goalScorerOddsDetails?.name as string;

  const { handleBoxClick, isAddingBetLoading, isRemovingBetLoading, error } = useHandleAddUpdateOdds();
  const selectedGoalScorerOddsOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions, selectedOddsDisplay, matchStatus, setActiveOddForEnteringStake } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      selectedOddsDisplay: state.selectedOddsDisplay,
      matchStatus: state.matchStatus,
      setActiveOddForEnteringStake: state.setActiveOddForEnteringStake,
    }),
    shallow,
  );

  const [openContent, setOpenContent] = useState(true);
  const [loadingIds, setLoadingIds] = useState(new Set()); // Tracks loading state by item ID
  const [showMore, setShowMore] = useState(false);

  const maxVisibleItems = 4; // Define how many items to show initially
  const renderData = showMore
    ? goalScorerOddsDetails?.data || []
    : goalScorerOddsDetails?.data.slice(0, maxVisibleItems) || [];

  const renderPlayer = (id: string, playerTeamId: string) => {
    const fetchPlayerDetails = sessionHelper.oddsDetails?.allPlayers.find(
      (el) => el.playerId === id && playerTeamId === el.teamId,
    );

    // if no player details found, show no goalscorer (no goal scorer)
    if (!fetchPlayerDetails?.playerId) {
      return (
        <section className={classNames(styles.playerDetails)}>
          <div
            className={classNames(styles.attRating, {
              [styles.bgBronze]: true,
            })}
          >
            <Typography size="lg" color={"black"} fontFamily="degularBlack">
              -
            </Typography>
          </div>
          <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
            {getContent(translationKeys.NO_GOAL_SCORER)}
          </Typography>
        </section>
      );
    }

    const { teamId, name, attackRating, rarity } = fetchPlayerDetails;
    return (
      <section className={classNames(styles.playerDetails)}>
        <div
          className={classNames(styles.attRating, {
            [styles.bgBronze]: rarity === CardRarityEnum.Bronze,
            [styles.bgSilver]: rarity === CardRarityEnum.Silver,
            [styles.bgGold]: rarity === CardRarityEnum.Gold,
            [styles.bgInForm]: rarity === CardRarityEnum.InForm,
          })}
        >
          <Typography size="xs3" color={rarity === CardRarityEnum.InForm ? "white" : "black"} fontFamily="avenirFont">
            {getContent(translationKeys.ATT)}
          </Typography>
          <Typography
            size="md"
            color={rarity === CardRarityEnum.InForm ? "white" : "black"}
            fontFamily="avenirNextBold"
          >
            {attackRating}
          </Typography>
        </div>
        <div className={styles.teamDetails}>
          <Typography color="grayPlus" size="md" fontFamily="avenirFont">
            {name}
          </Typography>{" "}
          <Typography
            color="grayPlus"
            size={getTeamName(teamId as string).length > 9 ? "xs2" : "xs"}
            fontFamily="avenirFont"
            isEllipsis={true}
            width="100%"
          >
            {getTeamName(teamId as string)}
          </Typography>
        </div>
      </section>
    );
  };

  debug({ event: "calling", message: "Goal scorere odds" });

  const handleUserInteraction = async (id: string, keyName: string) => {
    if (matchStatus === matchStatusEnums.betPlaced) return;
    try {
      setLoadingIds((prev) => new Set(prev).add(id)); // Mark the specific box as loading
      let resp = await handleBoxClick(id, keyName, [...selectedGoalScorerOddsOptions]);
      setSelectedOddsOptions(resp || [], keyName);
      let getUpdatedOddsOptions = useGameStore.getState();
      const result = Object.entries(getUpdatedOddsOptions.selectedOddsOptions).find(([_, value]) => value.length);
      if (result) {
        //  the result will be like ["key", [array of values]];
        let activeInputBoxDetails = result[1][0];
        let marketName = result[0];
        // store the data structure as "First / Last / Anytime Goalscorer" for only this particular odd not like first Goalscorer , last Goalscorer , Anytime Goalscorer...
        if (marketName === "First / Last / Anytime Goalscorer") {
          marketName = result[0];
        } else {
          marketName = activeInputBoxDetails?.marketName;
        }

        setActiveOddForEnteringStake({
          oddId: activeInputBoxDetails?.oddId,
          key: marketName,
          betId: activeInputBoxDetails?.betId,
        });
      } else {
        setActiveOddForEnteringStake({ oddId: "", key: "", betId: "" });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingIds((prev) => {
        const updated = new Set(prev);
        updated.delete(id); // Remove loading state for the box
        return updated;
      });
    }
  };

  return (
    <section className={styles.root}>
      <Accordion
        open={openContent}
        boxHeading={getContent(translationKeys.FIRST_LAST_ANYTIME_GOALSCORER) || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
        showAll={showMore}
      >
        <div className={styles.colRowContainer}>
          <div className={styles.row}>
            <div className={styles.col}></div>
            <div className={styles.col}>
              <Typography color="grayPlus" size={"xs"} fontFamily="avenirFont">
                {getContent(translationKeys.FIRST)}
              </Typography>
            </div>
            <div className={styles.col}>
              <Typography color="grayPlus" size={"xs"} fontFamily="avenirFont">
                {getContent(translationKeys.LAST)}
              </Typography>
            </div>
            <div className={styles.col}>
              <Typography color="grayPlus" size={"xs"} fontFamily="avenirFont" isEllipsis={true} width="100%">
                {getContent(translationKeys.ANY_TIME)}
              </Typography>
            </div>
          </div>
          {renderData.map((oddDetails, oddIndex) => (
            <div key={`${oddIndex}-${oddDetails.teamId}`} className={styles.row}>
              <div className={styles.col}>
                {renderPlayer(oddDetails.playerId?.toLocaleString() as any, oddDetails.teamId as any)}
              </div>
              <div className={styles.col}>
                <BoxSelection
                  smallText={true}
                  smallBoxPadding={true}
                  value={
                    selectedOddsDisplay
                      ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                        ? oddDetails.first?.fractionalOdd?.toString() || ""
                        : selectedOddsDisplay === OddsDisplayEnum.Decimal
                          ? oddDetails.first?.decimalOdd?.toString() || ""
                          : oddDetails.first?.americanOdd?.toString() || ""
                      : ""
                  }
                  numberSelectorForTextSize={4}
                  onClick={() => handleUserInteraction(oddDetails.first?.id as string, keyName)}
                  active={
                    selectedGoalScorerOddsOptions.length &&
                    selectedGoalScorerOddsOptions?.some((ele) => ele.oddId === oddDetails?.first?.id)
                      ? true
                      : false
                  }
                  isClicked={loadingIds.has(oddDetails.first?.id)} // Check if this box is in a loading state
                />
              </div>
              <div className={styles.col}>
                <BoxSelection
                  smallText={true}
                  smallBoxPadding={true}
                  value={
                    selectedOddsDisplay
                      ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                        ? oddDetails.last?.fractionalOdd?.toString() || ""
                        : selectedOddsDisplay === OddsDisplayEnum.Decimal
                          ? oddDetails.last?.decimalOdd?.toString() || ""
                          : oddDetails.last?.americanOdd?.toString() || ""
                      : ""
                  }
                  numberSelectorForTextSize={4}
                  onClick={() => handleUserInteraction(oddDetails.last?.id as string, keyName)}
                  active={
                    selectedGoalScorerOddsOptions.length &&
                    selectedGoalScorerOddsOptions?.some((ele) => ele.oddId === oddDetails?.last?.id)
                      ? true
                      : false
                  }
                  isClicked={loadingIds.has(oddDetails.last?.id)} // Check if this box is in a loading state
                />
              </div>
              <div className={styles.col}>
                <BoxSelection
                  smallText={true}
                  smallBoxPadding={true}
                  value={
                    selectedOddsDisplay
                      ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                        ? oddDetails.any?.fractionalOdd?.toString() || ""
                        : selectedOddsDisplay === OddsDisplayEnum.Decimal
                          ? oddDetails.any?.decimalOdd?.toString() || ""
                          : oddDetails.any?.americanOdd?.toString() || ""
                      : ""
                  }
                  disableClick={isAddingBetLoading || isRemovingBetLoading}
                  numberSelectorForTextSize={4}
                  onClick={() => handleUserInteraction(oddDetails.any?.id as string, keyName)}
                  active={
                    selectedGoalScorerOddsOptions.length &&
                    selectedGoalScorerOddsOptions?.some((ele) => ele.oddId === oddDetails?.any?.id)
                      ? true
                      : false
                  }
                  isClicked={loadingIds.has(oddDetails.any?.id)} // Check if this box is in a loading state
                />
              </div>
            </div>
          ))}
        </div>
        {goalScorerOddsDetails?.data.length && goalScorerOddsDetails?.data.length > maxVisibleItems && (
          <div onClick={() => setShowMore((prev) => !prev)} className={styles.showMoreContainer}>
            <Typography size="lg" className={styles.showMoreButton}>
              {showMore ? getContent(translationKeys.SHOW_LESS) : getContent(translationKeys.SHOW_MORE)}
            </Typography>
            <img
              className={classNames(styles.arrowIcon, {
                [styles.rotateIcon]: showMore,
              })}
              src={"icon/arrowDown.png"}
              alt=""
            />
          </div>
        )}
      </Accordion>
    </section>
  );
};

export default React.memo(GoalScorerOdds);
