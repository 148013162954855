import classNames from "classnames";
import ContentShell from "components/ContentShell";
import { notify } from "components/Notifications";
import PixiContainer from "components/PixiContainer";
import { AppEventTypes, CanvasEvents, CanvasMessage, loaderTypes } from "core/enums";
import { useAllBadgesLazyQuery, useMeLazyQuery, useOddsLazyQuery } from "generated/graphql";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import Bets from "pages/bets/Bets";
import Wallet from "pages/wallet/Wallet";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import sessionHelper from "service/sessionHelper";
import useGameStore from "store/gameStore";
import styles from "styles/pages/home/home.module.css";
import getConfig from "util/configHelper";
import appEventInstance from "util/eventHandler";
import { timer } from "util/miscUtils";
import { shallow } from "zustand/shallow";

const Home = () => {
  const { configData, errConfigData } = getConfig();
  const {
    updateDrawerStates,
    setMyTeamResponse,
    setIframeRef,
    setSelectedOddsDisplay,
    setShowLoader,
    setIsShowTeamList,
    isOpenSettingsDrawer,
    setIsInitDataAvailable,
    setIsUserCheating,
    isUserCheating,
    setAllBadgesList,
    isOpenLeaderBoardDrawer,
    setHideReactOverLay,
    hideReactOverLay,
  } = useGameStore(
    (state) => ({
      updateDrawerStates: state.updateDrawerStates,
      setShowLoader: state.setShowLoader,
      updateModalStates: state.updateModalStates,
      setMyTeamResponse: state.setMyTeamResponse,
      isOpenSettingsDrawer: state.drawerStates.isOpenSettingsDrawer,
      setIframeRef: state.setIframeRef,
      setSelectedOddsDisplay: state.setSelectedOddsDisplay,
      myTeamResponse: state.myTeamResponse,
      hideReactPage: state.hideReactPage,
      setHideReactPage: state.setHideReactPage,
      setIsInitDataAvailable: state.setIsInitDataAvailable,
      isInitDataAvailable: state.isInitDataAvailable,
      setIsShowTeamList: state.setIsShowTeamList,
      setAllBadgesList: state.setAllBadgesList,
      isUserCheating: state.isUserCheating,
      hideReactOverLay: state.hideReactOverLay,
      setHideReactOverLay: state.setHideReactOverLay,
      setIsUserCheating: state.setIsUserCheating,
      isOpenLeaderBoardDrawer: state.drawerStates.isOpenLeaderBoardDrawer,
    }),
    shallow,
  );

  const teamDataRef = React.useRef({});
  const paddingRef = React.useRef({ top: 74, bottom: 79 });
  const [dimension, setDimension] = React.useState({ top: 74, bottom: 79 });
  const [page, setPage] = React.useState(CanvasEvents.home);
  const iframe = React.useRef(null);
  const [fetchUserDetails] = useMeLazyQuery();
  const [fetchStakeOptionsOddsList] = useOddsLazyQuery();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const isDebugBuild = queryParams.get("isDebugBuild") === "true";

  const _transferInfoToPixi = async (data: { type: string; value: any }) => {
    const iframeRef = useGameStore.getState().iframeRef;
    //@ts-ignore
    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow
    ) {
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension(data);
    }
  };

  const [fetchAllBadges] = useAllBadgesLazyQuery({
    onCompleted: (data) => {
      setAllBadgesList(data?.teamManager?.allBadges);
    },
  });

  const _getCurrStateValues = () => {
    let myTeamResponse = useGameStore.getState().myTeamResponse;
    let allBadgesResponse = useGameStore.getState().allBadgesList;

    return { myTeamResponse, allBadgesResponse };
  };

  const _handleCanvasEvent = async (info: CustomEvent) => {
    const { detail } = info;
    if (detail?.padding?.top) {
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleDrawerHeight, payload: { ...detail?.padding } });
      if (paddingRef.current.top !== detail?.padding?.top || paddingRef.current.bottom !== detail?.padding?.bottom) {
        setDimension({ ...detail?.padding });
        paddingRef.current = { ...detail?.padding };
      }
    }
    try {
      switch (detail.messageName) {
        case CanvasEvents.initData:
          await timer(100);
          sessionHelper?.onSignIn({
            ...sessionHelper,
            ...detail.data,
          });
          setIsInitDataAvailable(true);
          if (isDebugBuild && detail?.data?.useCheat) {
            setIsUserCheating(true);
            sessionHelper.onSignIn({ ...sessionHelper, useCheat: true });
          }

          //@ts-ignore
          await _transferInfoToPixi?.({
            type: CanvasMessage.configData,
            value: { ...configData },
          });
          fetchAllBadges();

          const _userDetailsResp = await fetchUserDetails();
          if (_userDetailsResp?.data?.players?.me?._id) {
            let OddsDisplay = _userDetailsResp?.data?.players?.me?.settings?.oddsDisplay;

            //@ts-ignore
            await _transferInfoToPixi?.({
              type: CanvasMessage.initialUserDetails,
              value: { initialUserDetails: _userDetailsResp?.data?.players },
            });

            setSelectedOddsDisplay(OddsDisplay);
          }

          break;
        case CanvasEvents.teamData:
          teamDataRef.current = { ...detail?.data };
          setMyTeamResponse(detail?.data);
          break;
        case CanvasEvents.udpateBalance:
          sessionHelper.onSignIn({
            ...sessionHelper,
            balance: detail.balance,
            pbfCoin: detail.pbfCoin,
          });
          break;
        case CanvasEvents.teamEdit:
          const { myTeamResponse, allBadgesResponse } = _getCurrStateValues();
          teamDataRef.current = { ...myTeamResponse };
          appEventInstance.dispatch({
            eventType: AppEventTypes.populateTeamEdit,
            payload: { data: { ...teamDataRef.current }, badgeList: allBadgesResponse },
          });

          updateDrawerStates({ isOpenTeamEditDrawer: true });
          break;
        case CanvasEvents.showPopUp:
          let canvasMessage = "";
          if (detail?.data?.id === "VOID_BET") {
            canvasMessage = translationKeys.VOID_BET;
          }
          appEventInstance.dispatch({
            eventType: AppEventTypes.toggleCanvasErrorModal,
            payload: { translationKey: canvasMessage },
          });
          break;
        case CanvasEvents.showSetting:
          if (!isOpenSettingsDrawer) {
            updateDrawerStates({ isOpenSettingsDrawer: true });
          }
          break;
        case CanvasEvents.showLeaderBoard:
          if (!isOpenLeaderBoardDrawer) {
            updateDrawerStates({ isOpenLeaderBoardDrawer: true });
          }
          break;
        case CanvasEvents.showSnackBar:
          if (detail?.data?.message) {
            notify(detail.data);
          }
          break;
        case CanvasEvents.showBet:
          setShowLoader({ isTrue: true, loaderFor: loaderTypes.oddsList });
          updateDrawerStates({ isOpenStakePannelOptions: true });
          const _oddsResp = await fetchStakeOptionsOddsList();
          if (_oddsResp?.data?.players?.odds?.matchId) {
            sessionHelper.updateOddDetails({
              ..._oddsResp?.data?.players?.odds,
              teamA: detail?.data?.myTeam,
              teamB: detail?.data?.oppTeam,
            });
            if (sessionHelper.oddsDetails?.matchId.length) {
              setShowLoader({ isTrue: false, loaderFor: loaderTypes.oddsList });
            }
          } else {
            appEventInstance.dispatch({
              eventType: AppEventTypes.toggleErrorModal,
              payload: { translationKey: translationKeys.Stake04 },
            });
          }
          break;
        case CanvasEvents.hideTeamList:
          setIsShowTeamList(false);
          break;
        case CanvasEvents.hideReactOverLay:
          setHideReactOverLay(true);
          break;
        case CanvasEvents.showReactOverLay:
          setHideReactOverLay(false);
          break;
        case CanvasEvents.home:
          setPage(detail.messageName);
          break;
        case CanvasEvents.teams:
          setPage(detail.messageName);
          break;
        case CanvasEvents.myBets:
          setShowLoader({ isTrue: true, loaderFor: loaderTypes.betHistory });
          setPage(detail.messageName);
          break;
        case CanvasEvents.hideReactPage:
          setPage(detail.messageName);
          break;
        case CanvasEvents.ftuiComplete:
          sessionHelper.onSignIn({ ...sessionHelper, isFTU: false });
          break;
        case CanvasEvents.wallet:
          setPage(detail?.messageName);
          break;
        default:
      }
    } catch (e) {
      console.log("Home: err", e);
    }
  };

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.canvasEvent, _handleCanvasEvent);

    return () => {
      appEventInstance.remove(AppEventTypes.canvasEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (iframe.current) {
      setIframeRef(iframe.current);
    }
  }, [setIframeRef]);

  return (
    <ContentShell>
      <section className={styles.root}>
        <PixiContainer iframe={iframe} />
        {/* <PixiContainer view={CanvasView.home} /> */}
        {/* remove the below div forceBuildData once use Cheat functionality is checked by the client .... */}
        <div
          className={classNames(styles.forceBuildData, {
            [styles.hide]: !isUserCheating,
          })}
          style={{
            position: "absolute",
            top: "65px",
            left: 0,
            zIndex: 9999,
            width: "100%",
            textAlign: "center",
            WebkitTextStroke: "0.1px black",
          }}
        >
          <h1 style={{ color: "red", fontSize: "0.85rem", fontWeight: "bolder" }}>WARNING: FORCES ENABLED</h1>
        </div>
        {[CanvasEvents.home, CanvasEvents.teams, CanvasEvents.hideReactPage].includes(page) ? null : (
          <div
            id="pageContainer"
            style={{ top: `${dimension?.top || 0}px`, bottom: `${dimension?.bottom || 0}px` }}
            className={classNames(styles.default, {
              [styles.hideEl]: true,
              [styles.noWidth]: hideReactOverLay,
            })}
          >
            {page === CanvasEvents.myBets && <Bets />}
            {page === CanvasEvents.wallet && <Wallet />}
          </div>
        )}
      </section>
    </ContentShell>
  );
};

export default Home;
