import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import styles from "styles/components/modals/vaultXpModal.module.css";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";

const VaultXpModal = () => {
  const { isOpenVaultXpModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenVaultXpModal: state.modalStates.isOpenVaultXpModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenVaultXpModal: false });
  };

  return (
    <Modal
      id="vaultXpModal"
      open={isOpenVaultXpModal}
      handleClose={handleModalClose}
      containerSize="xs"
      footerButtonText={getContent(translationKeys.ContinueText)}
    >
      <div className={classNames(styles.body)}>
        <Typography fontFamily="degularBold" size="2xl">
          {getContent(translationKeys.Vault_XP) || "--"}
        </Typography>
        <Typography fontFamily="avenirNextMedium" size="md2">
          {getContent(translationKeys.VAULT_XP_UNLOCK_MESSAGE) || "--"}
        </Typography>
      </div>
    </Modal>
  );
};
export default React.memo(VaultXpModal);
