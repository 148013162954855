import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import Carousel, { ICarouselNode } from "components/Carousal";
import Tabs from "components/Tab";
import Typography from "components/Typography";
import Button from "components/Button";
import Drawer from "components/Drawer";
import useGameStore from "store/gameStore";
import { formatDataToCarousalNodeType, getTeamName, timer } from "util/miscUtils";
import { DrawerBgType, TabType, DrawerPositions, ZIndexLevels, AppEventTypes } from "core/enums";
import { CardItem, MyTeamsResponse } from "core/interfaces/home";
import styles from "styles/components/drawers/teamEditDrawer.module.css";
import { TeamBadge, useProfanityCheckLazyQuery, useUpdateTeamMetadataMutation } from "generated/graphql";
import _Error, { ErrorMessage } from "util/ErrHandler";
import appEventInstance from "util/eventHandler";
import { useImagesPreloaded } from "hooks/usePreloader";
import Loader from "components/Loader";
import gameJson from "config/gameJSON.json";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import firebaseAnalytics from "service/firebaseAnalytics";
import { AnalyticsEvents } from "analyticTypes/analyticsEventTypes";
import sessionHelper from "service/sessionHelper";

const TeamEditDrawer = () => {
  const { isOpenTeamEditDrawer, updateDrawerStates, myTeamResponse, setMyTeamResponse, updateTeamName, allBadgesList } =
    useGameStore(
      (state) => ({
        isOpenTeamEditDrawer: state.drawerStates.isOpenTeamEditDrawer,
        isOpenSettingDrawer: state.drawerStates.isOpenSettingsDrawer,
        updateDrawerStates: state.updateDrawerStates,
        myTeamResponse: state.myTeamResponse,
        setMyTeamResponse: state.setMyTeamResponse,
        updateTeamName: state.updateTeamName,
        allBadgesList: state.allBadgesList,
      }),
      shallow,
    );
  const tabLabels = [getContent(translationKeys.KITS), getContent(translationKeys.BADGES)];
  const matchedTabs = gameJson.teamEditTabOption.filter((option) => tabLabels.includes(getContent(option.label)));
  const [activeTab, setActiveTab] = useState<string>(getContent(matchedTabs[0]?.id));
  const [kits, setKits] = useState<ICarouselNode<CardItem>[]>([]);
  const [badges, setBadges] = useState<ICarouselNode<TeamBadge>[]>([]);
  const [selectedKit, setSelectedKit] = useState<ICarouselNode<CardItem> | undefined>();
  const [selectedBadge, setSelectedBadge] = useState<ICarouselNode<TeamBadge> | undefined>();
  // const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true);
  const kitsImageUrls = gameJson.kits?.map((kit: any) => kit.url) || [];
  const badgesImageUrls = allBadgesList?.map((badge: any) => badge.url) || [];
  const allImagesLoaded = useImagesPreloaded([...kitsImageUrls, ...badgesImageUrls]);
  const [initialTeamDetails, setInitialTeamDetails] = useState({
    name: myTeamResponse?.name,
    badges: myTeamResponse?.badge,
    kit: myTeamResponse?.kit,
  });

  // const [checkProfanity] = useProfanityCheckLazyQuery();
  const [UpdateTeamMetadataMutation] = useUpdateTeamMetadataMutation();

  const TabNames = gameJson.teamEditTabOption.map((tab) => ({
    id: getContent(tab.id),
    label: getContent(tab.label),
  }));

  useEffect(() => {
    if (allImagesLoaded) {
      setLoading(false);
    }
  }, [allImagesLoaded]);

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.populateTeamEdit, (data) => {
      const _badgeList: TeamBadge[] = [...data.detail.badgeList];
      const initializedKits = formatDataToCarousalNodeType(gameJson.kits || []);
      const initializedBadges = formatDataToCarousalNodeType(_badgeList || []);
      const _data = { ...data.detail.data };

      setKits(initializedKits);
      setBadges(initializedBadges);

      if (!selectedKit) {
        const _kit = initializedKits.find((el) => el.id === _data?.kit?.split(".")[0]);
        setSelectedKit(_kit);
        setInitialTeamDetails((prev: any) => {
          return { ...prev, kit: _kit };
        });
      }
      if (!selectedBadge) {
        const _badge = initializedBadges.find((el) => el.id === _data?.badge?.split(".")[0]);
        setSelectedBadge(_badge);
        setInitialTeamDetails((prev: any) => {
          return { ...prev, badges: _badge };
        });
      }
    });

    return () => {
      appEventInstance.remove(AppEventTypes.populateTeamEdit);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendTeamInfoToCanvas = (data: Partial<MyTeamsResponse>) => {
    const iframeRef = useGameStore.getState().iframeRef;

    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow &&
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension
    ) {
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension({
        type: "TEAM_INFO",
        value: {
          kit: data?.kit,
          badge: data?.badge,
          name: data?.name,
          kitColor: data?.kitColor,
          kitColorDark: data?.kitColorDark,
          badgeUrl: data?.badgeUrl,
        },
      });
    }
  };

  const renderItem = (item: ICarouselNode<CardItem>, index: number) => {
    const isSelected = activeTab === matchedTabs[0]?.id ? selectedKit?.id === item?.id : selectedBadge?.id === item?.id;
    return (
      <div key={index} className={styles.arrows}>
        <div
          className={classNames(styles.carousel, { [styles.selected]: isSelected })}
          onClick={() => handleSelect(item)}
        >
          <img
            src={item?.data?.url as string}
            alt={`item-${index}`}
            className={classNames({
              [styles.carouselKitImg]: activeTab === matchedTabs[0]?.id,
              [styles.carouselEmblemImg]: activeTab === matchedTabs[1]?.id,
            })}
          />
        </div>
      </div>
    );
  };

  const handleSelect = (item: ICarouselNode<CardItem>) => {
    if (activeTab === matchedTabs[0]?.id) {
      setSelectedKit(item);
    } else if (activeTab === matchedTabs[1]?.id) {
      setSelectedBadge(item);
    }
  };

  // const handleEditClick = () => {
  //   if (inputRef.current) {
  //     const event = new Event("focus", { bubbles: true });
  //     inputRef.current.dispatchEvent(event);
  //     inputRef.current.focus();
  //   }
  // };

  // const handleNameSave = async (teamName: string) => {
  //   try {
  //     if (useGameStore.getState().drawerStates.isOpenSettingsDrawer) return; // get the updated state rapidly
  //     if (!teamName?.trim()) {
  //       inputRef.current?.blur();
  //       throw new _Error({ message: getContent(translationKeys.GEN03), toastPosition: "bottom" });
  //     }

  //     if (teamName.trim().length < 3) {
  //       inputRef.current?.blur();
  //       throw new _Error({ message: getContent(translationKeys.LessCharError, ["3"]), toastPosition: "bottom" });
  //     }

  //     if (/(?:\D*\d){5,}/.test(teamName.trim())) {
  //       inputRef.current?.blur();
  //       throw new _Error({ message: getContent(translationKeys.MoreDigitsError), toastPosition: "bottom" });
  //     }

  //     let _res = await checkProfanity({ variables: { text: teamName.trim() } });

  //     if (!_res?.data?.players?.profanityCheck?.isValid) {
  //       throw new _Error({ message: getContent(translationKeys.ProfanityError), toastPosition: "bottom" });
  //     }

  //     const response = await UpdateTeamMetadataMutation({
  //       variables: { input: { name: teamName.trim() } },
  //     });

  //     const data = response.data?.teamManager.updateTeamMetadata;
  //     sendTeamInfoToCanvas(data as any);

  //     return data?.name;
  //   } catch (error) {
  //     const errMsg = error as ErrorMessage;
  //     updateTeamName({ name: "" });
  //     appEventInstance.dispatch({
  //       eventType: AppEventTypes.toggleErrorModal,
  //       payload: { msg: errMsg?.message },
  //     });
  //   }
  // };

  const onTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  // const handleClose = async () => {
  //   try {
  //     const previousTeamName = initialTeamDetails.name; // Get the stored previous name
  //     const updatedTeamName = await handleNameSave(myTeamResponse?.name as string);

  //     if (updatedTeamName?.length) {
  //       updateDrawerStates({ isOpenTeamEditDrawer: false });
  //       if (previousTeamName != updatedTeamName) {
  //         firebaseAnalytics.trackEvent(AnalyticsEvents.Team_Customisation, {
  //           team_name_changed_from: previousTeamName,
  //           team_name_changed_to: updatedTeamName,
  //           UserID: sessionHelper?._id,
  //         });
  //       }

  //       // Update the stored previous name for future comparisons
  //       setInitialTeamDetails((prev) => {
  //         return { ...prev, name: updatedTeamName };
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error while saving team name:", error);
  //   }
  // };

  const handleClose = () => {
    if (isOpenTeamEditDrawer) {
      updateDrawerStates({ isOpenTeamEditDrawer: false });
    }
  };

  const handleSelectClick = async () => {
    // const updatedTeamName = await handleNameSave(myTeamResponse?.name as string); // Get the updated name
    // if (!updatedTeamName?.length) {
    //   // keep this diff
    //   return;
    // } else {
    //   if (initialTeamDetails.name != myTeamResponse?.name) {
    //     firebaseAnalytics.trackEvent(AnalyticsEvents.Team_Customisation, {
    //       team_name_changed_from: initialTeamDetails?.name,
    //       team_name_changed_to: myTeamResponse?.name,
    //       UserID: sessionHelper?._id,
    //     });
    //   }
    // }
    try {
      // updating kit and badges
      const resp = await UpdateTeamMetadataMutation({
        variables: {
          input: {
            kit: selectedKit?.id,
            name: myTeamResponse?.name as string,
            badge: selectedBadge?.id,
          },
        },
      });

      const data = resp.data?.teamManager.updateTeamMetadata;

      if (myTeamResponse?.kit != selectedKit?.id) {
        firebaseAnalytics.trackEvent(AnalyticsEvents.Team_Customisation, {
          team_kit_changed_from: myTeamResponse?.kit,
          team_kit_changed_to: selectedKit?.id,
          UserID: sessionHelper?._id,
        });
      }
      if (myTeamResponse?.badge != selectedBadge?.id) {
        firebaseAnalytics.trackEvent(AnalyticsEvents.Team_Customisation, {
          team_badge_changed_from: myTeamResponse?.badge,
          team_badge_changed_to: selectedBadge?.id,
          UserID: sessionHelper?._id,
        });
      }

      // update team details in gamestores
      setMyTeamResponse(data as any);
      sendTeamInfoToCanvas(data as any);
      setInitialTeamDetails((prev) => {
        return { ...prev, name: myTeamResponse?.name };
      });
      // sending team info data to canvas
      updateDrawerStates({ isOpenTeamEditDrawer: false });
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleErrorModal, payload: { msg: errMsg?.message } });
    }
  };

  // const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   updateTeamName({ name: value });
  // };

  return (
    <Drawer
      id="teamEditDrawer"
      onClose={handleClose}
      isOpen={isOpenTeamEditDrawer}
      position={DrawerPositions.Top}
      showFooterButton={true}
      drawerBgType={DrawerBgType.TeamEdit}
      zIndexLevel={ZIndexLevels.Level1}
    >
      <div className={classNames(styles.mainDiv)}>
        <div className={classNames(styles.firstDiv)}>
          <input
            // ref={inputRef}
            type="text"
            // value={myTeamResponse?.name ?? ""}  // uncomment this 
            value={getContent("myTeam") ?? "-"}
            // onChange={handleNameChange}
            // autoFocus
            // onInput={(e) => {
            //   const target = e.target as HTMLInputElement;
            //   target.value = target.value.replace(/[^a-zA-Z0-9\s'’]/g, ""); // Allows spaces
            // }}
            // onFocus={() => {
            //   let teamName = myTeamResponse?.name;
            //   setInitialTeamDetails((prev) => {
            //     return { ...prev, name: teamName };
            //   });
            // }}
            // onBlur={async (e) => {
            //   await timer(300); // small dirty fix ....
            //   handleNameSave(myTeamResponse?.name || "");
            // }}
            maxLength={20}
            style={{ fontSize: myTeamResponse?.name && myTeamResponse?.name?.length > 10 ? "1.10rem" : "1.5rem" }}
            className={classNames(styles.teamNameInput)}
            readOnly
          />
          {/* <div onClick={handleEditClick}>
            <img src="images/team/editButton.png" alt="editBtn" className={classNames(styles.editButton)} />
          </div> */}
        </div>
        <div className={classNames(styles.secondDiv)}>
          <img src={selectedKit?.data?.url} alt="tshirtImg" className={classNames(styles.tshirtImg)} />
          <img src={selectedBadge?.data?.url} alt="badgeImg" className={classNames(styles.emblemImg)} />
        </div>
        <div className={classNames(styles.tabs)}>
          <Tabs
            type={TabType.Square}
            onClick={(tabId) => onTabClick(tabId)}
            tabNames={TabNames}
            activeTab={activeTab}
            drawerOpen={isOpenTeamEditDrawer}
          />
        </div>
        <div className={classNames(styles.thirdDiv)}>
          {loading ? (
            <Loader />
          ) : (
            <Carousel
              pauseOnHover
              showDots={true}
              infinite={true}
              items={activeTab === "kits" ? kits : badges}
              renderItem={renderItem}
            />
          )}
        </div>
        <div className={classNames(styles.selectButton)}>
          <Button size="medium" color="greenBtn" onClick={handleSelectClick}>
            <Typography color="navyBlue" size="lg" fontFamily="degularBlack">
              {getContent(translationKeys.SELECT)}
            </Typography>
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default React.memo(TeamEditDrawer);
