import classNames from "classnames";
import Button from "components/Button";
import Loader from "components/Loader";
import NumbersKeypad from "components/NumbersKeypad";
import TextFeild from "components/TextFeild";
import Typography from "components/Typography";
import { AppEventTypes, CanvasMessage, matchStatusEnums, minMaxWarningMessagesEnums } from "core/enums";
import {
  BetStatus,
  OddsDisplayEnum,
  PlaceBetResponse,
  useCurrentBetSlipLazyQuery,
  usePlaceBetMutation,
} from "generated/graphql";
import React, { useCallback, useEffect, useRef, useState } from "react";
import sessionHelper from "service/sessionHelper";
import useGameStore from "store/gameStore";
import styles from "styles/components/stakePannelInput.module.css";
import getConfig from "util/configHelper";
import appEventInstance from "util/eventHandler";
import {
  calculateToReturn,
  debounce,
  getBetSelectionForAnalytics,
  getTeamName,
  renderDecimalOdds,
  setCurrencyFormat,
  setNumberFormat,
  timer,
} from "util/miscUtils";
import { shallow } from "zustand/shallow";
import Vault from "../../../components/Vault";
import useHandleAddUpdateOdds from "../../../hooks/useHandleAddUpdateOdds";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import ContentShell from "components/ContentShell";
import firebaseAnalytics from "service/firebaseAnalytics";
import { AnalyticsEvents } from "analyticTypes/analyticsEventTypes";

interface StakePannelInputProps {
  updateBetPannelOptions?: (data: Record<string, any>) => void; // Update function signature to accept an object
  _transferInfoToPixi: (data: { type: string; value: any }) => void;
}

const views = {
  selectedOddsInputsView: "selectedOddsInputsView",
  selectedOddsLabelsView: "selectedOddsLabelsView",
  betPlaceView: "betPlaceView",
  addedToSlip: "addedToSlip",
  updateStakeSlip: "updateStakeSlip",
};

const StakePannelInput: React.FC<StakePannelInputProps> = ({ _transferInfoToPixi }) => {
  const { configData, errConfigData } = getConfig();

  const {
    udpateModalStates,
    selectedOddsOptions,
    updateStakeById,
    setSelectedOddsOptions,
    activeOddsOptions,
    setActiveOddsOptions,
    updateDrawerStates,
    selectedOddsDisplay,
    setBetPlaceData,
    activeOddForEnteringStake,
    setActiveOddForEnteringStake,
    setMatchStatus,
    setStakePannelWarningMessage,
  } = useGameStore(
    (state) => ({
      udpateModalStates: state.updateModalStates,
      matchStatus: state.matchStatus,
      setMatchStatus: state.setMatchStatus,
      updateDrawerStates: state.updateDrawerStates,
      selectedOddsOptions: state.selectedOddsOptions,
      activeOddsOptions: state.activeOddsOptions,
      setActiveOddsOptions: state.setActiveOddsOptions,
      activeOddForEnteringStake: state.activeOddForEnteringStake,
      setActiveOddForEnteringStake: state.setActiveOddForEnteringStake,
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      updateStakeById: state.updateStakeById,
      isOpenStakeInfoModal: state.modalStates.isOpenStakeInfoModal,
      selectedOddsDisplay: state.selectedOddsDisplay,
      setBetPlaceData: state.setBetPlaceData,
      setStakePannelWarningMessage: state.setStakePannelWarningMessage,
    }),
    shallow,
  );
  const [playAnimation, setPlayAnimation] = useState(configData?.selectedOddsInputsView);
  const [betPannelBooleanValues, setBetPannelBooleanValues] = useState({
    isBetPlaced: false,
    showKeypad: false,
    disablePlaceBetOnKeyClick: false,
    disablePlaceBetOnBetPlace: false,
    isCallingRemovingOddsListApi: false,
  });
  const [defaultKeyOrNumbersKey, setDefaultKeyOrNumbersKey] = useState("keypadKeys");

  const { handleBoxClick, isRemovingBetLoading, isAddingBetLoading, error } = useHandleAddUpdateOdds();

  const [currentStakeSlipsData, { data, error: isCurrentStakeSlipError, loading: isCurrentStakeSlipsLoading }] =
    useCurrentBetSlipLazyQuery();

  const debouncedApiCall = useCallback(
    debounce(async (oddId: string, betId: string, stake: number) => {
      try {
        let updateStakeSlip = await handleBoxClick(oddId, configData.updateStakeSlip, [], betId, stake);
        setBetPannelBooleanValues((prev) => ({ ...prev, disablePlaceBetOnKeyClick: false }));
        return updateStakeSlip;
      } catch (error) {
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { error },
        });
      }
    }, 500), // Delay
    [],
  );
  const getUdpatedOddsOptions = useGameStore.getState; // get fresh Odds Options ...

  const listRef = useRef<HTMLDivElement | null>(null); // for scrolling bet recreipt on top

  const handleScrollToTop = () => {
    if (listRef.current) {
      listRef.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const _calculateMaxStakeForSelectedOdd = (item: any) => {
    let maxStake = sessionHelper?.maxPayOut / item.decimalOdd;
    return Math.floor(maxStake * 100) / 100;
  };

  const handleKeyClick = async (key: string | number) => {
    setDefaultKeyOrNumbersKey("keypadKeys");
    let updatedOddsOptions = getUdpatedOddsOptions().selectedOddsOptions;
    const activeOption = updatedOddsOptions[activeOddForEnteringStake?.key]?.find(
      (option) => option.oddId === activeOddForEnteringStake.oddId,
    );

    if (!activeOption) {
      return;
    }

    // setDisablePlaceBetOnKeyClick(true);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, disablePlaceBetOnKeyClick: true };
    });

    // Raw stake value (unformatted)
    let stakeString = activeOption.betAmount?.toString() || "";

    if (key === "icon") {
      // Handle backspace: Remove the last character
      stakeString = stakeString.slice(0, -1);
      if (stakeString === "" || stakeString === "0") {
        stakeString = ""; // Remove "0" and keep empty
      }
      updateStakeById(activeOddForEnteringStake.key, activeOddForEnteringStake.oddId, stakeString);
      debouncedApiCall(activeOddForEnteringStake.oddId, activeOddForEnteringStake.betId, Number(stakeString || 0));
      return;
    }

    if (key === "decimal") {
      // Handle decimal input: Add if it doesn't already exist
      if (!stakeString.includes(".") && stakeString.length > 0) {
        stakeString += ".";
      }
      if (Number(stakeString) > Number(sessionHelper?.maxBet) - 1) {
        return;
      }
      updateStakeById(activeOddForEnteringStake.key, activeOddForEnteringStake.oddId, stakeString);
      setBetPannelBooleanValues((prev) => {
        return { ...prev, disablePlaceBetOnKeyClick: false };
      });

      return;
    }

    // Validate: If already two decimal places exist, reject input
    if (stakeString.includes(".")) {
      const [integerPart, decimalPart] = stakeString.split(".");
      if (decimalPart?.length >= 2) {
        // setDisablePlaceBetOnKeyClick(false);
        setBetPannelBooleanValues((prev) => {
          return { ...prev, disablePlaceBetOnKeyClick: false };
        });
        return; // Reject input if decimal already has two digits
      }
    }

    // Append numeric keys (0-9) or handle replacement of "0"
    if (stakeString === "0" && key !== "decimal") {
      stakeString = ""; // Replace "0" with new input
    }
    stakeString += key;

    // Validate: Enforce maximum value of 10,000
    const numericValue = parseFloat(stakeString);
    if (numericValue > Number(sessionHelper?.maxBet)) {
      setStakePannelWarningMessage(minMaxWarningMessagesEnums.maxStake);
      udpateModalStates({ isOpenMinMaxStakeModal: true });
      stakeString = sessionHelper?.maxBet?.toString(); // Cap to max value
    }
    if (stakeString?.includes(".")) {
      if (stakeString < sessionHelper?.minBet) {
        setStakePannelWarningMessage(minMaxWarningMessagesEnums.minStake);
        udpateModalStates({ isOpenMinMaxStakeModal: true });
        stakeString = sessionHelper?.minBet?.toString();
      }
    }

    let maxStakeForSelectedOdd = _calculateMaxStakeForSelectedOdd(activeOption);
    if (Number(stakeString) > maxStakeForSelectedOdd) {
      setStakePannelWarningMessage(minMaxWarningMessagesEnums.maxPayout);
      udpateModalStates({ isOpenMinMaxStakeModal: true });

      // let [integer, decimalValue] = maxStakeForSelectedOdd.toString().split(".");
      stakeString = maxStakeForSelectedOdd.toString();
    }

    // Update state and API calls
    updateStakeById(activeOddForEnteringStake.key, activeOddForEnteringStake.oddId, stakeString);
    debouncedApiCall(activeOddForEnteringStake.oddId, activeOddForEnteringStake.betId, parseFloat(stakeString));
  };

  const handleOptionClick = (defaultKey: string | number) => {
    let updatedOddsOptions = getUdpatedOddsOptions().selectedOddsOptions;

    const activeOption = updatedOddsOptions[activeOddForEnteringStake?.key]?.find(
      (option) => option.oddId === activeOddForEnteringStake.oddId,
    );

    if (!activeOption) {
      return;
    }

    setDefaultKeyOrNumbersKey("defaultKey");
    // setDisablePlaceBetOnKeyClick(true);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, disablePlaceBetOnKeyClick: true };
    });
    let maxStakeForSelectedOdd = _calculateMaxStakeForSelectedOdd(activeOption);

    if (Number(defaultKey) > maxStakeForSelectedOdd) {
      setStakePannelWarningMessage(minMaxWarningMessagesEnums.maxPayout);
      udpateModalStates({ isOpenMinMaxStakeModal: true });
      // let [integer, decimalValue] = maxStakeForSelectedOdd.toString().split(".");
      defaultKey = maxStakeForSelectedOdd.toString();
    }
    updateStakeById(activeOddForEnteringStake.key, activeOddForEnteringStake.oddId, defaultKey as string);
    debouncedApiCall(activeOddForEnteringStake.oddId, activeOddForEnteringStake.betId, Number(defaultKey));
  };

  const handleInputChange = ({ value, name }: { value: string; name: string }) => {};

  const [betsMutation, { data: placeBetData, loading: isPlaceBetLoading, error: isPlaceBetError }] =
    usePlaceBetMutation({
      variables: {},
      onCompleted: (data) => {},
    });

  function getLastestVaultLevel(data: any, targetValue: number) {
    // Filter out null values first
    const filteredData = data.filter((item: any) => item !== null || item !== undefined);

    // Find the index of the first item with matching level value
    const startIndex = filteredData.findIndex((item: any) => item && item.spin === targetValue);

    // If found, return the sliced array from that index; otherwise, return the full array
    return startIndex !== -1 ? filteredData.slice(startIndex) : filteredData;
  }

  const updateBetAmounts = async () => {
    let updatedOdds = {} as any;
    let hasUpdated = false; // Track if any stake is updated
    for (const market in selectedOddsOptions) {
      updatedOdds[market] = [];

      for (const bet of selectedOddsOptions[market]) {
        if (bet.betAmount != null && bet.betAmount != undefined && bet.betAmount < Number(sessionHelper?.minBet)) {
          setStakePannelWarningMessage(minMaxWarningMessagesEnums.minStake);
          udpateModalStates({ isOpenMinMaxStakeModal: true });
          if (
            bet.marketName === "First Goalscorer" ||
            bet.marketName === "Last Goalscorer" ||
            bet.marketName === "Anytime Goalscorer"
          ) {
            bet.marketName = "First / Last / Anytime Goalscorer"; // this is because in the selectedOddsoptions we are storing the marketName as First / Last / Anytime Goalscorer
          }
          updateStakeById(bet.marketName, bet.oddId, sessionHelper?.minBet);
          await handleBoxClick(bet.oddId, configData.updateStakeSlip, [], bet.betId, Number(sessionHelper?.minBet));
          updatedOdds[market].push({ ...bet, betAmount: Number(sessionHelper.minBet) });
          hasUpdated = true; // Mark that we have updated at least one stake
        } else {
          updatedOdds[market].push(bet);
        }
      }
    }

    return hasUpdated; // Return true if any update happened, false otherwise
  };

  // handling both start and place bet functionality here
  const handlePlaceBet = async () => {
    if (betPannelBooleanValues.isBetPlaced) {
      // if bet place is true then just start match........
      updateDrawerStates({ isOpenStakePannelOptions: false });
      //@ts-ignore
      await _transferInfoToPixi?.({
        type: CanvasMessage.startSimulation,
        value: {},
      });
      await timer(1000); // reset all the state after one sec...
      setMatchStatus(matchStatusEnums.matchStarted);
      setSelectedOddsOptions([], "removeAllData");
      setMatchStatus(matchStatusEnums.selectingOdds);
      sessionHelper.updateOddDetails([] as any);
      setBetPannelBooleanValues((prev) => {
        return { ...prev, isBetPlaced: false };
      });
    } else {
      // below function works for setting minBet Amount to stake which are not set to min Bet ...
      const hasUpdatedStake = await updateBetAmounts();

      if (hasUpdatedStake) {
        return; // Stop execution immediately if any stake was updated
      }
      setBetPannelBooleanValues((prev) => {
        return { ...prev, disablePlaceBetOnBetPlace: true, showKeypad: false }; // disable PlaceBetButton
      });
      if (calculateTotalBetAmount() > Number(sessionHelper?.balance)) {
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { translationKey: errConfigData.Stake01 },
        });
        setBetPannelBooleanValues((prev) => {
          return { ...prev, disablePlaceBetOnBetPlace: false }; // enable  PlaceBetButton if got insufficient funds ...
        });
        return;
      } else {
        try {
          let placeBet = await betsMutation({
            awaitRefetchQueries: true, // fetch new data without Cathing ...
          });

          let placeBetResp = placeBet?.data?.players?.placeBet as any;
          let stringifyResp = JSON.stringify(placeBetResp);
          let parseResp = JSON?.parse(stringifyResp);
          let updateProgressUpdates = getLastestVaultLevel(parseResp?.progressUpdates, parseResp?.lastVaultLevel);

          placeBetResp = {
            ...placeBetResp,
            UpdatedVaultSpinDetails: updateProgressUpdates,
          };
          setBetPlaceData(placeBetResp as PlaceBetResponse); // setkting betplace response ....

          //@ts-ignore
          _transferInfoToPixi?.({
            type: CanvasMessage.betPlaceResponse,
            value: placeBetResp,
          });

          if (placeBetResp?.bets?.length) {
            setBetPannelBooleanValues((prev) => {
              return { ...prev, isBetPlaced: true };
            });
            let resp = await currentStakeSlipsData(); // fetch bet slips which are in debitSuccess status ...
            let totalCurrentStakeSlipAval = resp?.data?.players?.currentBetSlip;
            const iframeRef = useGameStore.getState().iframeRef;
            if (
              iframeRef &&
              //@ts-ignore
              iframeRef?.contentWindow &&
              //@ts-ignore
              iframeRef?.contentWindow?.gameExtension
            ) {
              //@ts-ignore
              iframeRef?.contentWindow?.gameExtension({
                type: CanvasMessage?.sendVaultData,
                value: {},
              });
            }
            if (sessionHelper.isFTU) {
              // show vaultXpReardModal Only once when in isFTU true ...
              udpateModalStates({ isOpenVaultXpModal: true });
              for (const bet of placeBetResp.bets) {
                firebaseAnalytics.trackEvent(AnalyticsEvents.FTUE, {
                  FTUE_bet_placed: 1, // send this only if FTU is true ....
                  [`FTUE_Bet_stake_${bet.currency}`]: bet.betAmount,
                  FTUE_bet_market: bet.marketType,
                  FTUE_selection: getBetSelectionForAnalytics(bet),
                  placed_datetime: bet.createdAt,
                  BetID: bet.betId,
                  UserID: sessionHelper?._id,
                });
              }

              sessionHelper.onSignIn({
                ...sessionHelper,
                isFTU: false,
              });
            } else {
              for (const bet of placeBetResp.bets) {
                firebaseAnalytics.trackEvent(AnalyticsEvents.Bet, {
                  [`Bet_stake_${bet.currency}`]: bet.betAmount,
                  bet_market: bet.marketType,
                  selection: getBetSelectionForAnalytics(bet),
                  placed_datetime: bet.createdAt,
                  BetID: bet.betId,
                  UserID: sessionHelper?._id,
                  current_xp: placeBetResp?.currentXP,
                  current_vault_level: placeBetResp?.currentVaultLevel,
                });
              }
            }

            handleScrollToTop();
            setActiveOddsOptions(totalCurrentStakeSlipAval as any); // store all the  reponse except added_to_slip
            setPlayAnimation(configData.betPlaceView);
            setMatchStatus(matchStatusEnums.betPlaced);
            // setDisablePlaceBetOnBetPlace(false);
            await timer(300);
            setBetPannelBooleanValues((prev) => {
              return { ...prev, disablePlaceBetOnBetPlace: false };
            });
          } else {
            appEventInstance.dispatch({
              eventType: AppEventTypes.toggleErrorModal,
              payload: { translationKey: errConfigData.Stake05 },
            });
            setBetPannelBooleanValues((prev) => {
              return { ...prev, disablePlaceBetOnBetPlace: false };
            });
          }
        } catch (err) {
          appEventInstance.dispatch({
            eventType: AppEventTypes.toggleErrorModal,
            payload: { error: err },
          });

          setBetPannelBooleanValues((prev) => {
            return { ...prev, disablePlaceBetOnBetPlace: false };
          });
        }
      }
    }
  };

  const handleAddMoreOddsSlips = async () => {
    setBetPlaceData(null);
    setMatchStatus(matchStatusEnums.selectingOdds);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, isBetPlaced: false };
    });
    setPlayAnimation(configData.selectedOddsInputsView);
    try {
      let resp = await currentStakeSlipsData();
      let totalCurrentStakeSlipAval = resp?.data?.players?.currentBetSlip;
      setSelectedOddsOptions(totalCurrentStakeSlipAval as any, "reset"); // store only added_to_slip
    } catch (err) {
      console.error(error);
    }
  };

  // to show and undo the place bet btn and XP showing ....
  const checkIsAnyStakeUpdated = () => {
    for (let key in selectedOddsOptions) {
      if (betPannelBooleanValues.isBetPlaced) {
        return true;
      }
      if (
        selectedOddsOptions[key].some(
          (item) => item.betAmount && Number(item.betAmount) >= Number(sessionHelper?.minBet),
        )
      ) {
        return true; // Return true if any betAmount is greater than threshold
      }
    }
    return false;
  };

  const handleUserInteraction = async (id: string, marketName: string) => {
    try {
      if (
        marketName === configData.firstGoalScorer ||
        marketName === configData.lastGoalScorer ||
        marketName === configData.AnyTimeGoalScorer
      ) {
        marketName = configData.firstLastAnytimeGoalScorer;
      }
      let resp = await handleBoxClick(id, marketName, [...selectedOddsOptions[marketName]]);
      setSelectedOddsOptions(resp || [], marketName);

      let getUpdatedOddsOptions = useGameStore.getState();
      const result = Object.entries(getUpdatedOddsOptions.selectedOddsOptions).find(([_, value]) => value.length);
      if (result) {
        //  the result will be like ["key", [array of values]];
        setActiveOddForEnteringStake({
          oddId: result[1][0]?.oddId,
          key: result[1][0]?.marketName,
          betId: result[1][0]?.betId,
        });
      } else {
        setActiveOddForEnteringStake({ oddId: "", key: "", betId: "" });
      }
    } catch (e) {}
  };

  const calculateTotalBetAmount = () => {
    // Use reduce to sum the betAmount values from each array under the keys
    let _totalBetAmt = Object.values(selectedOddsOptions).reduce((sum, arr) => {
      return sum + arr.reduce((innerSum, item) => innerSum + Number(item.betAmount), 0);
    }, 0);
    return _totalBetAmt;
  };

  const calculateTotalReturnValue = () => {
    return Object.values(selectedOddsOptions).reduce((sum, arr) => {
      return (
        sum + arr.reduce((innerSum, item) => innerSum + calculateToReturn(item.betAmount, item.payoutMultiplier), 0)
      );
    }, 0);
  };

  const getBoostPercentage = (decimalOdd: number) => {
    if (decimalOdd > 11) return 100;
    if (decimalOdd > 6) return 40;
    if (decimalOdd > 3) return 20;
    return 0;
  };

  // functionality for which stake should take the numbers key values
  const handleSetActiveInputId = (oddId: string, marketName: string, betId: string) => {
    // setShowKeyPad(true);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, showKeypad: true };
    });
    appEventInstance.dispatch({
      eventType: AppEventTypes.ToggleDrawerScroll,
      payload: { isScrollDisabled: true },
    });
    // set market name as box heding rather than setting first , last , anyTime Goal scorer for activating the bet input which is active with bet id ...
    if (
      marketName === configData?.firstGoalScorer ||
      marketName === configData?.lastGoalScorer ||
      marketName === configData?.AnyTimeGoalScorer
    ) {
      marketName = configData?.firstLastAnytimeGoalScorer;
    }
    if (oddId) {
      setActiveOddForEnteringStake({ oddId, key: marketName, betId });
    }
  };

  const getPlayerName = (playerId: number | string) => {
    if (!playerId) return getContent(translationKeys.NO_GOAL_SCORER);
    let allPlayers = sessionHelper.oddsDetails?.allPlayers;
    let playerDetails = allPlayers?.filter((ele, index) => ele.playerId === playerId);
    if (playerDetails?.length) {
      return playerDetails[0].name;
    } else {
      return "player not found.";
    }
  };

  let renderedCount = 0;
  let SelectedOddsOptionsCount = Object.values(selectedOddsOptions).reduce((sum, arr) => sum + arr.length, 0);

  const _displayOddType = (item: any) => {
    switch (selectedOddsDisplay) {
      case OddsDisplayEnum.Fractional:
        return item.fractionalOdd || "F";
      case OddsDisplayEnum.Decimal:
        return renderDecimalOdds(item.decimalOdd) || "D"; // to show formatted decimal Odd
      case OddsDisplayEnum.American:
        return item.americanOdd || "DD";
      default:
        return "notFound - ";
    }
  };

  const updatedStakePannelAnimation = () => {
    if (betPannelBooleanValues.isCallingRemovingOddsListApi) {
      setBetPannelBooleanValues((prev) => {
        return { ...prev, isCallingRemovingOddsListApi: false };
      });
    } else {
      if (SelectedOddsOptionsCount > 1) {
        setPlayAnimation(configData.selectedOddsLabelsView);
        setBetPannelBooleanValues((prev) => {
          return { ...prev, showKeypad: false };
        });
      } else {
        setPlayAnimation(configData.selectedOddsInputsView);
      }
    }
  };

  const _getTeamNameForTranslation = (teamNameToGetTranslated: string) => {
    switch (teamNameToGetTranslated) {
      case configData.finalResult:
        return getContent(translationKeys.FINAL_RESULT);
      case configData.goalsOverUnder:
        return getContent(translationKeys.GOALS_OVER_UNDER);
      case configData.bothTeamsToScore:
        return getContent(translationKeys.BOTH_TEAMS_TO_SCORE);
      case configData.resultBothTeamsScore:
        return getContent(translationKeys.RESULT_BOTH_TEAMS_SCORE);
      case configData.correctScoree:
        return getContent(translationKeys.CORRECT_SCORE);
      case configData.firstLastAnytimeGoalScorer:
        return getContent(translationKeys.FIRST_LAST_ANYTIME_GOALSCORER);
      case configData.firstGoalScorer:
        return getContent(translationKeys.FIRST_GOALSCORER);
      case configData.lastGoalScorer:
        return getContent(translationKeys.LAST_GOALSCORER);
      case configData.AnyTimeGoalScorer:
        return getContent(translationKeys.ANYTIME_GOAL_SCORER);

      case configData.handicaps:
        return getContent(translationKeys.HANDICAPS);
      case configData.firstGoalScorerCorrectScore:
        return getContent(translationKeys.FIRST_GOALSCORER_CORRECT_SCORE);
      default:
        return teamNameToGetTranslated;
    }
  };

  useEffect(() => {
    if (playAnimation !== configData.selectedOddsInputsAndKeypadView) {
      updatedStakePannelAnimation();
    }
  }, [SelectedOddsOptionsCount]);

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.fetchCurrentBetSlip, (info) => {
      const { detail } = info;
      handleAddMoreOddsSlips();
    });
    return () => {
      appEventInstance.remove(AppEventTypes.fetchCurrentBetSlip);
    };
  }, []);

  return (
    <section
      className={classNames(styles.selectedStakeOptionsRoot, {
        [styles.visibility]: SelectedOddsOptionsCount === 0 ? true : false,
        [styles.disableClick]: isCurrentStakeSlipsLoading, // disable clicking on stakePannelInput when currentStake Slip are getting fetched ...
      })}
    >
      <div
        className={classNames(styles.animationRoot, {
          [styles.addPaddingtop]: !betPannelBooleanValues.isBetPlaced,
          [styles.addPaddingBottomg]: !betPannelBooleanValues.showKeypad,
          [styles.selectedOddsInputsView]: playAnimation === configData.selectedOddsInputsView,
          [styles.selectedOddsInputsAndKeypadView]: betPannelBooleanValues.showKeypad,
          [styles.selectedOddsLabelsView]: playAnimation === configData.selectedOddsLabelsView,
          [styles.betPlaceView]: playAnimation === configData.betPlaceView,
        })}
      >
        <Vault hide={!betPannelBooleanValues.isBetPlaced} />
        {/* bet place animation bar  */}
        <div
          className={classNames(styles.betPlaceBar, {
            [styles.hide]: !betPannelBooleanValues.isBetPlaced,
            [styles.slideFromLeft]: true,
          })}
        >
          <img src="/images/bets/active.png" alt="active btn" />
          <div className={styles.flexDirectionCol}>
            <Typography fontFamily="avenirNextSemiBold" size="lg">
              {getContent(translationKeys.BET_PLACED)}
            </Typography>
          </div>
        </div>

        {/* selected options lables counter with market names*/}
        <div
          className={classNames(styles.selectedStakeContainer, {
            [styles.hide]: playAnimation !== configData.selectedOddsLabelsView,
          })}
        >
          <div className={classNames(styles.counterContainer)}>
            <div className={classNames(styles.counter)}>
              <Typography size={SelectedOddsOptionsCount > 99 ? "xs2" : "md"} fontFamily="avenirNextBold" color="green">
                {SelectedOddsOptionsCount}
              </Typography>
            </div>
            <Typography size="md" fontFamily="avenirNextBold" color="black">
              {SelectedOddsOptionsCount > 1
                ? getContent(translationKeys.SELECTIONS)
                : getContent(translationKeys.SELECTION)}
            </Typography>
          </div>
          {/* render selected odds name with market  */}
          <div className={classNames(styles.teamListContainer)}>
            {Object.entries(selectedOddsOptions).map(([key, values], itemsIndex) => {
              const filteredValues = values.filter((value) => value?.betStatus === BetStatus?.AddedToSlip);

              // Calculate how many items can still be rendered
              const remainingSlots = 3 - renderedCount;

              // If no slots are remaining, skip rendering and return "..."
              if (remainingSlots <= 0) {
                return null;
              }

              // Slice the filtered values to ensure only the remaining slots are considered
              const valuesToRender = filteredValues.slice(0, remainingSlots);

              renderedCount += valuesToRender.length; // Update the rendered count

              // Determine if the total length exceeds 3 to show "..."
              const isMoreThanThree =
                Object.values(selectedOddsOptions).flatMap((val) =>
                  val.filter((v) => v?.betStatus === BetStatus?.AddedToSlip),
                ).length > 3;

              return (
                <React.Fragment key={`${key}-${itemsIndex}`}>
                  {valuesToRender.map((value) => (
                    <div key={`${value?.marketName}-${value?.betId}`} className={classNames(styles.selectedTeamList)}>
                      <Typography
                        hide={value.marketName !== configData?.finalResult}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)}
                      </Typography>
                      <Typography
                        hide={value.marketName !== configData?.goalsOverUnder}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {value.oddsDetails.type &&
                          value.oddsDetails.type.charAt(0).toUpperCase() + value.oddsDetails.type.slice(1)}{" "}
                        {value.oddsDetails.goals}
                      </Typography>
                      <Typography
                        hide={value.marketName !== configData?.bothTeamsToScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getContent(value.oddsDetails.value?.toLowerCase() as string)}
                      </Typography>

                      <Typography
                        hide={value.marketName !== configData?.resultBothTeamsScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)} /{" "}
                        {getContent(value.oddsDetails.value?.toLowerCase() as string)}
                      </Typography>

                      <div className={classNames(styles.selectedMarketsLabels)}>
                        <Typography
                          hide={value.marketName !== configData?.correctScoree}
                          color="black"
                          fontFamily="avenirNextBold"
                        >
                          {`${value.oddsDetails.team1} - ${value.oddsDetails.team2}`}
                        </Typography>
                        <Typography
                          hide={value.marketName !== configData?.correctScoree}
                          fontFamily="avenirFont"
                          color="black"
                          size="md2"
                        >
                          {getTeamName(value.oddsDetails.win as string)}
                        </Typography>
                      </div>

                      <div className={classNames(styles.selectedMarketsLabels)}>
                        <Typography
                          hide={value.marketType !== configData?.firstLastAnytimeGoalScorerType}
                          color="black"
                          fontFamily="avenirNextBold"
                        >
                          {getPlayerName(value.oddsDetails.playerId)}
                        </Typography>
                        <Typography
                          hide={value.marketType !== configData?.firstLastAnytimeGoalScorerType}
                          fontFamily="avenirFont"
                          color="black"
                          size="md2"
                        >
                          {getTeamName(value.oddsDetails.teamId as string)}
                        </Typography>
                      </div>

                      <Typography
                        hide={value.marketName !== configData?.handicaps}
                        color="black"
                        fontFamily="avenirNextBold"
                        size="md2"
                      >
                        {getTeamName(value.oddsDetails.win as string)}{" "}
                        {`${String(value.oddsDetails.handicap).startsWith("-") ? "" : "+"} ${value.oddsDetails.handicap}`}
                      </Typography>

                      <Typography
                        hide={value.marketName !== configData?.firstGoalScorerCorrectScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getPlayerName(value.oddsDetails.playerId)} /{" "}
                        {`${value.oddsDetails.team1} - ${value.oddsDetails.team2}`}
                      </Typography>
                    </div>
                  ))}
                  {isMoreThanThree && renderedCount >= 3 && (
                    <Typography color="gray" fontFamily="degularBold" size="xl" className={styles.moreText}>
                      ...
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        {/* arrow down  */}
        <div
          className={classNames(styles.arrow, {
            [styles.hide]: betPannelBooleanValues.isBetPlaced,
            [styles.centerFlatArrowDown]: playAnimation === configData.selectedOddsLabelsView,
          })}
        >
          <img
            src="icon/flatArrowDown.png"
            alt="flatArrowDown"
            onClick={() => {
              if (betPannelBooleanValues.showKeypad) {
                // setShowKeyPad(!betPannelBooleanValues.showKeypad);
                setBetPannelBooleanValues((prev) => {
                  return { ...prev, showKeypad: !prev.showKeypad };
                });
              }
              if (playAnimation === configData.selectedOddsInputsView) {
                setPlayAnimation(configData.selectedOddsLabelsView);
              } else {
                setPlayAnimation(configData.selectedOddsInputsView);
              }
            }}
          />
        </div>
        {/* arrow down  end*/}

        <div
          className={classNames(styles.row, {
            [styles.hide]: playAnimation === configData.selectedOddsLabelsView,
          })}
        >
          <section
            ref={listRef}
            className={classNames(styles.stakeInputsContainer, {
              [styles.stakeInputsContainerForBetReceipts]: betPannelBooleanValues.isBetPlaced,
            })}
          >
            <ContentShell loading={isCurrentStakeSlipsLoading} loadingType="grayLoader">
              {Object.entries(betPannelBooleanValues.isBetPlaced ? activeOddsOptions : selectedOddsOptions).map(
                ([key, values], oddsIndex) => (
                  <React.Fragment key={key}>
                    {values.map((item, index) => {
                      return (
                        <div
                          key={`${item.betId}-${index}`}
                          className={classNames(styles.stakeInputsList, {
                            [styles.addMinHeightToList]: betPannelBooleanValues.isBetPlaced,
                          })}
                        >
                          <div
                            className={classNames(styles.col, {
                              [styles.addPaddingtop]: betPannelBooleanValues.isBetPlaced,
                            })}
                          >
                            <Typography
                              color="purpleBlue700"
                              size={item.marketName.length > 25 ? "xs" : item.marketName.length > 20 ? "md" : "md2"}
                              fontFamily="avenirNextMedium"
                              isEllipsis={true}
                              width="50%"
                              noWrap={true}
                            >
                              {getContent(_getTeamNameForTranslation(item.marketName))}
                            </Typography>
                          </div>
                          {/* selected market list below with stake pannel inputs ..... */}
                          <div className={classNames(styles.col)}>
                            <div>
                              <div className={classNames(styles.flexSubCol, styles.addGap4)}>
                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketName !== configData.finalResult,
                                  })}
                                >
                                  {" "}
                                  <Typography
                                    color="purpleBlue"
                                    fontFamily="avenirNextBold"
                                    size={getTeamName(item.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                                    isEllipsis={true}
                                    width="80%"
                                  >
                                    {getTeamName(item.oddsDetails.win as string)}
                                  </Typography>
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>

                                {/* ================= */}
                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketName !== configData.goalsOverUnder,
                                  })}
                                >
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {getContent(item.oddsDetails.type as string)}
                                  </Typography>
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {item.oddsDetails.goals}
                                  </Typography>{" "}
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>

                                {/* ================= */}

                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketName !== configData.bothTeamsToScore,
                                  })}
                                >
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {getContent(item.oddsDetails.value?.toLowerCase() as string)}
                                  </Typography>{" "}
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>

                                {/* ================= */}

                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketName !== configData.resultBothTeamsScore,
                                  })}
                                >
                                  <Typography
                                    color="purpleBlue"
                                    fontFamily="avenirNextBold"
                                    size={getTeamName(item.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                                    isEllipsis={true}
                                    width="80%"
                                  >
                                    {getTeamName(item.oddsDetails.win as string)} /{" "}
                                    {getContent(item.oddsDetails.value?.toLowerCase() as string)}
                                  </Typography>
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>

                                {/* ================= */}

                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketName !== configData.correctScoree,
                                    [styles.flexColDirection]: true,
                                  })}
                                >
                                  <div>
                                    {" "}
                                    <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                      {item.oddsDetails.team1} - {item.oddsDetails.team2}
                                    </Typography>
                                    <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md2">
                                      {_displayOddType(item as any)}
                                    </Typography>
                                  </div>
                                  <Typography
                                    color="purpleBlue"
                                    fontFamily="avenirFont"
                                    size="md2"
                                    isEllipsis={true}
                                    width="100%"
                                  >
                                    {getTeamName(item.oddsDetails.win as string)}
                                  </Typography>
                                </div>

                                {/* ================= */}

                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketType !== configData.firstLastAnytimeGoalScorerType,
                                    [styles.flexColDirection]: true,
                                  })}
                                >
                                  <div>
                                    {" "}
                                    <Typography
                                      color="purpleBlue"
                                      fontFamily="avenirNextBold"
                                      size="md"
                                      isEllipsis={true}
                                      width="80%"
                                      noWrap={true}
                                    >
                                      {getPlayerName(item.oddsDetails.playerId)}
                                    </Typography>
                                    <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                      {_displayOddType(item as any)}
                                    </Typography>
                                  </div>
                                  <Typography
                                    color="purpleBlue"
                                    fontFamily="avenirFont"
                                    size="md2"
                                    isEllipsis={true}
                                    width="100%"
                                  >
                                    {item.oddsDetails?.playerId && getTeamName(item.oddsDetails.teamId as string)}
                                  </Typography>
                                </div>

                                {/* ================= */}

                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketName !== configData.handicaps,
                                    [styles.flexColDirection]: true,
                                  })}
                                >
                                  <div>
                                    {" "}
                                    <Typography
                                      color="purpleBlue"
                                      fontFamily="avenirNextBold"
                                      size={getTeamName(item.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                                      isEllipsis={true}
                                    >
                                      {getTeamName(item.oddsDetails.win as string)}
                                    </Typography>{" "}
                                    <Typography
                                      color="purpleBlue"
                                      fontFamily="avenirNextBold"
                                      size={getTeamName(item.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                                    >
                                      {`${String(item.oddsDetails.handicap).startsWith("-") ? "" : "+"}${item.oddsDetails.handicap}`}
                                    </Typography>
                                    <Typography
                                      color="purpleBlue"
                                      fontFamily="avenirNextBold"
                                      size={getTeamName(item.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                                    >
                                      {_displayOddType(item as any)}
                                    </Typography>
                                  </div>
                                </div>

                                {/* ================= */}

                                <div
                                  className={classNames(styles.marketDetails, {
                                    [styles.hide]: item.marketName !== configData.firstGoalScorerCorrectScore,
                                    [styles.flexColDirection]: true,
                                  })}
                                >
                                  <div>
                                    {" "}
                                    <Typography
                                      color="purpleBlue"
                                      fontFamily="avenirNextBold"
                                      size={getPlayerName(item.oddsDetails?.playerId).length > 9 ? "md2" : "md"}
                                      noWrap={true}
                                    >
                                      {getPlayerName(item.oddsDetails?.playerId)} /
                                      {`${item.oddsDetails.team1}-${item.oddsDetails.team2}`}
                                    </Typography>
                                    <Typography
                                      color="purpleBlue"
                                      fontFamily="avenirNextBold"
                                      size={item.odd.length > 4 ? "md2" : "md"}
                                    >
                                      {_displayOddType(item as any)}
                                    </Typography>
                                  </div>
                                  <Typography
                                    color="purpleBlue"
                                    fontFamily="avenirFont"
                                    size="md2"
                                    isEllipsis={true}
                                    width="100%"
                                  >
                                    {item.oddsDetails?.playerId && getTeamName(item.oddsDetails.teamId as string)}
                                  </Typography>
                                </div>
                              </div>
                              <div className={styles.teamNameDetails}>
                                <Typography
                                  color="purpleBlue700"
                                  fontFamily="avenirNextMedium"
                                  size="xs2"
                                  width="45%"
                                  isEllipsis={true}
                                  noWrap={true}
                                >
                                  {getTeamName("A")}
                                </Typography>
                                <Typography color="purpleBlue700" fontFamily="avenirNextMedium" size="xs2">
                                  {configData?.v}
                                </Typography>
                                <Typography
                                  color="purpleBlue700"
                                  fontFamily="avenirNextMedium"
                                  size="xs2"
                                  width="40%"
                                  isEllipsis={true}
                                >
                                  {getTeamName("B")}
                                </Typography>
                              </div>
                              {/* <Typography color="purpleBlue700" fontFamily="avenirNextMedium" size="xs2">
                  {sessionHelper?.oddsDetails?.teamA?.name} {configData?.v}
                  {sessionHelper?.oddsDetails?.teamB?.name}
                </Typography>{" "} */}
                              <div
                                className={classNames(styles.flexSubCol, styles.addTopSpacing, {
                                  [styles.visibility]:
                                    item.betAmount && Number(item?.betAmount) >= Number(sessionHelper?.minBet)
                                      ? false
                                      : true,
                                })}
                              >
                                <Typography color="purple" fontFamily="avenirNextBold" size="xs1">
                                  {/* update this once xpBoostPercentage comes in the current Bet Slip  */}
                                  {getBoostPercentage(item?.decimalOdd) > 0 &&
                                    `${getBoostPercentage(item?.decimalOdd as any)}% ${getContent(translationKeys.VAULT_XP_BOOST)} `}
                                  {item.betAmount &&
                                  sessionHelper?.oddsDetails?.xpToAward &&
                                  getBoostPercentage(item?.decimalOdd as any) >= 20
                                    ? setNumberFormat(
                                        (
                                          item.betAmount * sessionHelper?.oddsDetails?.xpToAward +
                                          (item.betAmount *
                                            sessionHelper?.oddsDetails?.xpToAward *
                                            getBoostPercentage(item?.decimalOdd)) /
                                            100
                                        ).toLocaleString() ?? 0,
                                        0,
                                      )
                                    : item.betAmount &&
                                      sessionHelper?.oddsDetails?.xpToAward &&
                                      setNumberFormat(
                                        (item.betAmount * sessionHelper?.oddsDetails?.xpToAward).toLocaleString() ?? 0,
                                        0,
                                      )}
                                  {getContent(translationKeys.XP)}
                                </Typography>
                                <img
                                  src="icon/info.png"
                                  alt="flatArrowDown"
                                  onClick={() => {
                                    udpateModalStates({ isOpenStakeInfoModal: true });
                                  }}
                                  className="h-4 w-4"
                                />
                              </div>
                            </div>
                            <div
                              className={classNames(styles.inputWidth, {
                                [styles.hide]: betPannelBooleanValues.isBetPlaced,
                              })}
                              onClick={() => handleSetActiveInputId(item.oddId, item.marketName, item.betId)}
                            >
                              <TextFeild
                                valueColor={item?.betAmount && item?.betAmount >= 0 ? "purpleBlue" : "purpleBlue500"}
                                isStakeInputHeight={true}
                                isLargeText={item?.betAmount ? item.betAmount > 4 : false}
                                startIcon={"currency"}
                                labelColor={"purpleBlue700"}
                                //@ts-ignore
                                borderColor={
                                  activeOddForEnteringStake.oddId === item?.oddId ? "purpleBlue" : "lightGray"
                                }
                                label={configData.stake}
                                //@ts-ignore
                                value={item?.betAmount === 0 ? item?.betAmount : item?.betAmount}
                                backGroundColor="lightGrayBg"
                                onChange={() => handleInputChange}
                                noDefaultKeypad={true}
                                placeholder="0.00"
                                actualBetAmount={item.betAmount as any}
                                defaultKeyOrNumberKey={defaultKeyOrNumbersKey}
                              />
                              <div>
                                <Typography
                                  hide={
                                    item?.betAmount && Number(item.betAmount) > 0 && SelectedOddsOptionsCount !== 1
                                      ? false
                                      : true
                                  }
                                  color="purpleBlue700"
                                  size={item?.betAmount && item?.betAmount > 10 ? "xs2" : "xs1"}
                                  fontFamily="avenirFont"
                                  noWrap={true}
                                >
                                  {`${getContent(translationKeys.TO_RETURN_TITLE)} ${setCurrencyFormat({
                                    showCurrency: true,
                                    decimal: 2,
                                    value: calculateToReturn(item.betAmount, item.payoutMultiplier),
                                  })}`}

                                  {/* {setNumberFormat(calculateToReturn(item.betAmount, item.payoutMultiplier), 2)} */}
                                </Typography>
                              </div>
                            </div>
                            <img
                              className={classNames(styles.closeIcon, {
                                [styles.hide]: betPannelBooleanValues.isBetPlaced,
                                [styles.disableClick]: isRemovingBetLoading || isAddingBetLoading, // avoiding clicking if any bet is being place or any bet is being removed....
                              })}
                              src="icon/grayClose.png"
                              alt="grayClose"
                              onClick={() => {
                                handleUserInteraction(item?.oddId, item.marketName);
                                setBetPannelBooleanValues((prev) => {
                                  return { ...prev, isCallingRemovingOddsListApi: true };
                                });
                              }} // basically to check and remove the id Obj
                            />
                            <section
                              className={classNames(styles.stakeToReturn, {
                                [styles.hide]: !betPannelBooleanValues.isBetPlaced,
                              })}
                            >
                              <div className={classNames(styles.row)}>
                                <div>
                                  <Typography
                                    color="purpleBlue700"
                                    size={
                                      getContent(translationKeys.TO_RETURN_TITLE).toString().length > 6 ? "xs" : "md2"
                                    }
                                    fontFamily="avenirNextMedium"
                                    isEllipsis={true}
                                    width="100%"
                                  >
                                    {getContent(translationKeys.STAKE_TITLE)}
                                  </Typography>
                                  <Typography
                                    color="purpleBlue"
                                    size={
                                      setCurrencyFormat({
                                        currency: sessionHelper?.currency,
                                        getCurrencySymbol: true,
                                        value: item?.betAmount ? item.betAmount : 0,
                                        decimal: 2,
                                      }).length > 8
                                        ? "xs2"
                                        : "md"
                                    }
                                    fontFamily="avenirNextBold"
                                    noWrap={true}
                                    isEllipsis={true}
                                    width="100%"
                                  >
                                    {setCurrencyFormat({
                                      currency: sessionHelper?.currency,
                                      getCurrencySymbol: true,
                                      value: item?.betAmount ? item.betAmount : 0,
                                      decimal: 2,
                                    })}
                                    {/* {setNumberFormat(item?.betAmount ?? 0, 2)} */}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    color="purpleBlue700"
                                    size={
                                      getContent(translationKeys.TO_RETURN_TITLE).toString().length > 6 ? "xs" : "md2"
                                    }
                                    fontFamily="avenirNextMedium"
                                    isEllipsis={true}
                                    width="100%"
                                    noWrap={true}
                                  >
                                    {getContent(translationKeys.TO_RETURN_TITLE)}
                                  </Typography>
                                  {/* change the calculation later  */}
                                  <Typography
                                    color="purpleBlue"
                                    size={
                                      setCurrencyFormat({
                                        currency: sessionHelper?.currency,
                                        getCurrencySymbol: true,
                                        value: item.betAmount
                                          ? calculateToReturn(item.betAmount, item.payoutMultiplier)
                                          : 0,
                                        decimal: 2,
                                      }).length > 8
                                        ? "xs2"
                                        : "md"
                                    }
                                    fontFamily="avenirNextBold"
                                    noWrap={true}
                                    isEllipsis={true}
                                    width="100%"
                                  >
                                    {setCurrencyFormat({
                                      currency: sessionHelper?.currency,
                                      getCurrencySymbol: true,
                                      value: item.betAmount
                                        ? calculateToReturn(item.betAmount, item.payoutMultiplier)
                                        : 0,
                                      decimal: 2,
                                    })}
                                    {/* {item.betAmount &&
                                      setNumberFormat(calculateToReturn(item.betAmount, item.payoutMultiplier), 2)} */}
                                  </Typography>
                                </div>
                              </div>

                              <div className={classNames(styles.betRef)}>
                                <Typography color="black" fontFamily="avenirNextMedium" size="xs3" noWrap={true}>
                                  {`${getContent(translationKeys.BET_REF)} : ${item.betId}`}
                                </Typography>
                              </div>
                            </section>
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                ),
              )}
            </ContentShell>
          </section>
          <div
            className={classNames(styles.col, {
              [styles.hide]: isCurrentStakeSlipsLoading,
              [styles.placeBetBtn]: true, // just for adding another closs for styling
            })}
          >
            {/* remove this extraPadding props after clikng on stake input  */}
            <Button
              isShowClickTransition={true}
              addDropShadow={true}
              onClick={() => handlePlaceBet()}
              size="stretch"
              color={checkIsAnyStakeUpdated() ? "darkGreenBtn" : "purpleBlue100"}
              disabled={!checkIsAnyStakeUpdated()}
              addMinHeight={true}
              displayFlex={true}
              isDisableOnClick={
                isPlaceBetLoading ||
                betPannelBooleanValues.disablePlaceBetOnBetPlace ||
                betPannelBooleanValues.disablePlaceBetOnKeyClick
              } // disable until stake udpated from api
            >
              <div className={classNames({ [styles.hide]: betPannelBooleanValues.isBetPlaced })}>
                <div className={styles.placeBetRow}>
                  <Typography
                    hide={betPannelBooleanValues.isBetPlaced}
                    fontFamily="degularBold"
                    color="purpleBlue"
                    size="xl"
                  >
                    {getContent(translationKeys.PLACE_BET)}
                  </Typography>
                  {checkIsAnyStakeUpdated() && (
                    <div
                      className={classNames(styles.addLeftMargin, {
                        [styles.hide]: SelectedOddsOptionsCount === 1,
                      })}
                    >
                      <Typography
                        hide={betPannelBooleanValues.isBetPlaced}
                        fontFamily="degularBold"
                        color="purpleBlue"
                        size="xl"
                      >
                        {/* {setNumberFormat(calculateTotalBetAmount() ?? 0, 2)} before */}
                        {`${setCurrencyFormat({ currency: sessionHelper?.currency, getCurrencySymbol: true, decimal: 2, value: calculateTotalBetAmount() })}`}
                      </Typography>
                    </div>
                  )}
                  <div
                    className={classNames(styles.conditionalLoading, {
                      [styles.activeLoader]: betPannelBooleanValues.disablePlaceBetOnKeyClick,
                    })}
                  >
                    <Typography size="xl" fontFamily="avenirNextBold" color="purpleBlue">
                      ...
                    </Typography>
                  </div>
                </div>
                <Typography
                  hide={!checkIsAnyStakeUpdated() || SelectedOddsOptionsCount > 1} // hide if more than two odds selected
                  fontFamily="avenirNextBold"
                  color="purpleBlue700"
                  size="xs"
                >
                  {/* setNumberFormat(calculateTotalReturnValue() ?? 0, 2) */}
                  {`${getContent(translationKeys.TO_RETURN_TITLE)} ${setCurrencyFormat({ currency: sessionHelper?.currency, decimal: 2, value: calculateTotalReturnValue(), getCurrencySymbol: true })}`}
                </Typography>
              </div>

              <Typography
                hide={!betPannelBooleanValues.isBetPlaced}
                color={betPannelBooleanValues.isBetPlaced ? "purpleBlue" : "purpleBlue700"}
                fontFamily="degularBold"
                size={betPannelBooleanValues.isBetPlaced ? "xl" : "xs"}
              >
                {betPannelBooleanValues.isBetPlaced && getContent(translationKeys.START_MATCH)}
              </Typography>
              <div className={styles.loaderContainer}>
                <Loader
                  type="grayLoader"
                  hide={betPannelBooleanValues.isBetPlaced ? betPannelBooleanValues.isBetPlaced : !isPlaceBetLoading}
                />
              </div>
            </Button>
          </div>
        </div>
        <div
          className={classNames(styles.numbersKeyPad, {
            [styles.showKeypadView]: betPannelBooleanValues.showKeypad,
            [styles.removeShowKeypadView]: betPannelBooleanValues.isBetPlaced || !betPannelBooleanValues.showKeypad,
          })}
        >
          <NumbersKeypad onClickOption={handleOptionClick} onClickKey={handleKeyClick} />
        </div>
      </div>

      <div className={styles.addMoreBetsContainer}>
        <Button
          isShowClickTransition={true}
          onClick={() => handleAddMoreOddsSlips()}
          roundedEdge={true}
          addExtraPadding={true}
          displayFlex={true}
          size="stretch"
          color="blackBtn"
          hide={!betPannelBooleanValues.isBetPlaced}
          disabled={betPannelBooleanValues?.disablePlaceBetOnBetPlace} // disable this until placebetButon isnot enabled for better ui interaction ...
        >
          <img src="icon/backArrow.png" alt="backButton" className={classNames(styles.backButtonImage)} />
          <Typography fontFamily="degularBold" size="2xl" transform={true}>
            {getContent(translationKeys.ADD_MORE_BETS)}
          </Typography>
        </Button>
      </div>
    </section>
  );
};

export default StakePannelInput;
