import { InitResponse, Odds } from "generated/graphql";

class SessionHelper {
  beToken = "";
  balance = 0;
  pbfCoin = 0;
  isFTU = "";
  operatorId = "";
  language = "";
  location = "";
  initData: InitResponse | undefined;
  oddsDetails: Odds | undefined;
  badge = "";
  kit = "";
  kitColor = "";
  minBet = "";
  maxBet = "";
  bankingUrl = "";
  kitColorDark = "";
  maxPayOut = 0;
  extraData = {
    team1Score: "",
    team2Score: "",
    balance: 0,
  };
  content = {} as any;
  currency = "";
  useCheat = false;
  useAnalytics = true;
  currencyUseGrouping = true;
  coinPurchaseAllowed = true;
  currencyLang = "";
  _id = "";

  //@ts-ignore
  onSignIn({
    beToken,
    _id,
    balance,
    bankingUrl,
    pbfCoin,
    isFTU,
    useCheat,
    operatorId,
    language,
    location,
    initData,
    badge,
    kit,
    kitColor,
    kitColorDark,
    extraData,
    content,
    currency,
    maxPayOut,
    useAnalytics,
    minBet,
    maxBet,
    currencyLang,
    currencyUseGrouping,
    coinPurchaseAllowed,
  }: any) {
    this.beToken = beToken;
    this.balance = balance;
    this.pbfCoin = pbfCoin;
    this.isFTU = isFTU;
    this.operatorId = operatorId;
    this.language = language;
    this.location = location;
    this.initData = initData;
    this.badge = badge || "";
    this.kit = kit || "";
    this.kitColor = kitColor || "";
    this.kitColorDark = kitColorDark || "";
    this.extraData = extraData || {};
    this.content = content;
    this.minBet = minBet;
    this.maxBet = maxBet;
    this.bankingUrl = bankingUrl;
    this._id = _id;
    this.maxPayOut = maxPayOut;
    this.currency = currency;
    this.useCheat = useCheat;
    this.useAnalytics = useAnalytics ?? this.useAnalytics;
    this.currencyUseGrouping = currencyUseGrouping;
    this.currencyLang = currencyLang ?? language;
    this.coinPurchaseAllowed = coinPurchaseAllowed;
  }

  updateOddDetails(oddsDetails: Odds) {
    this.oddsDetails = oddsDetails as Odds;
  }
}

export default new SessionHelper();
