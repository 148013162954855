import { ICarouselNode } from "components/Carousal";
import { SetCurrencyFormatParams } from "core/interfaces/common";
import { BettingMarketsEnum, PlayerPositionEnum, UserBets } from "generated/graphql";
import { getContent } from "i18n";
import { DateTime } from "luxon";
import { round } from "mathjs";
import sessionHelper from "service/sessionHelper";

export const formatDataToCarousalNodeType = <T>(list: Array<T> = []) => {
  const formattedData: Array<ICarouselNode<T>> = list.map((item: T) => {
    return {
      id: (item as ICarouselNode<T>).name as string,
      data: item,
    };
  });

  return formattedData;
};

export const debug = ({ event, message }: { event: string; message: string }) => {
};

export function extractCurrencySymbol(amount: string, currency = "") {
  // Try to match common currency symbols or alphabetic currency indicators
  const currencySymbolPattern = /[\p{Sc}]/u;

  // Use match method to find the currency symbol or code
  const match = amount.match(currencySymbolPattern);

  // If a match is found, return the first matched symbol; otherwise, return the currency code
  return match ? match[0] : currency;
}

export const isObjectAndHasData = (data: unknown): data is Record<string, any> => {
  return typeof data === "object" && data !== null && Object.keys(data).length > 0;
};

export const isArrayAndHasData = <T>(data: T[]): boolean => {
  return Array.isArray(data) && data.length > 0;
};

export const getConfigLabel = (label: string): string => {
  return getContent(label, ["7"]); // TODO: Fix this
};

export const parseMessage = ({ str, map }: { str: string; map: Array<any> }): string => {
  let st = "";
  const len = map.length;
  for (let n = 0; n < len; n++) {
    st = `{{text${n + 1}}}`;
    str = str.replace(st, map[n]);
  }
  return str;
};

export const formatCurrencyString = (value: string | number) => {
  const currencySymbol = setCurrencyFormat({ getCurrencySymbol: true });
  const formatted = new Intl.NumberFormat(sessionHelper?.currencyLang || "en-IN", {
    style: "currency",
    currency: sessionHelper?.currency || "GBP",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).formatToParts(0);

  const symbolBefore = formatted[0]?.type === "currency";

  return symbolBefore ? `${currencySymbol} ${value}` : `${value} ${currencySymbol}`;
};

export const setCurrencyFormat = ({
  value = 0,
  funMode = false,
  language = sessionHelper?.currencyLang || "en-IN",
  currency = sessionHelper?.currency || "GBP",
  decimal = 0,
  getCurrencySymbol = false,
  showZeroWithSymbol = false,
}: SetCurrencyFormatParams): string => {
  const numericValue = Number(value) || 0;
  const shouldShowSymbol = getCurrencySymbol || showZeroWithSymbol;

  const formatter = new Intl.NumberFormat(language, {
    style: funMode ? "decimal" : "currency",
    currency: funMode ? undefined : currency,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    useGrouping: sessionHelper.currencyUseGrouping,
  });

  if (shouldShowSymbol) {
    const symbol = formatter.formatToParts(0).find((part) => part.type === "currency")?.value || currency;

    return numericValue || decimal > 0 || showZeroWithSymbol ? formatter.format(numericValue) : symbol;
  }

  return formatter.format(numericValue);
};

export const setNumberFormat = (number: any, decimal: any, skipRegionalFormating = false) => {
  if (!number || isNaN(number) || isNaN(decimal) || decimal < 0) {
    if (sessionHelper?.language === "id" && !skipRegionalFormating && number === 0) {
      return setCurrencyFormat({
        value: Number(number),
        decimal,
        showCurrency: false,
        format: true,
      });
    } else {
      // return number?.toString()?.split(".")[0] + "." + `000000`.slice(0, decimal);
      return number?.toString()?.split(".")[0] + (decimal > 0 ? "." + `000000`.slice(0, decimal) : "");
    }
  }

  if (sessionHelper?.language === "id" && !skipRegionalFormating) {
    return setCurrencyFormat({
      value: Number(number),
      decimal,
      showCurrency: false,
      format: true,
    });
  }

  // Format the number with commas and decimals
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

  return formatter.format(Number(number));
};

export function formatDateTime(utcTime: string) {
  try {
    const dateTimeUTC = DateTime.fromISO(utcTime, { zone: "utc" });
    const localTime = dateTimeUTC.setZone("local");
    return localTime.toFormat("dd/MM/yy HH:mm:ss");
  } catch (error) {
    console.error("Invalid UTC time string:", error);
    return "-";
  }
}

export const getLanguage = (language: string, location: string) => {
  if (language === "en") {
    if (location === "US") return "American";
    if (location === "UK") return "Decimal";
  }
  return "Fractional";
};

export const getPreferredPositions = (filter: string): PlayerPositionEnum[] | undefined => {
  switch (filter) {
    case "goalkeepers":
      return [PlayerPositionEnum.Gk];
    case "defenders":
      return [PlayerPositionEnum.Cb, PlayerPositionEnum.Lb, PlayerPositionEnum.Rb];
    case "midfielders":
      return [
        PlayerPositionEnum.Cm,
        PlayerPositionEnum.Lm,
        PlayerPositionEnum.Rm,
        PlayerPositionEnum.Cam,
        PlayerPositionEnum.Cdm,
      ];
    case "forwards":
      return [PlayerPositionEnum.St, PlayerPositionEnum.Lw, PlayerPositionEnum.Rw];
    default:
      return undefined; // No specific positions preferred
  }
};

export const getPreferredPositionLabel = (position: string): string => {
  switch (position) {
    case "GK":
      return "Goalkeeper";
    case "LB":
    case "RB":
    case "CB":
      return "Defender";
    case "LM":
    case "RM":
    case "CM":
    case "CAM":
    case "CDM":
      return "Midfielder";
    case "ST":
    case "LW":
    case "RW":
      return "Forward";
    default:
      return "Unknown Position";
  }
};

export const getPositionGroup = (position: PlayerPositionEnum): PlayerPositionEnum[] => {
  const forwardPositions = [PlayerPositionEnum.St, PlayerPositionEnum.Lw, PlayerPositionEnum.Rw];
  const midfielderPositions = [
    PlayerPositionEnum.Lm,
    PlayerPositionEnum.Rm,
    PlayerPositionEnum.Cm,
    PlayerPositionEnum.Cam,
    PlayerPositionEnum.Cdm,
  ];
  const defenderPositions = [PlayerPositionEnum.Lb, PlayerPositionEnum.Rb, PlayerPositionEnum.Cb];
  const goalkeeperPositions = [PlayerPositionEnum.Gk];

  const positionLabel = getPreferredPositionLabel(position);

  switch (positionLabel) {
    case "Goalkeeper":
      return goalkeeperPositions;
    case "Defender":
      return defenderPositions;
    case "Midfielder":
      return midfielderPositions;
    case "Forward":
      return forwardPositions;
    default:
      return [position]; // Return only the specific position if it's unknown
  }
};

export function generateRandomId() {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  let firstStr = ("000" + first.toString(36)).slice(-3);
  let secondStr = ("000" + second.toString(36)).slice(-3);

  return firstStr + secondStr;
}

export function generateRandomUserId() {
  const prefix = "user_";
  const randomId = Math.random().toString(36).substring(2, 10);
  return prefix + randomId;
}

function isValidVersion(version: string): boolean {
  const versionRegex = /^v(\d+)\.(\d+)\.(\d+)$/;
  return versionRegex.test(version);
}

export async function getVersion() {
  try {
    const response = await fetch(`./version.txt`);

    if (response.ok) {
      const version = await response.text();

      if (isValidVersion(version.trim())) {
        return version.trim();
      }
    }

    return "";
  } catch (error) {
    return "0.01"; // or another default string
  }
}

export function debounce<T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeoutId: any;

  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}

export function timer(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}

export const getDecimalLength = (value: string | number): number => {
  const strValue = value.toString();
  const decimalPart = strValue.split(".")[1];
  return decimalPart ? decimalPart.length : 0;
};

export const renderDecimalOdds = (value: string | number): string => {
  const decimalLength = getDecimalLength(value);
  if (decimalLength === 1) {
    return `${value}0`;
  } else if (decimalLength === 0) {
    return `${value}.00`;
  }
  return value.toString();
};

export const getTeamName = (name: string) => {
  // if (name === "A") return sessionHelper?.oddsDetails?.teamA?.name || "-";   // uncomment when we want to show user Actual team Name
  if (name === "A") return getContent("myTeam") || "-"; // remove this once testing is done
  if (name === "B") return sessionHelper?.oddsDetails?.teamB?.name || "-";
  return getContent("draw");
};

/**
 * Round a value towards the nearest decimal
 * @param value
 * @returns
 */
export const roundOffDec = (value: any, numOfDec = 0) => {
  return round(value, numOfDec);
};

export const calculateToReturn = (betAmount: any, multiplier: any) => {
  if (!betAmount) {
    return 0;
  }

  betAmount = Number(betAmount);
  multiplier = Number(multiplier) || 0;

  // Formula: Payout = ROUND((Numerator of Final Fraction / Denominator of Final Fraction) * Stake + Stake)
  // Multiplier is the Final Fraction multiplier, it is calculated from oddsConverter.
  return roundOffDec(betAmount * multiplier + betAmount, 2);
};

export const getBetSelectionForAnalytics = (bet: UserBets): string => {
  let selection: any = "";
  const marketType = bet.marketType;

  if (marketType === BettingMarketsEnum.FinalResult) {
    selection = bet.oddsDetails?.win;
  } else if (marketType === BettingMarketsEnum.CorrectScore) {
    selection = `${bet.oddsDetails?.team1}-${bet.oddsDetails?.team2}`;
  } else if (marketType === BettingMarketsEnum.Btts) {
    selection = bet.oddsDetails?.value;
  } else if (marketType === BettingMarketsEnum.FirstGoalCorrectScore) {
    selection = `${bet.oddsDetails?.team1}_${bet.oddsDetails?.team2}_${bet.oddsDetails?.playerId}_${bet.oddsDetails?.teamId}`;
  } else if (marketType === BettingMarketsEnum.FirstLastAnyGoalScore) {
    selection = `${bet.oddsDetails?.type}_${bet.oddsDetails?.playerId}_${bet.oddsDetails?.teamId}`;
  } else if (marketType === BettingMarketsEnum.Handicaps) {
    selection = `${bet.oddsDetails?.win}_${bet.oddsDetails?.handicap}`;
  } else if (marketType === BettingMarketsEnum.ResultBtts) {
    selection = `${bet.oddsDetails?.value}_${bet.oddsDetails?.win}`;
  } else if (marketType === BettingMarketsEnum.UnderOver) {
    selection = `${bet.oddsDetails?.type}_${bet.oddsDetails?.goals}`;
  }

  return selection;
};
