import { getContent } from "i18n";
import {
  closeSnackbar,
  enqueueSnackbar,
  OptionsWithExtraProps,
  SnackbarKey,
  SnackbarProvider,
  VariantType,
} from "notistack";
import { ReactNode } from "react";

const dismissAction = (snackbarId: SnackbarKey) => (
  <>
    <button
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      Dismiss
    </button>
  </>
);

export const notify = (input: {
  message: string;
  variant?: VariantType;
  autoHideDuration?: number;
  showDismiss?: boolean;
  translationKey?: string;
}) => {
  const { variant, autoHideDuration, showDismiss = true, translationKey } = input;
  let { message } = input;

  message = translationKey ? getContent(translationKey) : message;

  const options: OptionsWithExtraProps<VariantType> = {
    variant: variant ?? "info",
    autoHideDuration: autoHideDuration ?? 3000,
  };

  if (showDismiss) {
    options.action = dismissAction;
  }

  enqueueSnackbar(message, options);
};

interface NotificationProviderProps {
  children: ReactNode;
}

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000}>
      {children}
    </SnackbarProvider>
  );
};

export default NotificationProvider;
