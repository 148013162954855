import React, { useState } from "react";
import Typography from "components/Typography";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import Modal from "components/Modal";
import styles from "styles/components/modals/gameRulesModal.module.css";
import classNames from "classnames";
import { getConfigLabel, setCurrencyFormat } from "util/miscUtils";
import gameJson from "config/gameJSON.json";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import { BettingMarketsEnum, useGetPlatformRtpQuery } from "generated/graphql";
import firebaseAnalytics from "service/firebaseAnalytics";
import { AnalyticsEvents } from "analyticTypes/analyticsEventTypes";
import sessionHelper from "service/sessionHelper";

interface Column {
  id: string;
  label: React.ReactNode;
}

interface TableProps {
  columns: Column[];
  data: { [id: string]: React.ReactNode }[];
}

const Table: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <div className={classNames(styles.tableRoot)}>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.id}>
                <Typography fontFamily="avenirNextBold" size={String(col.label).length > 9 ? "xs2" : "xs"}>
                  {col.label}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col) => (
                <td key={col.id}>
                  <Typography fontFamily="avenirFont" size="xs">
                    {row[col.id]}
                  </Typography>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const preprocessColumns = (columns: Column[]) =>
  columns.map((col) => ({
    id: col.id,
    label: getConfigLabel(col.label as string),
  }));

const preprocessData = (data: any[]) =>
  data.map((row) => {
    const processedRow: { [id: string]: React.ReactNode } = {};
    Object.keys(row).forEach((key) => {
      processedRow[key] = getConfigLabel(row[key]);
    });
    return processedRow;
  });

interface TableRow {
  icon: string;
  title: string;
  description: string;
}

// Define props type
interface InfoTableProps {
  data: TableRow[];
}

const MyTeamChemistryRatingTable: React.FC<InfoTableProps> = ({ data }) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.table}>
        {data.map((row, index) => (
          <div key={index} className={styles.row}>
            <div className={styles.iconCell}>
              <img src={row.icon} alt={row.title} className={styles.icon} />
            </div>
            <div className={styles.titleCell}>
              <Typography size="md">{row.title}</Typography>
            </div>
            <div className={styles.descriptionCell}>
              <Typography size="xs">{row.description}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const GameRulesModal = () => {
  const { isOpenGameRulesModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenGameRulesModal: state.modalStates.isOpenGameRulesModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleClose = () => {
    firebaseAnalytics.trackEvent(AnalyticsEvents.Help_Pages, {
      open_close: 0,
      UserID: sessionHelper._id,
    });
    updateModalStates({ isOpenGameRulesModal: false });
  };

  const [platformRTPValues, setPlatFormRTPValues] = useState({
    BTTS: 0,
    CORRECT_SCORE: 0,
    FINAL_RESULT: 0,
    FIRST_GOAL_CORRECT_SCORE: 0,
    FIRST_LAST_ANY_GOAL_SCORE: 0,
    HANDICAPS: 0,
    RESULT_BTTS: 0,
    UNDER_OVER: 0,
    OVER_ALL: 0,
  });
  const { data, loading, error } = useGetPlatformRtpQuery({
    onCompleted: (data) => {
      setPlatFormRTPValues(data?.players?.getPlatformRtp as any);
      firebaseAnalytics.trackEvent(AnalyticsEvents.Help_Pages, {
        open_close: 1,
        UserID: sessionHelper._id,
      });
    },
    skip: !isOpenGameRulesModal, // dont call until game Rules modal is opened
  });

  let listFormattingStyle = {
    display: "flex",
    alignItems: "flex-start",
  };

  return (
    <Modal
      id="gameRulesModal"
      open={isOpenGameRulesModal}
      handleClose={handleClose}
      title={getContent(translationKeys.GAME_RULES_TITLE)}
      addBorder={true}
      containerSize="xl"
      noPaddingTop={true}
    >
      <div className={classNames(styles.root)}>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.OVERVIEW)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.OVERVIEW_TEXT1)} </Typography>
          <Typography size="xs">{getContent(translationKeys.OVERVIEW_TEXT2)}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.PLAYING_MATCH)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.PLAYING_MATCH_TEXT)}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.TEAM_CONFIGURATION)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.TEAM_CONFIGURATION_TEXT)}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.BETTING)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.BETTING_TEXT1)}</Typography>
          <Typography size="xs">{getContent(translationKeys.BETTING_TEXT2)}</Typography>
          <Typography size="xs">
            {getContent(translationKeys.BETTING_TEXT3, [`${platformRTPValues["OVER_ALL"]}%`])}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.BETTING_TEXT4)}</Typography>
          <Typography size="xs">{getContent(translationKeys.BETTING_TEXT5)}</Typography>
          <Typography size="xs" style={listFormattingStyle}>
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.BETTING_TEXT6, ["2007"])}</span>
          </Typography>
          <Typography size="xs">{getContent(translationKeys.BETTING_TEXT7)}</Typography>
          <Typography size="xs">{getContent(translationKeys.BETTING_TEXT8)}</Typography>
          <Typography size="xs">{getContent(translationKeys.BETTING_TEXT9)}</Typography>
          <Typography fontFamily="degularBold" size="xl">
            {`${getContent(translationKeys.RTP_TITLE_TEXT)} (${getContent(translationKeys.RTP_TITLE)})`}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_TEXT)}</Typography>
          <div className={classNames(styles.data, styles.addBottomSpace)}>
            {[
              [getContent(translationKeys.MARKET), getContent(translationKeys.RTP_TITLE)],
              [
                getContent(translationKeys.OVER_ALL_TEXT),
                platformRTPValues["OVER_ALL"] ? platformRTPValues["OVER_ALL"] + "%" : 0,
              ],
            ].map((row, index) => (
              <div key={index} className={classNames(styles.rows, { [styles.index1]: index === 0 })}>
                <Typography fontFamily="avenirFont" size="xs">
                  {row[0]}
                </Typography>
                <Typography fontFamily="avenirFont" size="xs">
                  {row[1]}
                </Typography>
              </div>
            ))}
          </div>
          <div className={classNames(styles.data)}>
            {[
              [getContent(translationKeys.MARKET), getContent(translationKeys.RTP_S)?.slice(0, 3)],
              [getContent(translationKeys.FINAL_RESULT), platformRTPValues[BettingMarketsEnum.FinalResult] + "%"],
              [getContent(translationKeys.BOTH_TEAMS_TO_SCORE), platformRTPValues[BettingMarketsEnum.Btts] + "%"],
              [getContent(translationKeys.GOALS_OVER_UNDER), platformRTPValues[BettingMarketsEnum.UnderOver] + "%"],
              [
                getContent(translationKeys.RESULT_BOTH_TEAMS_SCORE),
                platformRTPValues[BettingMarketsEnum.ResultBtts] + "%",
              ],
              [getContent(translationKeys.HANDICAPS), platformRTPValues[BettingMarketsEnum.Handicaps] + "%"],
              [getContent(translationKeys.CORRECT_SCORE), platformRTPValues[BettingMarketsEnum.CorrectScore] + "%"],
              [
                getContent(translationKeys.FIRST_LAST_ANYTIME_GOALSCORER),
                platformRTPValues[BettingMarketsEnum.FirstLastAnyGoalScore] + "%",
              ],
              [
                getContent(translationKeys.FIRST_GOALSCORER_CORRECT_SCORE),
                platformRTPValues[BettingMarketsEnum.FirstGoalCorrectScore] + "%",
              ],
            ].map((row, index) => (
              <div key={index} className={classNames(styles.rows, { [styles.index1]: index === 0 })}>
                <Typography fontFamily="avenirFont" size="xs">
                  {row[0]}
                </Typography>
                <Typography fontFamily="avenirFont" size="xs">
                  {row[1]}
                </Typography>
              </div>
            ))}
          </div>

          {/* RTP Values starts */}
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE1)}</Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE2)}</Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE3, ["7", "30"])}</Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE4)}</Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE5)}</Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE6)}</Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE7)}</Typography>
          <Typography size="xs">
            {getContent(translationKeys.RTP_VALUE8, [
              `${setCurrencyFormat({ showCurrency: true, value: sessionHelper.minBet, decimal: 2 })}`,
              `${setCurrencyFormat({ showCurrency: true, value: sessionHelper.maxBet, decimal: 2 })}`,
              `${setCurrencyFormat({ showCurrency: true, value: sessionHelper.maxPayOut, decimal: 2 })}`,
            ])}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.RTP_VALUE9, ["79%", "1.27", "2/7"])}</Typography>

          {/* RTP Values Ends */}

          {/* XP Values Start */}
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.XP)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.XP_VALUE_1)}</Typography>
          <Typography size="xs">{getContent(translationKeys.XP_VALUE_2)}</Typography>

          {/* XP Values End */}

          <div className={classNames(styles.data)}>
            {[
              [getContent(translationKeys.ODDS), getContent(translationKeys.POINTS_MULTIPLIER)],
              [getContent(translationKeys.UP_TO, ["2/1 (3.0, +200)"]), "x1"],
              [getContent(translationKeys.GREATER_THAN, ["2/1 (3.0, +200)"]), "x 1.2 (+20%)"],
              [getContent(translationKeys.GREATER_THAN, ["5/1 - (6.0, +500)"]), "x 1.4 (+40%)"],
              [getContent(translationKeys.GREATER_THAN, ["10/1 - (11.0, +1000)"]), "x 2 (+100%)"],
            ].map((row, index) => (
              <div key={index} className={classNames(styles.rows, { [styles.index1]: index === 0 })}>
                <Typography fontFamily="avenirFont" size="xs">
                  {row[0]}
                </Typography>
                <Typography fontFamily="avenirFont" size="xs">
                  {row[1]}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        {/* <Typography size="xs">{getContent(translationKeys.ODDS_TEXT, ["79%", "1.27", "2/7", "1/4"])}</Typography> */}
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.PBF_COINS)}
          </Typography>
          <Typography size="xs" whiteSpacePreWrap={true}>
            {getContent(
              sessionHelper?.coinPurchaseAllowed ? translationKeys.PBF_COINS_TEXT : translationKeys.PBF_COINS_TEXT_ALT,
            )}
          </Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.MECHANICS)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.MECHANICS_TEXT1)}</Typography>
          <Typography size="xs">{getContent(translationKeys.MECHANICS_TEXT2)}</Typography>
          <Typography size="xs" style={listFormattingStyle}>
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MECHANICS_TEXT3)}</span>
          </Typography>
          <Typography size="xs" style={listFormattingStyle}>
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MECHANICS_TEXT4)}</span>
          </Typography>
          <Typography size="xs" style={listFormattingStyle}>
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MECHANICS_TEXT5)}</span>
          </Typography>
          <Typography size="xs">{getContent(translationKeys.MECHANICS_TEXT6)}</Typography>
          <Typography size="xs">{getContent(translationKeys.MECHANICS_TEXT7)}</Typography>
          <Typography size="xs">{getContent(translationKeys.MECHANICS_TEXT8)}</Typography>
          <Typography size="xs">{getContent(translationKeys.MECHANICS_TEXT9)}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.MY_TEAM)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.MYTEAM_TEXT1, ["4"])}</Typography>

          <MyTeamChemistryRatingTable
            data={[
              {
                icon: "/icon/attack.svg", // Path to your icon images
                title: getContent(translationKeys.ATTACK_TITLE),
                description: getContent(translationKeys.My_TEAM_ATTACK_VALUE),
              },
              {
                icon: "/icon/defence.svg",
                title: getContent(translationKeys.DEFENCE_TITLE),
                description: getContent(translationKeys.My_TEAM_DEFENCE_VALUE),
              },
              {
                icon: "/icon/creativity.svg",
                title: getContent(translationKeys.CREATIVITY_TITLE),
                description: getContent(translationKeys.My_TEAM_CREATIVITY_VALUE),
              },
            ]}
          />

          <Typography size="xs">{getContent(translationKeys.MYTEAM_TEXT2)}</Typography>
          <Typography size="xs">{getContent(translationKeys.MYTEAM_TEXT3)}</Typography>

          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.PLAYER_POSITION_AND_CHEM)}
          </Typography>

          <Typography size="xs">{getContent(translationKeys.MYTEAM_TEXT4)}</Typography>
          <Typography size="xs" style={listFormattingStyle}>
            {" "}
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MYTEAM_TEXT5, ["1"])}</span>
          </Typography>
          <Typography size="xs" style={listFormattingStyle}>
            {" "}
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MYTEAM_TEXT6, ["1", "2"])}</span>
          </Typography>
          <Typography size="xs" style={listFormattingStyle}>
            {" "}
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MYTEAM_TEXT7, ["2", "4"])}</span>
          </Typography>
          <Typography size="xs" style={listFormattingStyle}>
            {" "}
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MYTEAM_TEXT8, ["1"])}</span>
          </Typography>
          <Typography size="xs" style={listFormattingStyle}>
            <span className="mr-1">•</span>{" "}
            <span className="flex-1">{getContent(translationKeys.MYTEAM_TEXT9, ["2", "2"])}</span>
          </Typography>
          <Typography size="xs" style={listFormattingStyle}>
            <span className="mr-1">•</span>
            <span className="flex-1">{getContent(translationKeys.MYTEAM_TEXT10, ["1"])}</span>
          </Typography>

          <Typography size="xs">{getContent(translationKeys.MYTEAM_TEXT11, ["4"])}</Typography>
          <Table columns={preprocessColumns(gameJson?.chemistryScore)} data={gameJson?.chemistryScoreData} />
        </div>
        <div className={classNames(styles.text)}>
          {/* <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.PLAYER_POSITION_AND_CHEM)}
          </Typography> */}
          <Typography size="xs">{getContent(translationKeys.PLAYER_POSITION_TEXT)}</Typography>
          <Table
            columns={preprocessColumns(gameJson?.playerPosition)}
            data={preprocessData(gameJson?.playerPositionData)}
          />
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.PLAYER_RATING)}
          </Typography>
          <Typography size="xs">{getContent(translationKeys.PLAYER_RATING_TEXT, ["99", "+10", "95", "99"])}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.BOOSTS)}
          </Typography>
          <Typography size="xs" whiteSpacePreWrap={true}>
            {getContent(translationKeys.BOOSTS_TEXT, ["3"])}
          </Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.DIVISIONS)}
          </Typography>
          <Typography size="xs" whiteSpacePreWrap={true}>
            {getContent(translationKeys.DIVISIONS_TEXT, ["10", "7", "10", "7"])}
          </Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.DAILY_REAWRDS)}
          </Typography>
          <Typography size="xs" whiteSpacePreWrap={true}>
            {getContent(translationKeys.DAILY_REWARDS_TEXT, ["7", "7"])}
          </Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.STORES)}
          </Typography>
          <Typography size="xs" whiteSpacePreWrap={true}>
            {getContent(
              sessionHelper?.coinPurchaseAllowed ? translationKeys.STORES_TEXT : translationKeys.STORES_TEXT_ALT,
            )}
          </Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.PROMO_PACKS_TITLE)}
          </Typography>
          <Typography size="xs" whiteSpacePreWrap={true}>
            {getContent(translationKeys.PROMO_PACKS_TEXT)}
          </Typography>
        </div>{" "}
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {getContent(translationKeys.END_OF_SEASON_TITLE)}
          </Typography>
          <Typography size="xs" whiteSpacePreWrap={true}>
            {getContent(translationKeys.END_OF_SEASON_TEXT)}
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(GameRulesModal);
