import React from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import { TeamData } from "core/interfaces/common";
import styles from "styles/pages/teamSelect/components/statsCard.module.css";
import { translationKeys } from "i18n/translationKeys.enums";
import { getContent } from "i18n";

interface StatsCardProps {
  team: "opponent" | "myTeam";
  tableTitle: string;
  roundedCorner: Boolean | false;
  bgColor?: String;
  data: TeamData;
}

const StatsCard: React.FC<StatsCardProps> = ({ team = "myteam", data, bgColor }) => {
  if (!data) {
    return <Typography>{getContent(translationKeys.DATA_NOT_FOUND)}</Typography>;
  }

  const { attackRating, defenseRating, creativityRating, overallRating, kitColor, chemistry, chemistryPercentage } =
    data;

  const team1 = [
    {
      value: attackRating + (chemistry ? chemistry : 0),
      label: getContent(translationKeys.ATTACK_TITLE),
      icon: "icon/attack.svg",
    },
    {
      value: defenseRating + (chemistry ? chemistry : 0),
      label: getContent(translationKeys.DEFENCE_TITLE),
      icon: "icon/defence.svg",
    },
    {
      value: creativityRating + (chemistry ? chemistry : 0),
      label: getContent(translationKeys.CREATIVITY_TITLE),
      icon: "icon/creativity.svg",
    },
    { value: chemistryPercentage, label: getContent(translationKeys.CHEMISTRY_TITLE), icon: "icon/chemistry.svg" },
    { value: overallRating, label: getContent(translationKeys.OVR_TEXT) },
  ];

  const team2 = [
    { value: chemistryPercentage, label: getContent(translationKeys.CHEMISTRY_TITLE), icon: "icon/chemistry.svg" },
    {
      value: creativityRating + (chemistry ? chemistry : 0),
      label: getContent(translationKeys.CREATIVITY_TITLE),
      icon: "icon/creativity.svg",
    },
    {
      value: defenseRating + (chemistry ? chemistry : 0),
      label: getContent(translationKeys.DEFENCE_TITLE),
      icon: "icon/defence.svg",
    },
    {
      value: attackRating + (chemistry ? chemistry : 0),
      label: getContent(translationKeys.ATTACK_TITLE),
      icon: "icon/attack.svg",
    },
    { value: overallRating, label: getContent(translationKeys.OVR_TEXT) },
  ];

  return (
    <section
      style={{ background: `#${kitColor}` }}
      className={classNames(styles.statsCardRoot, {
        [styles.oppBg]: team === "opponent",
      })}
    >
      <div
        className={classNames(styles.row, {
          [styles.oppoTeamDetails]: team === "opponent",
        })}
      >
        {(team === "opponent" ? team2 : team1).map((data, index) => {
          return (
            <React.Fragment key={index}>
              <div
                key={`${index}-data`}
                className={classNames(styles.col, {
                  [styles.oppoTeamDetails]: team === "opponent",
                })}
              >
                {/* show all the lable except OVR */}
                {/* <Typography color="gray" size="xs3" fontFamily="avenirNextMedium">
                  {data.label !== getContent(translationKeys.OVR_TEXT) ? data.label : ""}
                </Typography> */}
                <div className={classNames(styles.statsRow)}>
                  {data.label !== getContent(translationKeys.OVR_TEXT) && <img src={data.icon} />}
                  <Typography
                    fontFamily={"avenirNextSemiBold"}
                    size={data.label === getContent(translationKeys.OVR_TEXT) ? "2xl" : "xs"}
                    hide={data.label === getContent(translationKeys.OVR_TEXT)}
                  >
                    {data.value}
                    {data.label === getContent(translationKeys.CHEMISTRY_TITLE) && "%"}
                  </Typography>
                </div>
                <Typography
                  fontFamily={"avenirNextBold"}
                  size={data.label === getContent(translationKeys.OVR_TEXT) ? "2xl" : "lg"}
                  hide={data.label !== getContent(translationKeys.OVR_TEXT)}
                >
                  {data.value}
                </Typography>
              </div>
              <div
                className={classNames(styles.divider, {
                  [styles.hide]: index >= team1?.length - 2,
                })}
              ></div>
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default StatsCard;
