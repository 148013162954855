import React, { PropsWithChildren, HTMLAttributes } from "react";
import classNames from "classnames";
import Loader from "components/Loader";
import styles from "styles/components/contentShell.module.css";

interface ContentShellProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  loading?: boolean;
  loadingType?: string;
}

const ContentShell: React.FC<ContentShellProps> = ({ children, loading = false, loadingType = "loader", ...props }) => {
  return (
    <div id="contentShell" className={classNames(styles.root, {})} {...props}>
      {loading ? (
        <Loader type={loadingType} isAddMinHeight={loadingType === "grayLoader" ? true : false} />
      ) : (
        <div className={styles.content}>{children}</div>
      )}
    </div>
  );
};

export default ContentShell;
