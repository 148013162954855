import React from "react";
import sessionHelper from "service/sessionHelper";
import styles from "styles/pages/wallet/wallet.module.css";

function Wallet() {
  return (
    <section className={styles.container}>
      <iframe src={`${sessionHelper?.bankingUrl}`} width="100%" height="100%" style={{ border: "none" }}></iframe>
    </section>
  );
}

export default Wallet;
