import classNames from "classnames";
import Drawer from "components/Drawer";
import Typography from "components/Typography";
import { DrawerPositions, ZIndexLevels } from "core/enums";
import { useGetCountryLeaderboardQuery, useGetGlobalLeaderboardQuery } from "generated/graphql";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import { useState } from "react";
import useGameStore from "store/gameStore";
import styles from "styles/components/drawers/leaderBoardDrawer.module.css";
import { shallow } from "zustand/shallow";

const LeaderBoardDrawer = () => {
  const [filter, setFilter] = useState<"Country" | "Global">("Country");
  const [countryImg, setCountryImg] = useState<string | null | undefined>("");
  const [championsData, setChampionsData] = useState<{ country: any; global: any }>({
    country: null,
    global: null,
  });

  const { isOpenLeaderBoardDrawer, updateDrawerStates } = useGameStore(
    (state) => ({
      isOpenLeaderBoardDrawer: state.drawerStates.isOpenLeaderBoardDrawer,
      updateDrawerStates: state.updateDrawerStates,
    }),
    shallow,
  );

  const fetchLeaderboardData = (data: any, type: "Country" | "Global") => {
    const leaderboard =
      type === "Country"
        ? data?.players?.getCountryLeaderboard?.countryLeaderboard
        : data?.players?.getGlobalLeaderboard?.globalLeaderboard;

    const userRanking =
      type === "Country"
        ? data?.players?.getCountryLeaderboard.userRanking
        : data?.players?.getGlobalLeaderboard.userRanking;
    return {
      leaderboard: leaderboard ? leaderboard : [],
      userRanking: userRanking ? userRanking : null,
    };
  };

  const { data: countryData, loading: countryLoading } = useGetCountryLeaderboardQuery({
    skip: !isOpenLeaderBoardDrawer,
    onCompleted: (data) => {
      setChampionsData((prev: any) => ({
        ...prev,
        country: fetchLeaderboardData(data, "Country"),
      }));
      setCountryImg(data?.players?.getCountryLeaderboard?.userRanking?.imageUrl);
    },
  });

  const { data: globalData, loading: globalLoading } = useGetGlobalLeaderboardQuery({
    skip: !isOpenLeaderBoardDrawer,
    onCompleted: (data) => {
      setChampionsData((prev: any) => ({
        ...prev,
        global: fetchLeaderboardData(data, "Global"),
      }));
    },
  });

  const isLoading = countryLoading || globalLoading;

  const handleClose = () => {
    if (isOpenLeaderBoardDrawer) {
      updateDrawerStates({ isOpenLeaderBoardDrawer: false });
    }
  };

  const title = getContent(translationKeys.CHAMPION_TITLE) || "";
  const words = title.split(" ");
  const rankImages: Record<number, string> = {
    1: "images/leaderBoard/1.png",
    2: "images/leaderBoard/2.png",
    3: "images/leaderBoard/3.png",
  };
  const currentLeaderboard = championsData[filter.toLowerCase() as "country" | "global"];
  let goldStarRatingLength = currentLeaderboard?.userRanking?.goldStars;
  return (
    <Drawer
      isOpen={isOpenLeaderBoardDrawer}
      position={DrawerPositions.Bottom}
      id="leaderBoardDrawer"
      onClose={handleClose}
      showFooterButton={true}
      zIndexLevel={ZIndexLevels.Level2}
      header={
        <div className={styles.header}>
          <img src="images/leaderBoard/star.png" alt="star" className={styles.championStar} />
          <Typography fontFamily="degularBold">
            <span className={styles.division}>{words.slice(0, 2).join(" ")}</span>
            <span className={styles.champion}>{words.slice(2).join(" ")}</span>
          </Typography>
          <img src="images/leaderBoard/star.png" alt="star" className={styles.championStar} />
        </div>
      }
    >
      <div className={classNames(styles.divWrap)}>
        <div className={classNames(styles.firstDiv)}>
          <div className={classNames(styles.tabBorder)}>
            <div
              className={classNames(styles.tab, { [styles.activeTab]: filter === "Country" })}
              onClick={() => setFilter("Country")}
            >
              {countryImg ? (
                <img src={countryImg} alt="countryImg" className="h-4" />
              ) : (
                <div className={styles.spinner}>
                  <img className="w-6" src="icon/dotsSpinningLoader.gif" alt="loading gif" />
                </div>
              )}
              <Typography fontFamily="degularBold" size="md2">
                {getContent(translationKeys.COUNTRY_TITLE)}
              </Typography>
            </div>
          </div>
          <div className={classNames(styles.tabBorder)}>
            <div
              className={classNames(styles.tab, { [styles.activeTab]: filter === "Global" })}
              onClick={() => setFilter("Global")}
            >
              <img src="/images/leaderBoard/globe.png" alt="globeImg" className="h-4" />
              <Typography fontFamily="degularBold" size="md2">
                {getContent(translationKeys.GLOBAL_TITLE)}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classNames(styles.secondDiv)}>
          <div>
            <div className={styles.rankText}>
              <Typography fontFamily="degularBold" size="md3">
                {getContent(translationKeys.RANK_TITLE)}
              </Typography>
            </div>
            <div className={styles.rankText}>
              <Typography fontFamily="degularBold" size="md3" transform>
                {getContent(translationKeys.TEAM_TEXT)}
              </Typography>
            </div>
          </div>
          <Typography fontFamily="degularBold" size="md3">
            {getContent(translationKeys.CHAMPIONSHIP_TITLE)}
          </Typography>
        </div>
        {isLoading ? (
          <div className={styles.spinner}>
            <img className="w-10" src="icon/dotsSpinningLoader.gif" alt="loading gif" />
          </div>
        ) : (
          <section className={styles.leaderBoardContainer}>
            {[...(currentLeaderboard?.leaderboard || [])]?.map((data: any, index: any) => {
              return (
                <div className={styles.leaderBoardRow} key={index}>
                  <div className={styles.col}>
                    {rankImages[data?.ranking] ? (
                      <img className={styles.rankImage} src={rankImages[data.ranking]} alt="" />
                    ) : (
                      <Typography fontFamily="degularBold" size="lg">
                        {data?.ranking > 0 ? data?.ranking : "- -"}
                      </Typography>
                    )}
                  </div>
                  <div className={styles.col}>
                    <img src={data?.team?.badgeUrl || ""} alt="" />
                  </div>
                  <div className={styles.col}>
                    {data.userId === currentLeaderboard?.userRanking?.userId ? (
                      <Typography fontFamily="degularBold" width="100%" size="md2" noWrap={true} isEllipsis={true}>
                        {getContent("myTeam")}
                      </Typography>
                    ) : (
                      <Typography fontFamily="degularBold" width="100%" size="md2" noWrap={true} isEllipsis={true}>
                        {data?.team?.name || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={styles.col}>
                    <img src={data?.imageUrl || ""} alt="" />
                  </div>
                  <div className={styles.col}>
                    <Typography
                      fontFamily="avenirNextBold"
                      color="black"
                      size={
                        data?.goldStars?.toString()?.length > 3
                          ? "xs4"
                          : data?.goldStars?.toString()?.length > 2
                            ? "xs2"
                            : "xs"
                      }
                    >
                      {data?.goldStars ?? "0"}
                    </Typography>
                  </div>
                </div>
              );
            })}
            {currentLeaderboard?.userRanking?.ranking === 0 || currentLeaderboard?.userRanking?.ranking > 10 ? (
              <section
                className={classNames(styles.rowContainer, {
                  [styles.isSticky]: true,
                })}
              >
                <div
                  className={classNames(styles.leaderBoardRow, {
                    [styles.activeBlueBg]: true,
                  })}
                >
                  <div className={styles.col}>
                    {rankImages[currentLeaderboard?.userRanking?.ranking] ? (
                      <img
                        className={styles.rankImage}
                        src={rankImages[currentLeaderboard?.userRanking?.ranking]}
                        alt=""
                      />
                    ) : (
                      <Typography fontFamily="degularBold" size="lg">
                        {currentLeaderboard?.userRanking?.ranking > 0
                          ? currentLeaderboard?.userRanking?.ranking
                          : "- -"}
                      </Typography>
                    )}
                  </div>
                  <div className={styles.col}>
                    <img src={currentLeaderboard?.userRanking?.team?.badgeUrl || ""} alt="" />
                  </div>
                  <div className={styles.col}>
                    <Typography fontFamily="degularBold" width="100%" size="md2" noWrap={true} isEllipsis={true}>
                      {getContent("myTeam")}
                    </Typography>
                  </div>
                  <div className={styles.col}>
                    <img src={currentLeaderboard?.userRanking?.imageUrl || ""} alt="" />
                  </div>
                  <div className={styles.col}>
                    <Typography
                      fontFamily="avenirNextBold"
                      color="black"
                      size={
                        goldStarRatingLength?.toString()?.length > 3
                          ? "xs4"
                          : goldStarRatingLength?.toString()?.length > 2
                            ? "xs2"
                            : "xs"
                      }
                    >
                      {currentLeaderboard?.userRanking?.goldStars ?? "0"}
                    </Typography>
                  </div>
                </div>
              </section>
            ) : null}
          </section>
        )}
      </div>
    </Drawer>
  );
};

export default LeaderBoardDrawer;
