import config from "config/config.json";
import errConfig from "config/err.json";
import sessionHelper from "service/sessionHelper";

const getConfig = (lang = null) => {
  let configData = { ...config };
  let errConfigData = { ...errConfig }; // Use this instead of directly reassigning errConfig

  switch (lang || sessionHelper?.language) {
    case true:
      configData = { ...configData };
      errConfigData = { ...errConfigData }; // Update errConfigData, not errConfig
      break;
    default:
  }

  return { configData, errConfigData };
};

export default getConfig;