import React from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import { formatDateTime, renderDecimalOdds, setCurrencyFormat, setNumberFormat } from "util/miscUtils";
import styles from "styles/pages/bets/components/betSlip.module.css";
import sessionHelper from "service/sessionHelper";
import { BetStatus, CoinHistoryResponse, OddsDisplayEnum, UserBets } from "generated/graphql";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import getConfig from "util/configHelper";
import { MARKET_ENUMS } from "core/enums";

type BetItems = {
  betId: string;
  oddId: string;
  currency: string;
  betAmount: number;
  winAmount: number;
  toReturn: number;
  marketType: string;
  marketName: string;
  xp: number;
  xpBoostPercentage: number;
  odd: string;
  decimalOdd: number;
  fractionalOdd: string;
  americanOdd: string;
  isResolved: boolean;
  betStatus: string;
  createdAt: string;
  oddsDetails: {
    win: string;
    goals: string;
    type: string;
    value: string;
    team1: string;
    team2: string;
    playerId: number;
    teamId: string;
    handicap: string;
    xpBoostPercentage: number;
  };
};

type Bet = {
  createdAt: string;
  myTeamName: string;
  opponentTeamName: string;
  bets: BetItems[];
  allPlayers: [];
};

type Props = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    betSlipData: Bet;
    pbfCoinsData: CoinHistoryResponse;
    isShowBetHistory?: boolean;
  }
>;

const BetSlip = ({ betSlipData, pbfCoinsData, isShowBetHistory }: Props) => {
  const { configData, errConfigData } = getConfig();
  const getTeamName = (name: string) => {
    // if (name === "A") return betSlipData.myTeamName || "-";
    if (name === "A") return getContent("myTeam") || "-"; // remove this once testing is done
    if (name === "B") return betSlipData.opponentTeamName || "-";
    return "Draw";
  };

  const { selectedOddsDisplay } = useGameStore(
    (state) => ({
      selectedOddsDisplay: state.selectedOddsDisplay,
    }),
    shallow,
  );

  const getPlayerName = (playerId: number | string, allPlayersList: any) => {
    if (!playerId) return getContent(translationKeys.NO_GOAL_SCORER);
    let playerDetails = allPlayersList?.filter((ele: any, index: any) => ele.playerId === playerId);
    if (playerDetails?.length) {
      return playerDetails[0].name;
    } else {
      // return getContent(translationKeys.GEN06);
      return errConfigData.GEN06;
    }
  };

  function getOddsDisplay(betItems: any) {
    if (!selectedOddsDisplay) return "";

    switch (selectedOddsDisplay) {
      case OddsDisplayEnum.Fractional:
        return betItems.fractionalOdd.toString();
      case OddsDisplayEnum.Decimal:
        return renderDecimalOdds(betItems.decimalOdd) || "D";
      case OddsDisplayEnum.American:
        return betItems.americanOdd.toString();
      default:
        return "--";
    }
  }

  const _getTeamNameForTranslation = (teamNameToGetTranslated: string) => {
    switch (teamNameToGetTranslated) {
      case configData.finalResult:
        return getContent(translationKeys.FINAL_RESULT);
      case configData.goalsOverUnder:
        return getContent(translationKeys.GOALS_OVER_UNDER);
      case configData.bothTeamsToScore:
        return getContent(translationKeys.BOTH_TEAMS_TO_SCORE);
      case configData.resultBothTeamsScore:
        return getContent(translationKeys.RESULT_BOTH_TEAMS_SCORE);
      case configData.correctScoree:
        return getContent(translationKeys.CORRECT_SCORE);
      case configData.firstLastAnytimeGoalScorer:
        return getContent(translationKeys.FIRST_LAST_ANYTIME_GOALSCORER);
      case configData.firstGoalScorer:
        return getContent(translationKeys.FIRST_GOALSCORER);
      case configData.lastGoalScorer:
        return getContent(translationKeys.LAST_GOALSCORER);
      case configData.AnyTimeGoalScorer:
        return getContent(translationKeys.ANYTIME_GOAL_SCORER);

      case configData.handicaps:
        return getContent(translationKeys.HANDICAPS);
      case configData.firstGoalScorerCorrectScore:
        return getContent(translationKeys.FIRST_GOALSCORER_CORRECT_SCORE);
      default:
        return teamNameToGetTranslated;
    }
  };

  const getTypographySize = (length: any) => {
    if (length > 25) return "xs2";
    if (length > 18) return "md";
    return "md2";
  };

  const isRefundRelatedStatus = [BetStatus.RefundSuccess, BetStatus.RefundStarted, BetStatus.RefundFailed];

  return (
    <div className={classNames(styles.betSlip)}>
      <header>
        <div className={classNames(styles.teamName)}>
          {isShowBetHistory ? (
            <>
              <Typography size="md2" fontFamily="avenirNextMedium" isEllipsis={true} width="50%">
                {/* {betSlipData.myTeamName} */}
                {getContent("myTeam")}
              </Typography>
              <Typography size="md2" fontFamily="avenirNextMedium">
                v
              </Typography>
              <Typography size="md2" fontFamily="avenirNextMedium" isEllipsis={true} width="50%">
                {betSlipData.opponentTeamName}
              </Typography>
            </>
          ) : (
            <Typography size="md" fontFamily="avenirNextMedium">
              {getContent(translationKeys.COIN_PURCHASE_TITLE)}
            </Typography>
          )}
        </div>
        <Typography size="xs" fontFamily="avenirNextMedium">
          {`${formatDateTime(betSlipData.createdAt)}`}
        </Typography>
      </header>
      {isShowBetHistory ? (
        <>
          {" "}
          {betSlipData.bets.map((betItems, betItemsIndex) => {
            return (
              <div key={`${betItemsIndex}-${betItems.betId}`} className={classNames(styles.betSlipItems)}>
                <div className={classNames(styles.col)}>
                  <img
                    className={styles.activeStatusIcon}
                    src={`/images/bets/${isRefundRelatedStatus.includes(betItems.betStatus as BetStatus) ? "voidIcon" : betItems.isResolved ? "active" : "inActive"}.png`}
                    alt="active btn"
                  />
                </div>

                {/* =================== */}

                <div
                  className={classNames(styles.col, styles.addGap, {
                    [styles.adjustwidth]:
                      betItems.marketName.length > 10 || betItems.marketName === configData.handicaps,
                  })}
                >
                  <Typography
                    color="grayPlus"
                    size={betItems.marketName.length > 25 ? "xs2" : betItems.marketName.length > 18 ? "md" : "md2"}
                    fontFamily="avenirNextMedium"
                  >
                    {getContent(_getTeamNameForTranslation(betItems?.marketName))}
                  </Typography>
                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketName !== configData.finalResult,
                    })}
                  >
                    {" "}
                    <Typography
                      color="white"
                      fontFamily="avenirNextBold"
                      size={getTeamName(betItems.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                      isEllipsis={true}
                      width="60%"
                    >
                      {getTeamName(betItems.oddsDetails.win as string)}
                    </Typography>
                    <Typography color="green" fontFamily="avenirNextBold" size="md">
                      {getOddsDisplay(betItems)}
                    </Typography>
                  </div>

                  {/* ================= */}
                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketName !== configData.goalsOverUnder,
                    })}
                  >
                    {" "}
                    <Typography color="white" fontFamily="avenirNextBold" size="md">
                      {getContent(betItems.oddsDetails.type)}
                    </Typography>
                    <Typography color="white" fontFamily="avenirNextBold" size="md">
                      {betItems.oddsDetails.goals}
                    </Typography>{" "}
                    <Typography color="green" fontFamily="avenirNextBold" size="md">
                      {getOddsDisplay(betItems)}
                    </Typography>
                  </div>

                  {/* ================= */}

                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketName !== configData?.bothTeamsToScore,
                    })}
                  >
                    <Typography color="white" fontFamily="avenirNextBold" size="md">
                      {getContent(betItems.oddsDetails.value?.toLowerCase())}
                    </Typography>{" "}
                    <Typography color="green" fontFamily="avenirNextBold" size="md">
                      {getOddsDisplay(betItems)}
                    </Typography>
                  </div>

                  {/* ================= */}

                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketName !== configData?.resultBothTeamsScore,
                    })}
                  >
                    <Typography
                      color="white"
                      fontFamily="avenirNextBold"
                      size={betItems.betAmount > 5 ? "md2" : "md"}
                      noWrap={true}
                      isEllipsis={true}
                      width="80%"
                    >
                      {getTeamName(betItems.oddsDetails.win as string)} /{" "}
                      {getContent(betItems.oddsDetails.value?.toLowerCase())}
                    </Typography>
                    <Typography color="green" fontFamily="avenirNextBold" size="md">
                      {getOddsDisplay(betItems)}
                    </Typography>
                  </div>

                  {/* ================= */}

                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketName !== configData?.correctScoree,
                      [styles.flexColDirection]: true,
                      [styles.adjustwidth]: betItems.marketName.length > 15,
                    })}
                  >
                    <div>
                      {" "}
                      <Typography color="white" fontFamily="avenirNextBold" size="md">
                        {betItems.oddsDetails.team1} - {betItems.oddsDetails.team2}
                      </Typography>
                      <Typography color="green" fontFamily="avenirNextBold" size="md2">
                        {getOddsDisplay(betItems)}
                      </Typography>
                    </div>
                    <Typography color="white" fontFamily="avenirFont" size="md2" isEllipsis={true} width="100%">
                      {getTeamName(betItems.oddsDetails.win as string)}
                    </Typography>
                  </div>

                  {/* ================= */}

                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketType !== configData?.firstLastAnytimeGoalScorerType,
                      [styles.flexColDirection]: true,
                    })}
                  >
                    <div>
                      {" "}
                      <Typography
                        color="white"
                        fontFamily="avenirNextBold"
                        size="md"
                        isEllipsis={true}
                        width="100%"
                        noWrap={true}
                      >
                        {getPlayerName(betItems.oddsDetails.playerId, betSlipData.allPlayers)}
                      </Typography>
                      <Typography color="green" fontFamily="avenirNextBold" size="md">
                        {getOddsDisplay(betItems)}
                      </Typography>
                    </div>
                    {/* show team Name if any player exist (check for no Goal Scorer) */}
                    <Typography color="white" fontFamily="avenirFont" size="md2" isEllipsis={true} width="100%">
                      {betItems?.oddsDetails?.playerId && getTeamName(betItems.oddsDetails.teamId as string)}
                    </Typography>
                  </div>

                  {/* ================= */}

                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketName !== configData?.handicaps,
                      [styles.flexColDirection]: true,
                    })}
                  >
                    <div>
                      {" "}
                      <Typography
                        color="white"
                        fontFamily="avenirNextBold"
                        size={getTeamName(betItems.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                        isEllipsis={true}
                        width="50%"
                      >
                        {getTeamName(betItems.oddsDetails.win as string)}
                      </Typography>{" "}
                      <Typography
                        color="white"
                        fontFamily="avenirNextBold"
                        size={getTeamName(betItems.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                      >
                        {/* {` ${betItems.oddsDetails.handicap}`} */}
                        {`${String(betItems.oddsDetails.handicap).startsWith("-") ? "" : "+"}${betItems.oddsDetails.handicap}`}
                      </Typography>
                      <Typography
                        color="green"
                        fontFamily="avenirNextBold"
                        size={getTeamName(betItems.oddsDetails.win as string).length > 9 ? "md2" : "md"}
                      >
                        {getOddsDisplay(betItems)}
                      </Typography>
                    </div>
                  </div>

                  {/* ================= */}

                  <div
                    className={classNames(styles.marketDetails, {
                      [styles.hide]: betItems.marketName !== configData?.firstGoalScorerCorrectScore,
                      [styles.flexColDirection]: true,
                    })}
                  >
                    <div className={styles.flexColDirection}>
                      {" "}
                      <Typography color="white" fontFamily="avenirNextBold" size="md">
                        {getPlayerName(betItems.oddsDetails?.playerId, betSlipData?.allPlayers)} /
                        {` ${betItems.oddsDetails.team1}-${betItems.oddsDetails.team2} `}
                      </Typography>
                      <Typography color="green" fontFamily="avenirNextBold" size="md">
                        {getOddsDisplay(betItems)}
                      </Typography>
                    </div>
                    {/* show team Name if any player exist (check for no Goal Scorer) */}
                    <Typography color="white" fontFamily="avenirFont" size="md2" isEllipsis={true} width="100%">
                      {betItems?.oddsDetails?.playerId && getTeamName(betItems.oddsDetails.teamId as string)}
                    </Typography>
                  </div>
                  <Typography fontFamily="avenirNextMedium" size="xs2" color="grayPlus" noWrap={true}>
                    {`${getContent(translationKeys.BET_REF)} : ${betItems.betId}`}
                  </Typography>
                </div>

                {/* =================== */}

                <div className={classNames(styles.col)}>
                  <div className={classNames(styles.stakeToReturnSubCol)}>
                    <div className={classNames(styles.row)}>
                      <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
                        {getContent(translationKeys.STAKE_TITLE)}
                      </Typography>
                      <Typography
                        fontFamily="avenirNextBold"
                        size={
                          setNumberFormat(betItems.betAmount ?? 0, 2).length +
                            setCurrencyFormat({ currency: betItems.currency, getCurrencySymbol: true }).length >
                          6
                            ? "xs1"
                            : "md2"
                        }
                        isEllipsis={true}
                        width="100%"
                        noWrap={true}
                      >
                        {`${setCurrencyFormat({ currency: betItems?.currency, value: betItems.betAmount, decimal: 2 })}`}
                      </Typography>
                    </div>
                    <div className={classNames(styles.row)}>
                      <Typography color="grayPlus" size="md2" fontFamily="avenirFont" noWrap={true}>
                        {getContent(translationKeys.TO_RETURN_TITLE)}
                      </Typography>
                      <Typography
                        fontFamily="avenirNextBold"
                        size={
                          setNumberFormat(betItems.toReturn ?? 0, 2).length +
                            setCurrencyFormat({ currency: betItems?.currency, getCurrencySymbol: true }).length >
                          6
                            ? "xs1"
                            : "md2"
                        }
                        color={betItems.toReturn > 0 ? "green" : "white"}
                        isEllipsis={true}
                        width="100%"
                        noWrap={true}
                        transform={true}
                      >
                        {isRefundRelatedStatus.includes(betItems.betStatus as BetStatus)
                          ? getContent(translationKeys.VOID_TITLE_TEXT)
                          : `${setCurrencyFormat({ currency: betItems?.currency, value: betItems.toReturn, decimal: 2 })}`}
                      </Typography>
                    </div>
                  </div>
                  <Typography fontFamily="avenirNextMedium" size="xs2" color="grayPlus" noWrap={true}>
                    {`${formatDateTime(betItems.createdAt)}`}
                  </Typography>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <section className={classNames(styles.coinHistoryItem)}>
            <div className={classNames(styles.col)}>
              <img src="images/bets/pbfCoin.png" alt="pbf" />
            </div>
            <div className={classNames(styles.col)}>
              <Typography fontFamily="avenirNextBold" size="md">
                {getContent(translationKeys.PBF_COINS_TITLE)}
              </Typography>
              <Typography fontFamily="avenirNextMedium" size="xs3" color="gray">
                {getContent(translationKeys.PBF_COIN_REF, [pbfCoinsData?.txnId])}
              </Typography>
            </div>
            <div className={classNames(styles.col)}>
              <div className={classNames(styles.row)}>
                <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
                  {getContent(translationKeys.SPENT_TITLE)}
                </Typography>
                <Typography fontFamily="avenirNextBold" size={"md3"} isEllipsis={true} width="100%" noWrap={true}>
                  {`${setCurrencyFormat({ currency: sessionHelper?.currency, value: pbfCoinsData.price, decimal: 2 })}`}
                </Typography>
              </div>
              <div className={classNames(styles.row)}>
                <Typography color="grayPlus" size="md2" fontFamily="avenirFont" noWrap={true}>
                  {getContent(translationKeys.VALUE_TTTLE)}
                </Typography>
                <Typography
                  fontFamily="avenirNextBold"
                  size={"md3"}
                  color={"white"}
                  isEllipsis={true}
                  width="100%"
                  noWrap={true}
                >
                  {pbfCoinsData?.amount?.toLocaleString("en-IN") || 0}
                </Typography>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default BetSlip;
