import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/finalResult.module.css";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import { debug, getTeamName } from "util/miscUtils";
import sessionHelper from "service/sessionHelper";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { OddsDisplayEnum, WinEnum } from "generated/graphql";
import { matchStatusEnums } from "core/enums";
import Typography from "components/Typography";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";

const FinalResult = () => {
  const [openContent, setOpenContent] = useState(true);
  const [loadingIds, setLoadingIds] = useState(new Set()); // Tracks loading state by item ID

  const keyName = sessionHelper.oddsDetails?.finalResult?.name as string;
  const selectedFinalResultOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow); // just get a object for final Result Market ..

  const {
    setSelectedOddsOptions,
    setActiveOddForEnteringStake,
    updateStakeInputPannelState,
    selectedOddsDisplay,
    matchStatus,
  } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      selectedOddsDisplay: state.selectedOddsDisplay,
      updateStakeInputPannelState: state.updateStakeInputPannelState,
      matchStatus: state.matchStatus,
      setActiveOddForEnteringStake: state.setActiveOddForEnteringStake,
    }),
    shallow,
  );

  let finalResultOddDetails = sessionHelper.oddsDetails?.finalResult;

  const { handleBoxClick, isAddingBetLoading, isRemovingBetLoading } = useHandleAddUpdateOdds();

  const handleUserInteraction = async (id: string, keyName: string, odd: any) => {
    if (matchStatus === matchStatusEnums.betPlaced) return; // If the match is already placed, do nothing
    try {
      setLoadingIds((prev) => new Set(prev).add(id)); // Mark the specific box as loading
      let resp = await handleBoxClick(id, keyName, [...selectedFinalResultOptions]);
      setSelectedOddsOptions(resp || [], keyName);

      let getUpdatedOddsOptions = useGameStore.getState();
      const result = Object.entries(getUpdatedOddsOptions.selectedOddsOptions).find(([_, value]) => value.length);
      if (result) {
        //  the result will be like ["key", [array of values]];
        let activeInputBoxDetails = result[1][0];
        setActiveOddForEnteringStake({
          oddId: activeInputBoxDetails?.oddId,
          key: activeInputBoxDetails?.marketName,
          betId: activeInputBoxDetails?.betId,
        });
      } else {
        setActiveOddForEnteringStake({ oddId: "", key: "", betId: "" });
      }

      updateStakeInputPannelState({ isOpenStakeInputsPannel: true }); // Logic handling here
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingIds((prev) => {
        const updated = new Set(prev);
        updated.delete(id); // Remove loading state for the box
        return updated;
      });
    }
  };

  useEffect(() => {
    debug({ event: "calling", message: "final result inside useEffect" });
  }, []);

  return (
    <section className={classNames(styles.root)}>
      <Accordion
        open={openContent}
        boxHeading={getContent(translationKeys?.FINAL_RESULT) || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.boxList}>
          {[WinEnum.A, WinEnum.Draw, WinEnum.B].map((winType, index) => {
            const item = finalResultOddDetails?.data.find((item) => item.win === winType);
            if (!item) return null; // Skip if no matching data

            return (
              <section key={`${item.decimalOdd}-${item.odd}-${index}`} className={classNames(styles.list)}>
                <div className={classNames(styles.col)}>
                  <Typography
                    color="grayPlus"
                    size={getTeamName(item.win).length > 9 ? "md2" : "md"}
                    fontFamily="avenirFont"
                    noWrap={true}
                    isEllipsis={true}
                    width="100%"
                  >
                    {getTeamName(item.win)}
                  </Typography>
                </div>
                <BoxSelection
                  active={
                    selectedFinalResultOptions.length &&
                    selectedFinalResultOptions?.some((ele) => ele.oddId === item.id)
                      ? true
                      : false
                  } // Determine active state
                  onClick={() => handleUserInteraction(item.id, keyName, item.odd)}
                  // title={getTeamName(item.win)}
                  value={
                    selectedOddsDisplay
                      ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                        ? item.fractionalOdd.toString()
                        : selectedOddsDisplay === OddsDisplayEnum.Decimal
                          ? item.decimalOdd.toString()
                          : item.americanOdd.toString()
                      : ""
                  }
                  isClicked={loadingIds.has(item.id)} // Check if this box is in a loading state
                  disableClick={isAddingBetLoading || isRemovingBetLoading}
                />
              </section>
            );
          })}
        </div>
      </Accordion>
    </section>
  );
};

export default React.memo(FinalResult);
