// Enum for event names
export enum AnalyticsEvents {
  Bet = "Bet",
  FTUE = "FTUE",
  Team_Management = "Team_Management",
  Help_Pages = "Help_Pages",
  Team_Customisation = "Team_Customisation",
}

export interface BetParams {
  bet_market?: string;
  selection?: string;
  platform?: string;
  bet_result?: number;
  placed_datetime?: string;
  BetID?: string;
  bet_selected?: string;
  bet_selected_market?: string;
  [key: string]: any;
}

export interface FTUEParams {
  step?: number;
  FTUE_country_selected?: string;
  first_game_result?: string;
  FTUE_bet_placed?: number;
  FTUE_bet_market?: string;
  FTUE_bet_result?: number;
  FTUE_selection?: string;
  [key: string]: any;
}

export interface TeamManagementParams {
  formation: number;
  team_rating: number;
  team_chemistry: number;
  player_sold: string;
}

// Generic type for Firebase events
export type AnalyticsEventParams = BetParams | FTUEParams | TeamManagementParams;
