import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Modal from "components/Modal";
import Typography from "components/Typography";
import appEventInstance from "util/eventHandler";
import { AppEventTypes } from "core/enums";
import styles from "styles/components/modals/canvasPopUpModal.module.css";
import { timer } from "util/miscUtils";
import { getContent } from "i18n";
import { BeErrorCodeTranslationKeyMap } from "i18n/BeErrorCodeTranslationKeyMap";
import { translationKeys } from "i18n/translationKeys.enums";

const CanvasPopUpModal = () => {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [isSuccess, setIssuccess] = useState(false);

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.toggleCanvasErrorModal, (info) => {
      const { detail } = info;

      const { msg, translationKey, success = false, error } = detail ?? {};

      let message = msg ?? getContent(translationKeys.GENERIC_ERROR_MESSAGE);

      if (translationKey) {
        message = getContent(translationKey);
      } else if (!success && error) {
        const customErrCode = error.cause?.extensions?.code;
        const httpCode = error.cause?.extensions?.httpCode;
        const requestId = error.cause?.extensions?.requestId;

        if (customErrCode) {
          const translationKey = BeErrorCodeTranslationKeyMap[customErrCode];

          if (translationKey) {
            message = getContent(translationKey);
          }

          // eslint-disable-next-line eqeqeq
          if (customErrCode == 2300) {
            setIsReload(true);
          }
        }
      }

      setMsg(message);
      setIssuccess(success);
      setOpen(true);
    });
    return () => {
      appEventInstance.remove(AppEventTypes.toggleCanvasErrorModal);
    };
  }, []);

  const handleClose = async () => {
    if (isReload) {
      window.location.reload();
    }
    appEventInstance.dispatch({
      eventType: AppEventTypes.fetchCurrentBetSlip,
      payload: { translationKey: "fetch" },
    });

    setOpen(false);
    let _modal = document.getElementById(AppEventTypes.toggleCanvasErrorModal);
    setTimeout(() => {
      _modal?.classList.add(styles.hide);
    }, 500);
    await timer(200);
    setMsg("");
  };

  return (
    <Modal
      id={`${AppEventTypes.toggleCanvasErrorModal}`}
      open={open}
      handleClose={handleClose}
      title=""
      containerSize={isSuccess ? "lg" : "xs"}
      disableOverlayInteraction={true}
    >
      <div
        className={classNames(styles.body, {
          [styles.addSuccessGap]: isSuccess,
        })}
      >
        <img
          src="icon/errorPopUp.png"
          alt="errorPopUp"
          className={classNames(styles.icon, {
            [styles.hide]: isSuccess,
          })}
        />
        <div className={classNames(styles.errormsg)}>
          <Typography fontFamily="avenirNextSemiBold">{msg}</Typography>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(CanvasPopUpModal);
