import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import styles from "styles/components/drawers/settingsDrawer.module.css";
import Typography from "components/Typography";
import Drawer from "components/Drawer";
import { CanvasMessage, DrawerPositions, ZIndexLevels } from "core/enums";
import useGameStore from "store/gameStore";
import {
  OddsDisplayEnum,
  useUpdateUserSettingsMutation,
  useMeQuery,
  useUpdateBalanceMutation,
  useUpdateUserExtraDataMutation,
} from "generated/graphql";
import sessionHelper from "service/sessionHelper";
import { getVersion } from "util/miscUtils";
import appEventInstance from "util/eventHandler";
import _Error, { ErrorMessage } from "util/ErrHandler";
import { AppEventTypes } from "core/enums";
import Button from "components/Button";
import gameJson from "config/gameJSON.json";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import TextFeild from "components/TextFeild";
import { notify } from "components/Notifications";

const SettingsDrawer: React.FC<{}> = () => {
  const [selectedOption, setSelectedOption] = useState<string>(OddsDisplayEnum.Fractional);

  const [isChecked, setIsChecked] = useState(true);
  const [updateUserSettingsMutation] = useUpdateUserSettingsMutation();
  const {
    isOpenSettingsDrawer,
    updateDrawerStates,
    updateModalStates,
    setSelectedOddsDisplay,
    selectedOddsDisplay,
    isUserCheating,
  } = useGameStore(
    (state) => ({
      isOpenSettingsDrawer: state.drawerStates.isOpenSettingsDrawer,
      updateDrawerStates: state.updateDrawerStates,
      updateModalStates: state.updateModalStates,
      setSelectedOddsDisplay: state.setSelectedOddsDisplay,
      selectedOddsDisplay: state.selectedOddsDisplay,
      isUserCheating: state.isUserCheating,
    }),
    shallow,
  );
  const [forceBuildData, setForceBuildData] = useState({
    team1Score: sessionHelper?.extraData?.team1Score || "",
    team2Score: sessionHelper?.extraData?.team2Score || "",
    balance: "",
  });

  const { refetch, data } = useMeQuery({
    skip: !isOpenSettingsDrawer,
    onCompleted: (data) => {
      let _oddType = data?.players?.me?.settings?.oddsDisplay;
      setSelectedOddsDisplay(_oddType);
      setIsChecked(data?.players?.me?.settings?.audio);
    },
  });

  const [updateBalanceMutation, { data: updateBalance, loading, error }] = useUpdateBalanceMutation({
    variables: {
      balance: Number(forceBuildData.balance),
    },
  });

  const [
    updateUserExtraDataMutation,
    { data: userExtraData, loading: isUpdateUserExtraDataLoading, error: isUpdateUserExtraDataError },
  ] = useUpdateUserExtraDataMutation();

  const updateUserBalance = async () => {
    // notify("Updating Balance", "success");
    // return
    try {
      let updateBalanceResp = await updateBalanceMutation();
      if (updateBalanceResp.data?.players?.updateBalance?.data?.balance === Number(forceBuildData.balance)) {
        setForceBuildData((prev) => {
          return { ...prev, balance: "" };
        });
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { msg: "Balance Updated Successfully.", success: true },
        });
      }
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({
        eventType: AppEventTypes.toggleErrorModal,
        payload: { msg: "Something Went Wrong! try again later" },
      });
    }
  };

  const udpateUserExtraData = async (isClearScoreCheat: boolean) => {
    try {
      let extraData = isClearScoreCheat
        ? { clearScoreCheat: isClearScoreCheat }
        : { team1Score: Number(forceBuildData.team1Score) || 0, team2Score: Number(forceBuildData.team2Score) || 0 };

      let updateUserExtraDataResp = await updateUserExtraDataMutation({
        variables: { extraData },
      });
      if (updateUserExtraDataResp?.data?.players?.updateUserExtraData?._id) {
        setForceBuildData((prev) => {
          return { ...prev, team1Score: "", team2Score: "" };
        });
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { msg: "Team Score Updated Successfully.", success: true },
        });
      }

      // send the updated respnse to canvas once the balance is updated
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({
        eventType: AppEventTypes.toggleErrorModal,
        payload: { msg: errMsg?.message },
      });
    }
  };

  const [version, setVersion] = useState("");

  const updateSettings = async (updatedSettings: { oddsDisplay: OddsDisplayEnum; audio: boolean }) => {
    try {
      const resp = await updateUserSettingsMutation({
        variables: { input: updatedSettings },
      });
    } catch (error) {
      appEventInstance.dispatch({
        eventType: AppEventTypes.toggleErrorModal,
        payload: { error },
      });
    }
  };

  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    setSelectedOddsDisplay(newSelectedOption as string);
    //@ts-ignore
    await _transferInfoToPixi?.({
      type: CanvasMessage.oddsType,
      value: { oddsType: newSelectedOption },
    });
    await updateSettings({ oddsDisplay: newSelectedOption as OddsDisplayEnum, audio: isChecked });
  };

  const handleToggle = async () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);
    //@ts-ignore
    await _transferInfoToPixi?.({
      type: CanvasMessage.isAudioOn,
      value: { isAudioOn: newIsChecked },
    });
    await updateSettings({ oddsDisplay: selectedOption as OddsDisplayEnum, audio: newIsChecked });
  };

  const _transferInfoToPixi = async (data: { type: string; value: any }) => {
    const iframeRef = useGameStore.getState().iframeRef;
    //@ts-ignore
    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow
    ) {
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension(data);
    }
  };

  const toggleModal = () => {
    // make an api call to get RTP values and set in the session Helper. once check the proper response ..
    updateModalStates({ isOpenGameRulesModal: true });
  };

  const _handleResetGame = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  const handleClose = () => {
    if (isOpenSettingsDrawer) {
      updateDrawerStates({ isOpenSettingsDrawer: false });
    }
  };

  //In Local value will be hardcoded please ignore
  useEffect(() => {
    getVersion().then(setVersion);
  }, []);

  return (
    <Drawer
      isOpen={isOpenSettingsDrawer}
      position={DrawerPositions.Bottom}
      id="isOpenSettingsDrawerClose"
      onClose={handleClose}
      showFooterButton={true}
      zIndexLevel={ZIndexLevels.Level2}
      header={
        <div className={classNames(styles.header)}>
          <Typography fontFamily="degularBold" size="2xl">
            {getContent(translationKeys.SETTINGS)}
          </Typography>
        </div>
      }
    >
      <section className={styles.box}>
        <div className={classNames(styles.root)}>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold">
                {getContent(translationKeys.ODDS_DISPLAY)}
              </Typography>
            </div>
            {gameJson.oddsDisplay?.map((item, index) => (
              <div key={index} className={classNames(styles.marginBtm, styles.borderOddsDisplay)}>
                <div className={classNames(styles.firstDiv)}>
                  <div className={classNames(styles.flexRow, { [styles.selected]: selectedOption === item.label })}>
                    <Typography
                      fontFamily="avenirNextSemiBold"
                      color={item.label === selectedOddsDisplay ? "white" : "gray"}
                    >
                      {getContent(item.label)}
                    </Typography>
                    <Typography
                      fontFamily="avenirNextSemiBold"
                      color={item.label === selectedOddsDisplay ? "white" : "gray"}
                    >{`(${item.value})`}</Typography>
                  </div>
                  <label className={classNames(styles.radioContainer)}>
                    <input
                      type="radio"
                      name="settings"
                      value={item.label}
                      checked={selectedOption === item.label}
                      onChange={handleRadioChange}
                      className={classNames(styles.hiddenRadio)}
                    />
                    <div
                      className={classNames(styles.customRadio, {
                        [styles.customRadioChecked]: selectedOddsDisplay === item.label,
                      })}
                    ></div>
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold">
                {getContent(translationKeys.SOUNDS)}
              </Typography>
            </div>
            <div className={classNames(styles.secondDiv)}>
              <Typography fontFamily="avenirNextMedium">{getContent(translationKeys.AUDIO)}</Typography>
              <label className={styles.toggleSwitch}>
                <input type="checkbox" checked={isChecked} onChange={handleToggle} className={styles.hiddenInput} />
                <div
                  className={classNames(styles.slider, {
                    [styles.checked]: isChecked,
                  })}
                />
              </label>
            </div>
          </div>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold" size="md">
                {getContent(translationKeys.HELP)}
              </Typography>
            </div>
            <div className={classNames(styles.secondDiv)} onClick={toggleModal}>
              <Typography fontFamily="avenirNextMedium">{getContent(translationKeys.GAME_RULE)}</Typography>
              <img src="icon/backArrow.png" alt="backArrow" className={classNames(styles.arrowIcon)} />
            </div>
          </div>

          <div
            className={classNames(styles.useCheat, {
              [styles.hide]: !isUserCheating, // show this only when BE sends useCheat is true .....
            })}
          >
            <div className={styles.col}>
              <input
                className={styles.forceBuildInput}
                type="number"
                value={forceBuildData.balance}
                onChange={(e) => {
                  setForceBuildData((prev) => {
                    return { ...prev, balance: e.target.value };
                  });
                }}
                placeholder="update Balance"
              />
              <Button
                size="small"
                color="cheatingBtn"
                roundedEdge={false}
                mediumHeight={true}
                onClick={updateUserBalance}
              >
                Update
              </Button>
            </div>
            <div className={styles.col}>
              <input
                className={styles.forceBuildInput}
                type="number"
                placeholder="Team 1 Score"
                value={forceBuildData.team1Score}
                onChange={(e) => {
                  let value = e.target.value;

                  if (value.length > 1) return;

                  if (isNaN(Number(value)) || Number(value) < 0) {
                    value = "0";
                  } else if (Number(value) > 6) {
                    value = "6";
                  }

                  setForceBuildData((prev) => ({
                    ...prev,
                    team1Score: value,
                  }));
                }}
              />
              <input
                className={styles.forceBuildInput}
                type="number"
                placeholder="Team 2 Score"
                onChange={(e) => {
                  let value = e.target.value;

                  if (value.length > 1) return;

                  if (isNaN(Number(value)) || Number(value) < 0) {
                    value = "0";
                  } else if (Number(value) > 6) {
                    value = "6";
                  }

                  setForceBuildData((prev) => ({
                    ...prev,
                    team2Score: value,
                  }));
                }}
                value={forceBuildData.team2Score}
              />
            </div>
            <div className={styles.col}>
              <Button
                color="cheatingBtn"
                size="stretch"
                roundedEdge={false}
                mediumHeight={true}
                onClick={() => udpateUserExtraData(false)}
              >
                Save
              </Button>
              <Button
                color="cheatingBtn"
                size="stretch"
                roundedEdge={false}
                mediumHeight={true}
                onClick={() => udpateUserExtraData(true)}
              >
                Clear
              </Button>{" "}
            </div>
          </div>
          <Button
            onClick={_handleResetGame}
            isShowClickTransition={true}
            displayFlex={true}
            size="stretch"
            mediumHeight={true}
            // hide={!isUserCheating}
          >
            <Typography addSpaceToRight={true} fontFamily="degularBold" size="xl" color="purpleBlack">
              {getContent(translationKeys.RESET_GAME_BTN)}
            </Typography>
            {/* <img src="icon/reset.png" alt="backArrow" className={styles.resetIcon} /> */}
          </Button>

          <div className={classNames(styles.flexCol)}>
            <img src="icon/exitButton.png" alt="exitButton" className={classNames(styles.exitButton)} />
            <Typography fontFamily="degularBold">{getContent(translationKeys.EXIT_GAME)}</Typography>
          </div>
        </div>
        <div className={styles.version}>
          <Typography fontFamily="degularMedium">{version}</Typography>
        </div>
      </section>
    </Drawer>
  );
};

export default React.memo(SettingsDrawer);
