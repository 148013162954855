import { ReactElement } from "react";

import { RoutesEnum } from "core/enums";
import Home from "pages/home/Home";
import Playcheck from "pages/playcheck/Playcheck";
interface RouteConfig {
  path: RoutesEnum;
  element: ReactElement;
}

export const routes: RouteConfig[] = [
  {
    path: RoutesEnum.Home,
    element: <Home />,
  },
  {
    path: RoutesEnum.Playcheck,
    element: <Playcheck />,
  },
];
