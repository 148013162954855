import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import styles from "styles/components/modals/minMaxStakeModal.module.css";
import { getContent } from "i18n";
import { setCurrencyFormat } from "util/miscUtils";
import sessionHelper from "service/sessionHelper";
import Typography from "components/Typography";
import { minMaxWarningMessagesEnums } from "core/enums";

const MinMaxStakeModal = () => {
  const { isOpenMinMaxStakeModal, updateModalStates, stakePannelWarningMessage } = useGameStore(
    (state) => ({
      isOpenMinMaxStakeModal: state.modalStates.isOpenMinMaxStakeModal,
      updateModalStates: state.updateModalStates,
      stakePannelWarningMessage: state.stakePannelWarningMessage,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenMinMaxStakeModal: false });
  };

  const minMaxValues = [
    {
      id: "minStake",
      label: "Min Stake",
      value: sessionHelper?.minBet,
    },
    {
      id: "maxStake",
      label: "Max Stake",
      value: sessionHelper?.maxBet,
    },
    {
      id: "maxPayout",
      label: "Max Payout",
      value: sessionHelper?.maxPayOut,
    },
  ];
  const getValueForWarnMsg = () => {
    if (stakePannelWarningMessage === minMaxWarningMessagesEnums.minStake) {
      return sessionHelper?.minBet;
    } else if (stakePannelWarningMessage === minMaxWarningMessagesEnums.maxStake) {
      return sessionHelper?.maxBet;
    } else if (stakePannelWarningMessage === minMaxWarningMessagesEnums.maxPayout) {
      return sessionHelper?.maxPayOut;
    }
  };

  return (
    <Modal id="minMaxStakeModal" open={isOpenMinMaxStakeModal} handleClose={handleModalClose} containerSize="lg">
      <div className={classNames(styles.body)}>
        <div className={classNames(styles.text)}>
          {/* define proper enums and work accordingly cause even you need to add currency format also  */}
          {/* {minMaxValues.map((item) => (
            <li key={item.id}>
              {getContent(item.label, [`${setCurrencyFormat({ value: item.value, showCurrency: true, decimal: 2 })}`])}
            </li>
          ))} */}
          <Typography size="md">
            {getContent(stakePannelWarningMessage, [
              `${setCurrencyFormat({ value: getValueForWarnMsg() as any, showCurrency: true, decimal: 2 })}`,
            ])}
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(MinMaxStakeModal);
