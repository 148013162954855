import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import getConfig from "util/configHelper";
import styles from "styles/components/modals/stakeInfoModal.module.css";
import gameJson from "config/gameJSON.json";
import sessionHelper from "service/sessionHelper";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";
import { setCurrencyFormat } from "util/miscUtils";

const StakeInfoModal = () => {
  const { configData } = getConfig();
  const { isOpenStakeInfoModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenStakeInfoModal: state.modalStates.isOpenStakeInfoModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenStakeInfoModal: false });
  };

  return (
    <Modal
      id="stakeInfoModal"
      open={isOpenStakeInfoModal}
      handleClose={handleModalClose}
      containerSize="lg"
    >
      <section className={classNames(styles.root)}>
        <div className={classNames(styles.row)}>
          <img className={classNames(styles.icon)} src="icon/infoGreen.png" alt="infoGreen" />
        </div>{" "}
        <div className={classNames(styles.row)}>
          <Typography position="center" fontFamily="avenirNextBold" size="xl">
            {getContent(translationKeys.XP_BOOST)}
          </Typography>
        </div>
        <div className={classNames(styles.row)}>
          <div className="flex flex-row">
            {/* <Typography size="md" fontFamily="avenirNextMedium" addSpaceToRight={true}>
              {getContent(translationKeys.FOR_EVERY)}
            </Typography>
            <Typography size="md" fontFamily="avenirNextMedium" addSpaceToRight={true}>
              <span className={classNames(styles.fontBlack)}>
                {" "}
                {setCurrencyFormat({ currency: sessionHelper?.currency, getCurrencySymbol: true })}1
              </span>
            </Typography>
            <Typography size="md" fontFamily="avenirNextMedium" addSpaceToRight={true}>
              {getContent(translationKeys.STAKED)}
            </Typography>
            <Typography size="md" fontFamily="avenirNextMedium" addSpaceToRight={true}>
              <span className={classNames(styles.fontBlack)}>5{getContent(translationKeys.XP, ["5"])}</span>.
            </Typography> */}
            <Typography size="md" fontFamily="avenirNextMedium">
              {getContent(translationKeys.xp_AWARDED, [
                `${setCurrencyFormat({ currency: sessionHelper?.currency, getCurrencySymbol: true })}1`,
                `${sessionHelper?.oddsDetails?.xpToAward}`,
              ])}
            </Typography>
          </div>
          <Typography size="md" fontFamily="avenirNextMedium">
            {getContent(translationKeys.XP_BOOSTED_INFO)}
          </Typography>
          {gameJson?.xpOddsInfoList.map((item, index) => (
            <div className={classNames(styles.list)} key={index}>
              <Typography fontFamily="avenirNextMedium"> • </Typography>
              <Typography size="md" fontFamily="avenirNextMedium" noWrap={true} addSpaceToRight={true}>
                {/* {getContent(item.item)} */}
                {getContent(item.item , [item.fraction])}
              </Typography>
              {/* <Typography fontFamily="avenirNextMedium" addSpaceToRight={true}>
                {item.fraction}
              </Typography> */}
              <Typography fontFamily="avenirNextMedium" addSpaceToRight={true}>
                <span className={classNames(styles.fontBlack)}>{item.percent}</span>
              </Typography>
              <Typography size="md" fontFamily="avenirNextMedium">
                {getContent(item.boost)}.
              </Typography>
            </div>
          ))}
        </div>
      </section>
    </Modal>
  );
};
export default React.memo(StakeInfoModal);
