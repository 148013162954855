import React, { ButtonHTMLAttributes, PropsWithChildren, useState } from "react";
import classNames from "classnames";
import styles from "styles/components/numbersKeypad.module.css";
import getConfig from "util/configHelper";
import Typography from "components/Typography";
import Button from "./Button";
import sessionHelper from "service/sessionHelper";
import gameJson from "config/gameJSON.json";
import { setCurrencyFormat } from "util/miscUtils";
import { useGetQuickBetsQuery } from "generated/graphql";

type Props = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    onClickOption?: (option: string | number) => void; // For default options
    onClickKey?: (key: string | number) => void; // For number keys
  }
>;

const NumbersKeypad = ({ onClickOption, onClickKey }: Props) => {
  const [quickBets, setQuickBets] = useState([]);
  const { data, loading, error } = useGetQuickBetsQuery({
    variables: {},
    onCompleted: (data) => {
      setQuickBets(data?.players?.getQuickBets?.bets as any);
    },
    onError: (error) => {
      setQuickBets(gameJson.keyPadDefaultOptions as any);
    },
  });
  let lengthOfCurrenySym = setCurrencyFormat({ currency: sessionHelper?.currency, getCurrencySymbol: true }).length;

  return (
    <section className={classNames(styles.keypadRoot)}>
      <div className={classNames(styles.defaultOptions)}>
        {quickBets.map((option, index) => (
          <Button
            isShowClickTransition={true}
            key={index}
            className={classNames(styles.option)}
            onClick={() => onClickOption && onClickOption(option)} // Handle option click
          >
            <Typography size={lengthOfCurrenySym > 1 ? "md" : "lg"} fontFamily="avenirNextBold" color="purpleBlue">
              {`${setCurrencyFormat({ value: option, getCurrencySymbol: true })}`}
            </Typography>
          </Button>
        ))}
      </div>
      <div className={classNames(styles.keyValues)}>
        {gameJson.keypadNumberOptions.map((key, index) => (
          <div
            key={index}
            className={classNames(styles.key)}
            onClick={() => {
              onClickKey && onClickKey(key.key);
            }}
          >
            {key.key === "icon" ? (
              <img src="images/bets/keyPadDelete.png" alt="keypad delete" />
            ) : (
              <Typography color={key.key !== "." ? "black" : ""} size="2xl" fontFamily="avenirNextBold">
                {key.value}
              </Typography>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default React.memo(NumbersKeypad);
