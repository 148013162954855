import classNames from "classnames";
import { shallow } from "zustand/shallow";
import Typography from "components/Typography";
import getConfig from "util/configHelper";
import styles from "styles/components/vault.module.css";
import React, { ButtonHTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import useGameStore from "store/gameStore";
import { timer } from "util/miscUtils";
import { matchStatusEnums } from "core/enums";
import CountUp from "react-countup";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";

type Props = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    hide?: boolean;
  }
>;

const Vault = ({ hide = false }: Props) => {
  const { configData } = getConfig();
  const [vaultAnimationDuration, setVaultAnimationDuration] = useState(0);

  const { betPlaceData, matchStatus } = useGameStore(
    (state) => ({
      betPlaceData: state.betPlaceData,
      matchStatus: state.matchStatus,
    }),
    shallow,
  );
  const calculatePercentage = (xpRequired: any, xpTo: any) => {
    if (xpTo && xpRequired) {
      return (xpTo / xpRequired) * 100;
    }
    return 0;
  };
  const getMatchStatus = useGameStore.getState; // get fresh match Status ...
  let lastCurrentXP = calculatePercentage(betPlaceData?.xpNeededForLevel, betPlaceData?.lastCurrentXP);

  const [progressWidth, setProgressWidth] = React.useState(`${lastCurrentXP}%`);
  const [rotatePbfPacket, setRotatePbfPacket] = React.useState(false);
  const [vaultValueCounterHandler, setVaultValueCounterHandler] = React.useState({
    vaultCurrentXp: 0,
    vaultTotalXp: 0,
    vaultLevel: 0,
    xpFrom: betPlaceData?.progressUpdates[0]?.xpFrom,
  });

  const [spinsCount, setSpinsCount] = useState(betPlaceData?.progressUpdates[0]?.spin as number);
  const [progressTransition, setProgressTransition] = useState("none");
  const handleUpdateVaultAnimation = async () => {
    let vaultProgressUpdates = betPlaceData?.progressUpdates;
    let totalXpIncreased = (betPlaceData?.totalXp as number) - (betPlaceData?.lastCurrentXP as number); // get totalXp increase..

    let getTotalTimeForAnimationInSeconds =
      totalXpIncreased <= 250
        ? 1.5
        : totalXpIncreased <= 750
          ? 3.5
          : totalXpIncreased <= 1500
            ? 4.5
            : totalXpIncreased <= 3000
              ? 7.5
              : 7.5;
    let getTimeForSingleIteration =
      getTotalTimeForAnimationInSeconds / (betPlaceData?.progressUpdates?.length as number);
    setVaultAnimationDuration(getTimeForSingleIteration);
    if (vaultProgressUpdates) {
      for (let i = 0; i < vaultProgressUpdates.length; i++) {
        if (getMatchStatus().matchStatus === matchStatusEnums.selectingOdds) {
          setProgressWidth(`0%`);
          setVaultAnimationDuration(0);
          setProgressTransition("none");
          return;
        } // Stop loop if unmounted

        const currentUpdate = vaultProgressUpdates[i];
        if (!currentUpdate) continue; // Skip if currentUpdate is undefined

        setRotatePbfPacket(true);
        // using math random is for starting from zero countUp function always cache and if same start Point is given it will not animate (counting )
        setVaultValueCounterHandler({
          xpFrom: currentUpdate?.level >= 50 ? Math.random() : currentUpdate.xpFrom || 0, // Default to 0 if undefined
          vaultCurrentXp: currentUpdate.xpTo || 0,
          vaultTotalXp: currentUpdate.xpRequired || 0,
          vaultLevel: currentUpdate.level || 0,
        });

        if (i < vaultProgressUpdates.length - 1) {
          // if not last index
          setProgressTransition(`all ${getTimeForSingleIteration}s linear`);
          setProgressWidth("100%");
          setSpinsCount(currentUpdate.spin as number);
          await timer(getTimeForSingleIteration * 1000); // this is for wating till the first iteration animation is not completed
          setProgressTransition("none"); // no transition needed when progress bar needs to start from zero
          setProgressWidth("0%");
          await timer(50); // have a pause before resetting everything .....
          if (getMatchStatus().matchStatus === matchStatusEnums.selectingOdds) {
            setProgressWidth(`0%`);
            setVaultAnimationDuration(0);
            setProgressTransition("none");
            return;
          } // Stop loop if unmounted
        } else {
          // if last index ....
          if (getMatchStatus().matchStatus === matchStatusEnums.selectingOdds) {
            setProgressWidth(`0%`);
            setVaultAnimationDuration(0);
            setProgressTransition("none");
            return;
          } // Stop loop if unmounted
          setProgressTransition(`all ${getTimeForSingleIteration}s linear`);
          setSpinsCount(currentUpdate.spin as number);
          const finalPercentage = calculatePercentage(currentUpdate.xpRequired, currentUpdate.xpTo);
          setProgressWidth(`${finalPercentage}%`);
          await timer(getTimeForSingleIteration * 1000);
          setProgressTransition("none");
          setRotatePbfPacket(false);
        }
      }
    }
  };

  useEffect(() => {
    handleUpdateVaultAnimation();
  }, [matchStatus]);

  const linearEasing = (t: any, b: any, c: any, d: any) => {
    return (c * t) / d + b;
  };
  
  return (
    <section
      className={classNames(styles.vaultContainer, {
        [styles.hide]: hide,
      })}
    >
      <div
        className={classNames(styles.badgeLevel, {
          [styles.addRedColor]: spinsCount,
          [styles.hide]: spinsCount <= 0,
        })}
      >
        <Typography color="white" fontFamily="degularBold" size="md3">
          {spinsCount || " "}
        </Typography>
      </div>
      <div className={styles.colRowContainer}>
        <div className={styles.row}>
          <Typography fontFamily="degularBlack" size="xl1">
            {getContent(translationKeys.THE_VAULT)}
          </Typography>
          <Typography fontFamily="avenirNextSemiBold" size="md2">
            {configData.xpText + " "}
            <CountUp
              start={vaultValueCounterHandler?.xpFrom as number}
              end={vaultValueCounterHandler?.vaultCurrentXp as number}
              duration={vaultAnimationDuration || 1}
              easingFn={linearEasing}
            />
          </Typography>
          <Typography fontFamily="avenirNextSemiBold" color={"white"} size="md2">
            /{vaultValueCounterHandler?.vaultTotalXp}
          </Typography>
        </div>
        <div className={styles.row}>
          <div className={styles.outerProgressBar}>
            <div
              className={classNames(styles.valueBar, {
                [styles.innerProgressBar]: progressWidth !== "0%",
              })}
              style={{
                width: progressWidth,
                transition: progressTransition,
              }}
            ></div>
            <div className={styles.pointer}></div>
          </div>
          <img
            src={"images/bets/vault.png"}
            alt="pbPacket"
            className={classNames(styles.pbPacket, {
              [styles.rotatePbfPacket]: rotatePbfPacket,
            })}
          />
        </div>
      </div>
      {/* <button onClick={() => handleUpdateVaultAnimation()}>
        <Typography>run animation</Typography>
      </button> */}
    </section>
  );
};

export default React.memo(Vault);
