import React, { PropsWithChildren, HTMLAttributes, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "styles/components/appshell.module.css";
import DrawerRenderer from "components/drawers/DrawerRenderer";
import ModalRenderer from "components/modals/ModalRenderer";
import appEventInstance from "util/eventHandler";
import { AppEventTypes } from "core/enums";
import Typography from "components/Typography";
import useDisableZoom from "hooks/useDisableZoom";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";

type AppShellProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const AppShell: React.FC<AppShellProps> = ({ children, ...props }) => {
  const location = useLocation();
  const childrenRef = useRef<HTMLDivElement>(null);
  useDisableZoom(); // Disable zoom for all devices

  const { isInitDataAvailable } = useGameStore(
    (state) => ({
      isInitDataAvailable: state.isInitDataAvailable,
    }),
    shallow,
  );

  useEffect(() => {
    const setViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.documentElement.style.setProperty("--vh", `${viewportHeight * 0.01}px`);
    };

    // Initial setting of the --vh custom property
    setViewportHeight();

    // Recalculate the --vh value on window resize
    window.addEventListener("resize", setViewportHeight);

    const handleDrawerScrollToggle = (event: CustomEvent) => {
      const { detail } = event;
      const shouldDisableScroll = detail?.isScrollDisabled ?? false;

      if (childrenRef.current) {
        childrenRef.current.style.overflowY = shouldDisableScroll ? "hidden" : "auto";
      }
    };

    appEventInstance.listen(AppEventTypes.ToggleDrawerScroll, handleDrawerScrollToggle);

    return () => {
      appEventInstance.remove(AppEventTypes.ToggleDrawerScroll, handleDrawerScrollToggle);
      window.removeEventListener("resize", setViewportHeight);
    };
  }, []);

  return (
    <div className={classNames(styles.mainContainer)} {...props}>
      <div className={classNames(styles.childContainer)}>
        {/* Hide message for /playcheck route */}
        {location.pathname !== "/playcheck" && (
          <div className={classNames(styles.notify)}>
            <Typography>{getContent(translationKeys.RESOLUTION_MSG)}</Typography>
          </div>
        )}

        <div className={classNames(styles.children)} ref={childrenRef}>
          {children}
        </div>

        {isInitDataAvailable && (
          <>
            <DrawerRenderer />
            <ModalRenderer />
          </>
        )}
      </div>
    </div>
  );
};

export default AppShell;
