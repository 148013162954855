import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/correctScore.module.css";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import sessionHelper from "service/sessionHelper";
import { debug, getTeamName } from "util/miscUtils";
import { OddsDisplayEnum, WinEnum } from "generated/graphql";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { matchStatusEnums } from "core/enums";
import getConfig from "util/configHelper";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";

const CorrectScore = () => {
  const { configData } = getConfig();
  const [openContent, setOpenContent] = useState(true);
  const [loadingIds, setLoadingIds] = useState(new Set()); // Tracks loading state by item ID
  const [showMore, setShowMore] = useState(false);
  debug({ event: "calling", message: "correct Score  ..." });
  let keyName = sessionHelper.oddsDetails?.correctScore?.name as string;

  const selectedCorrectScoreOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions, selectedOddsDisplay, matchStatus, setActiveOddForEnteringStake } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      selectedOddsDisplay: state.selectedOddsDisplay,
      matchStatus: state.matchStatus,
      setActiveOddForEnteringStake: state.setActiveOddForEnteringStake,
    }),
    shallow,
  );

  let correctScoreOdds = sessionHelper.oddsDetails?.correctScore;
  const maxVisibleItems = 3;
  const { handleBoxClick, isAddingBetLoading, isRemovingBetLoading, error } = useHandleAddUpdateOdds();

  const handleUserInteraction = async (id: string, keyName: string) => {
    if (matchStatus === matchStatusEnums.betPlaced) return; // If the match is already placed, do nothing
    try {
      setLoadingIds((prev) => new Set(prev).add(id)); // Mark the specific box as loading

      let resp = await handleBoxClick(id, keyName, [...selectedCorrectScoreOptions]);
      setSelectedOddsOptions(resp || [], keyName);

      let getUpdatedOddsOptions = useGameStore.getState();
      const result = Object.entries(getUpdatedOddsOptions.selectedOddsOptions).find(([_, value]) => value.length);
      if (result) {
        //  the result will be like ["key", [array of values]];
        let activeInputBoxDetails = result[1][0];
        setActiveOddForEnteringStake({
          oddId: activeInputBoxDetails?.oddId,
          key: activeInputBoxDetails?.marketName,
          betId: activeInputBoxDetails?.betId,
        });
      } else {
        setActiveOddForEnteringStake({ oddId: "", key: "", betId: "" });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingIds((prev) => {
        const updated = new Set(prev);
        updated.delete(id); // Remove loading state for the box
        return updated;
      });
    }
  };

  return (
    <section className={styles.root}>
      <Accordion
        open={openContent}
        boxHeading={getContent(translationKeys.CORRECT_SCORE) || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        showAll={showMore}
        type="settings"
      >
        <div className={styles.boxRowContainer}>
          {["A", "draw", "B"].map((winType) => {
            const itemOdd = correctScoreOdds?.data.find((item) => item.win === winType);
            if (!itemOdd) return null; // Skip if no matching data

            const visibleValues = showMore ? itemOdd.values : itemOdd.values.slice(0, maxVisibleItems);

            return (
              <div key={itemOdd.win} className={styles.boxCol}>
                <div className={classNames(styles.col)}>
                  <Typography
                    color="grayPlus"
                    size={getTeamName(itemOdd.win).length > 9 ? "md2" : "md"}
                    fontFamily="avenirFont"
                    noWrap={true}
                    isEllipsis={true}
                    width="100%"
                  >
                    {getTeamName(itemOdd.win)}
                  </Typography>
                </div>
                {visibleValues.map((oddValues, oddIndex) => (
                  <div key={`${oddIndex}-${oddValues.odd}`} className={styles.col}>
                    <BoxSelection
                      active={
                        selectedCorrectScoreOptions.length &&
                        selectedCorrectScoreOptions?.some((ele) => ele.oddId === oddValues.id)
                          ? true
                          : false
                      } // implement active state
                      onClick={() => handleUserInteraction(oddValues.id, keyName)}
                      title={`${oddValues.team1}-${oddValues.team2}`}
                      open={openContent}
                      isClicked={loadingIds.has(oddValues.id)} // Check if this box is in a loading state
                      value={
                        selectedOddsDisplay
                          ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                            ? oddValues.fractionalOdd.toString()
                            : selectedOddsDisplay === OddsDisplayEnum.Decimal
                              ? oddValues.decimalOdd.toString()
                              : oddValues.americanOdd.toString()
                          : ""
                      }
                      disableClick={isAddingBetLoading || isRemovingBetLoading}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        {correctScoreOdds?.data.some((item) => item.values.length > maxVisibleItems) && (
          <div
            onClick={() => {
              setShowMore((prev) => !prev);
            }}
            className={styles.showMoreContainer}
          >
            <Typography size="lg" className={styles.showMoreButton}>
              {showMore ? getContent(translationKeys.SHOW_LESS) : getContent(translationKeys.SHOW_MORE)}
            </Typography>
            <img
              className={classNames(styles.arrowIcon, {
                [styles.rotateIcon]: showMore,
              })}
              src={"icon/arrowDown.png"}
              alt=""
            />
          </div>
        )}
      </Accordion>
    </section>
  );
};

export default React.memo(CorrectScore);
