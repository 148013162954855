import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import sessionHelper from "service/sessionHelper";

// Step 1: Set up the HttpLink
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL + "/graphql",
  credentials: "include",
});

// Step 2: Set up the authLink
const authLink = setContext((_, { headers }) => {
  const token = sessionHelper?.beToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Step 3: Set up the error handling link
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    const context = operation.getContext();
    const requestId = context.response?.headers?.get("x-request-id") ?? context.response?.headers?.get("X-Request-Id");

    graphQLErrors.forEach((error) => {
      // Modify the error to include request ID and any other context
      if (error.extensions) {
        error.extensions.requestId = requestId;
        error.extensions.operationName = operation.operationName;
      }

      console.error(`[GraphQL error]: ${error.message}`, {
        requestId,
        path: error.path,
        code: error.extensions?.code,
      });
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

// Step 4: Create the ApolloClient instance with combined links
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]), // Order matters: error -> auth -> http
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default client;
