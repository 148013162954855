import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";
import styles from "styles/components/loader.module.css";

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    type?: string;
    hide?: string;
    isAddMinHeight?: boolean;
  }
>;
const Loader = ({ type = "loader", hide = false, isAddMinHeight = false }) => {
  return (
    <div
      className={classNames(styles["loader-container"], {
        [styles.removeHeight]: type !== "loader",
        [styles.isAddMinHeight]: isAddMinHeight,
        [styles.hide]: hide,
      })}
    >
      <img
        className={classNames(styles.img, {
          [styles.btnLoader]: type !== "loader",
        })}
        src={`icon/${type === "whiteLoader" ? "whiteSpinningLoader" : type === "grayLoader" ? "graySpinningLoader" : "loader"}.gif`}
        alt="loader"
      />
    </div>
  );
};

export default Loader;
