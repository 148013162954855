import React from "react";
import ContentShell from "components/ContentShell";
import { useNavigate, useLocation } from "react-router-dom";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/components/drawers/stakePannelOptionsDrawer.module.css";
import StatsCard from "pages/teamSelect/components/StatsCard";
import appEventInstance from "util/eventHandler";
import { AppEventTypes, CanvasEvents, CanvasMessage, loaderTypes, matchStatusEnums } from "core/enums";
import getConfig from "util/configHelper";
import { useCurrentBetSlipQuery } from "generated/graphql";
import { DrawerPositions, DrawerBgType } from "core/enums";
import { TeamData } from "core/interfaces/common";
import BettingsOdds from "pages/stakePannelOptions/components/odds/BettingsOdds";
import sessionHelper from "service/sessionHelper";
import { debug } from "util/miscUtils";
import StakePannelInput from "pages/stakePannelOptions/components/StakePannelInput";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import Button from "components/Button";
import Drawer from "components/Drawer";
import { getContent } from "i18n";
import firebaseAnalytics from "service/firebaseAnalytics";
import { AnalyticsEvents } from "analyticTypes/analyticsEventTypes";

const StakePannelOptionsDrawer = () => {
  const { configData } = getConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const { setSelectedOddsOptions, setMatchStatus, isShowTeamList, setIsShowTeamList } = useGameStore(
    (state) => ({
      isShowTeamList: state.isShowTeamList,
      setIsShowTeamList: state.setIsShowTeamList,
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      setMatchStatus: state.setMatchStatus,
    }),
    shallow,
  );
  const { isOpenStakePannelOptions, updateDrawerStates, showLoader } = useGameStore(
    (state) => ({
      isOpenStakePannelOptions: state.drawerStates.isOpenStakePannelOptions,
      updateDrawerStates: state.updateDrawerStates,
      showLoader: state.showLoader,
    }),
    shallow,
  );
  let teamVersus = [
    { ...sessionHelper.oddsDetails?.teamA, name: getContent("myTeam") },
    {
      name: "versus",
      icon: "headvs",
      badge: "",
      badgeUrl: "",
    },
    { ...sessionHelper.oddsDetails?.teamB },
  ];
  debug({ event: "calling", message: "Stake Pannel Options  ..." });

  const {
    data: currentStakeSlipsData,
    loading: isCurrentStakeSlipsLoading,
    error: isCurrentStakeSlipError,
  } = useCurrentBetSlipQuery({
    skip: !isOpenStakePannelOptions,
    onCompleted: (data) => {
      if (data) {
        const currStakeSlipRes = data.players.currentBetSlip;
        setSelectedOddsOptions(currStakeSlipRes as any, "reset");
      }
    },
  });

  const _transferInfoToPixi = async (data: { type: string; value: any }) => {
    const iframeRef = useGameStore.getState().iframeRef;
    //@ts-ignore
    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow
    ) {
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension(data);
    }
  };

  const handleStartMatch = async () => {
    if (sessionHelper?.isFTU) {
      firebaseAnalytics.trackEvent(AnalyticsEvents.FTUE, {
        FTUE_bet_placed: 0, // send this only if FTU is true ....
        UserID: sessionHelper?._id,
      });
    }
    updateDrawerStates({ isOpenStakePannelOptions: false });
    await _transferInfoToPixi?.({
      type: CanvasMessage.startSimulation,
      value: {},
    });
    setSelectedOddsOptions([], "removeAllData");
    setMatchStatus(matchStatusEnums.selectingOdds);
    sessionHelper.updateOddDetails([] as any);
  };

  const handleTeamSectionClick = async () => {
    setIsShowTeamList(true);
    await _transferInfoToPixi?.({
      type: CanvasMessage.showTeamList,
      value: {},
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      // for setting up footer functinality
      appEventInstance.dispatch({
        eventType: AppEventTypes.toogleFooter,
        payload: {
          label: configData.back,
          event: CanvasEvents.showBack,
          callback: () => {
            navigate(`/team${location.search}`);
          },
          forCanvas: false,
        },
      });
    }, 0);
  }, []);

  const handleClose = () => {
    updateDrawerStates({ isOpenStakePannelOptions: false });
  };

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper?.oddsDetails?.teamA?.name || "-";
    if (name === "B") return sessionHelper?.oddsDetails?.teamB?.name || "-";
    return "Draw";
  };

  return (
    <Drawer
      isOpen={isOpenStakePannelOptions}
      position={DrawerPositions.Top}
      id="stakePannelOptionsDrawer"
      onClose={handleClose}
      drawerBgType={DrawerBgType.BetPannelOptions}
      showFooterButton={false}
      hideVisibility={isShowTeamList}
    >
      <ContentShell loading={showLoader.isTrue && showLoader.loaderFor === loaderTypes.oddsList}>
        <div className={classNames(styles.drawerRoot)}>
          <header className={styles.betPannelHeader}>
            <div className={styles.teamVersus} onClick={handleTeamSectionClick}>
              <div className={styles.info}>
                <Typography fontFamily="avenirNextSemiBold" size="xs">
                  i
                </Typography>
              </div>
              {teamVersus?.map((data, index) => {
                return (
                  <React.Fragment key={`${data.name}-${index}`}>
                    {data.name === "versus" ? (
                      <div className={styles.versusContainer}>
                        <img className={styles.vs} src={`icon/home/headvs.png`} alt="teamImage" />
                      </div>
                    ) : (
                      <div
                        className={classNames(styles.team, {
                          [styles.rowReverse]: index === teamVersus.length - 1,
                        })}
                      >
                        <Typography
                          size={
                            data.name && data?.name?.length > 9
                              ? "md2"
                              : data.name && data?.name?.length > 12
                                ? "md3"
                                : "md"
                          }
                          fontFamily="degularBold"
                          isEllipsis={true}
                          width="80%"
                          fontWeight="font800"
                        >
                          {data?.name || "-"}
                        </Typography>
                        <img src={data?.badgeUrl || ``} alt="badge1" className={styles.teamIcon} />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div
              className={classNames(styles.teamsCardContainer, {
                [styles.stickToTop]: !sessionHelper?.isFTU,
              })}
              onClick={handleTeamSectionClick}
            >
              {/* below the indexes are hardCo ded cause any how we know the indexs  */}
              <div>
                <StatsCard
                  data={sessionHelper?.oddsDetails?.teamA as TeamData}
                  roundedCorner={true}
                  team={"myTeam"}
                  tableTitle={"My Team Fc"}
                />
              </div>
              <div>
                <StatsCard
                  data={sessionHelper?.oddsDetails?.teamB as TeamData}
                  roundedCorner={true}
                  team={"opponent"}
                  tableTitle={"Fc ToLoose"}
                />
              </div>
            </div>
            <section className={styles.betPannelContainer}>
              {/* <Vault /> */}
              {showLoader.loaderFor === loaderTypes.oddsList && <BettingsOdds />}
              <div className={styles.startMatchBtn}>
                <Button
                  addDropShadow={true}
                  onClick={() => handleStartMatch()}
                  size="stretch"
                  color={"darkGreenBtn"}
                  addMinHeight={true}
                  displayFlex={true}
                >
                  <Typography color={"purpleBlue"} fontFamily="degularBold" size={"2xl"}>
                    {getContent("startMatch")}
                  </Typography>
                </Button>
              </div>
            </section>
          </header>
        </div>

        <div className={styles.stakePannelInputContainer}>
          <StakePannelInput _transferInfoToPixi={_transferInfoToPixi} />
        </div>
      </ContentShell>
    </Drawer>
  );
};

export default React.memo(StakePannelOptionsDrawer);
