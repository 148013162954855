import { AnalyticsEvents } from "analyticTypes/analyticsEventTypes";
import { useAddUpdateBetMutation, useRemoveBetFromSlipMutation } from "generated/graphql";
import firebaseAnalytics from "service/firebaseAnalytics";
import sessionHelper from "service/sessionHelper";
import getConfig from "util/configHelper";

const useHandleAddUpdateOdds = () => {
  const [addUpdateBet, { loading: isAddingBetLoading, error }] = useAddUpdateBetMutation();
  const [removeBetFromSlipMutation, { loading: isRemovingBetLoading, error: removeStakeError }] =
    useRemoveBetFromSlipMutation();
  const { configData } = getConfig();

  const handleBoxClick = async (id: string, key: string, oddsForKey?: any[], betId?: string, betAmount?: number) => {
    if (isRemovingBetLoading || isAddingBetLoading) return; // any onGoing Api call found for removing or adding bet Id then return avoid multi clicking

    try {
      const existingOdd = oddsForKey?.length ? oddsForKey.find((ele) => ele.oddId === id) : null;
      const isIdAlreadyAdded = !!existingOdd;

      let updatedOdds;

      if (key === configData.updateStakeSlip) {
        // rather than calling update api here call the update api in the key click itself and apply debouncing there only ...
        if (betAmount && betAmount < Number(sessionHelper?.minBet)) return;
        const updateStakeSlip = await addUpdateBet({
          variables: {
            input: { oddId: id, betId: betId, betAmount: betAmount },
          },
        });
        return updateStakeSlip as any;
      }

      if (isIdAlreadyAdded) {
        const { betId } = existingOdd;
        if (betId) {
          await removeBetFromSlipMutation({ variables: { betId } });
          updatedOdds = oddsForKey?.filter((ele) => ele.oddId !== id) || [];
        } else {
          console.warn("No betId found for removal:", existingOdd);
        }
      } else {
        const addStakeSlipResp = await addUpdateBet({
          variables: { input: { oddId: id } },
        });

        if (addStakeSlipResp?.data?.players?.addUpdateBet) {
          const beResponse = addStakeSlipResp.data.players.addUpdateBet;
          updatedOdds = oddsForKey?.length ? [...oddsForKey, { ...beResponse }] : [{ ...beResponse }];

          firebaseAnalytics.trackEvent(AnalyticsEvents.Bet, {
            bet_selected: 1,
            bet_selected_market: beResponse.marketType,
            BetID: beResponse.betId,
          });
        } else {
          console.error("Failed to add the odd");
          return;
        }
      }

      return updatedOdds || [];
    } catch (err) {
      console.error("Mutation failed:", err);
      throw err;
    }
  };

  return {
    handleBoxClick,
    isAddingBetLoading,
    isRemovingBetLoading,
    removeStakeError,
    error,
  };
};

export default useHandleAddUpdateOdds;
