import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";

import styles from "styles/components/button.module.css";

type Props = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: string;
    border?: string;
    displayFlex?: boolean;
    addDropShadow?: boolean;
    addExtraPadding?: boolean;
    customStakeBtn?: boolean;
    disabled?: boolean;
    hide?: boolean;
    flexCol?: boolean;
    addMinHeight?: boolean;
    mediumHeight?: boolean;
    isAddMoreBtnDisabled?: boolean;
    roundedEdge?: boolean;
    isShowClickTransition?: boolean;
    size?: string;
    color?: string;
    isDisableOnClick?: boolean;
  }
>;

const Button = ({
  displayFlex = false,
  isDisableOnClick = false,
  flexCol = false,
  variant = "default",
  color = "greenBtn",
  customStakeBtn = false,
  hide = false,
  isAddMoreBtnDisabled = false,
  addDropShadow = false,
  border = "",
  size = "medium",
  addExtraPadding = false,
  disabled = false,
  mediumHeight = false,
  roundedEdge = false,
  addMinHeight = false,
  isShowClickTransition = false,
  children,
  ...props
}: Props) => {
  return (
    <button
      disabled={disabled}
      className={classNames(styles.root, {
        [styles.displayFlex]: displayFlex,
        [styles.flexCol]: flexCol,
        [styles.customStakeBtn]: customStakeBtn,
        [styles.navyBlue]: color === "navyBlue",
        [styles.addDropShadow]: addDropShadow,
        [styles.cheatingBtn]: color === "cheatingBtn", // remove the condition later
        [styles.greenBtn]: color === "greenBtn",
        [styles.darkGreenBtn]: color === "darkGreenBtn",
        [styles.redBtn]: color === "redBtn",
        [styles.blueBtn]: color === "blueBtn",
        [styles.storeBtnGreen]: color === "storeBtnGreen",
        [styles.storeBtnDarkBlue]: color === "storeBtnDarkBlue",
        [styles.small]: size === "small",
        [styles.blackBtn]: color === "blackBtn",
        [styles.purpleBlueExtraLightBtn]: color === "purpleBlue100",
        [styles.transparentBtn]: color === "transparent",
        [styles.medium]: size === "medium",
        [styles.large]: size === "large",
        [styles.isDisableOnClick]: isDisableOnClick,
        [styles.whiteBorder]: border === "whiteBorder",
        [styles.stretch]: size === "stretch",
        [styles.hide]: hide,
        [styles.addMinHeight]: addMinHeight,
        [styles.mediumHeight]: mediumHeight,
        [styles.addExtraPadding]: addExtraPadding,
        [styles.noRoundedEdge]: roundedEdge,
        [styles.disabled]: disabled,
        [styles.isAddMoreBtnDisabled]: isAddMoreBtnDisabled,
        [styles.isShowClickTransition]: isShowClickTransition,
      })}
      {...props}
    >
      {children}
    </button>
  );
};
export default Button;
