import classNames from "classnames";
import Loader from "components/Loader";
import Typography from "components/Typography";
import { MARKET_ENUMS } from "core/enums";
import { useMatchDetailsByPlatformTxnIdQuery } from "generated/graphql";
import { translationKeys } from "i18n/translationKeys.enums";
import i18next from "i18next";
import i18nextChainedBackend from "i18next-chained-backend";
import i18nextHttpBackend from "i18next-http-backend";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import getConfig from "util/configHelper";
import { setCurrencyFormat } from "util/miscUtils";
import "./playcheck.css";
import useGameStore from "store/gameStore";

const getContentV2 = (str: string, arr: (string | number)[] = [], toUpperCase = false): string => {
  const replacements = arr.reduce(
    (acc, value, index) => {
      acc[`text${index + 1}`] = value;
      return acc;
    },
    {} as Record<string, string | number>,
  );

  const text = i18next.t(str, replacements);
  return toUpperCase && text ? text.toUpperCase() : (text ?? str);
};

const Playcheck = () => {
  const { configData, errConfigData } = getConfig();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("LANGUAGE") || searchParams.get("language") || "en";
  const ticketId = searchParams.get("TICKETID") || searchParams.get("ticketid") || "";

  useEffect(() => {
    i18next.use(i18nextChainedBackend).init({
      backend: {
        backends: [i18nextHttpBackend],
        backendOptions: [
          {
            loadPath: `${process.env.REACT_APP_API_URL}/translations/{{lng}}`,
          },
        ],
      },
      lng: lang,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
    });
  }, [lang]);

  useEffect(() => {
    if (window.self !== window.top) {
      // App is inside an iframe, load the script
      const script = document.createElement("script");
      script.src = "https://info.valueactive.eu/Playcheck/scripts/eti-resize.js";
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const { data, error, loading } = useMatchDetailsByPlatformTxnIdQuery({
    variables: { platformTxnId: ticketId },
    skip: !ticketId,
    fetchPolicy: "no-cache",
  });

  if (!ticketId) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <p className="text-red-500 text-lg font-semibold">Ticket ID is missing.</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <p className="text-red-500 text-lg font-semibold">Error fetching data.</p>
      </div>
    );
  }

  const match = data?.players?.matchDetailsByPlatformTxnId;

  if (!match) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <p className="text-red-500 text-lg font-semibold">No match details found.</p>
      </div>
    );
  }

  const getTeamName = (name: string) => {
    if (name === "A") return match.myTeam.name || "-";
    if (name === "B") return match.opponentTeam.name || "-";
    return "Draw";
  };

  const getPlayerName = (playerId: number | string, allPlayersList: any) => {
    if (!playerId) return getContentV2(translationKeys.NO_GOAL_SCORER);
    let playerDetails = allPlayersList?.filter((ele: any, index: any) => ele.playerId === playerId);
    if (playerDetails?.length) {
      return playerDetails[0].name;
    } else {
      return errConfigData.GEN06;
    }
  };

  return (
    <div className="wrapper">
      <div className="firstDiv">
        <div className="rows">
          <img id="myTeamBadgeImg" src={match?.myTeam?.badgeUrl || ""} alt="myTeamBadge" width="20%" />
          <div className="rows" style={{ gap: "5px" }}>
            <Typography size="3xl">{match?.myTeamScore}</Typography>
            <Typography size="3xl">:</Typography>
            <Typography size="3xl">{match.oppTeamScore}</Typography>
          </div>
          <img
            id="opponentTeamBadgeImg"
            src={match?.opponentTeam?.badgeUrl || ""}
            alt="opponentTeamBadge"
            width="20%"
          />
        </div>
        <div
          className="rows"
          style={{
            width: "85%",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <Typography size="xl">{match?.myTeam?.name}</Typography>
          <Typography size="xl">{match?.opponentTeam?.name}</Typography>
        </div>
        <div
          className="rows"
          style={{
            width: "85%",
            justifyContent: "space-between",
            marginTop: "10px",
            alignItems: "flex-start",
          }}
        >
          <div id="goalScorers" className="cols">
            {match?.myTeamGoalScorers?.map((scorer, index) => (
              <Typography key={index} size="md2">{`${scorer.name} ${scorer.time}'`}</Typography>
            ))}
          </div>

          <div id="oppTeamGoalScorers" className="cols">
            {match?.oppTeamGoalScorers?.map((scorer, index) => (
              <Typography key={index} size="md2">{`${scorer.name} ${scorer.time}'`}</Typography>
            ))}
          </div>
        </div>
      </div>
      <div className="myBets" id="betsContainer">
        <div className="header">
          <Typography data-i18n="MyBets" size="xl">
            {getContentV2("MyBets")}
          </Typography>
        </div>
        {match?.bets?.map((betItems, betItemsIndex) => {
          return (
            <div key={`${betItemsIndex}-${betItems.betId}`} className={"wrap"}>
              <div
                className={classNames("cols", {
                  "!w-[45%]": betItems.marketName.length > 10 || betItems.marketName === configData.handicaps,
                })}
              >
                <div className="marketDetails">
                  <img
                    className="isResolvedImg"
                    src={`/images/bets/${betItems.isResolved ? "active" : "inActive"}.png`}
                    alt="active btn"
                  />
                  <div>
                    <Typography color="grayPlus" size="xs" fontFamily="avenirNextMedium">
                      {getContentV2(MARKET_ENUMS[betItems.marketType as keyof typeof MARKET_ENUMS])}
                    </Typography>

                    <div
                      className={classNames("marketCol", {
                        hideEl: betItems.marketName !== configData.finalResult,
                      })}
                    >
                      <div className="betDetails">
                        <Typography
                          color="white"
                          fontFamily="avenirNextBold"
                          size={"md2"}
                          isEllipsis={true}
                          width="60%"
                        >
                          {getTeamName(betItems.oddsDetails.win as string)}
                        </Typography>
                        <Typography color="green" fontFamily="avenirNextBold" size="md2">
                          {betItems?.odd}
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={classNames("marketCol", {
                        hideEl: betItems.marketName !== configData.goalsOverUnder,
                      })}
                    >
                      <div className="goalsOverUnder">
                        <Typography color="white" fontFamily="avenirNextBold" size="md2">
                          {betItems.oddsDetails.type &&
                            betItems.oddsDetails.type.charAt(0).toUpperCase() + betItems.oddsDetails.type.slice(1)}
                        </Typography>
                        <Typography color="white" fontFamily="avenirNextBold" size="md2">
                          {betItems.oddsDetails.goals}
                        </Typography>{" "}
                        <Typography color="green" fontFamily="avenirNextBold" size="md2">
                          {betItems?.odd}
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={classNames("marketCol", {
                        hideEl: betItems.marketName !== configData?.bothTeamsToScore,
                      })}
                    >
                      <div className="betDetails">
                        <Typography color="white" fontFamily="avenirNextBold" size="md2">
                          {betItems.oddsDetails.value}
                        </Typography>{" "}
                        <Typography color="green" fontFamily="avenirNextBold" size="md2">
                          {betItems?.odd}
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={classNames("lex w-full justify-start pt-1", {
                        hideEl: betItems.marketName !== configData?.resultBothTeamsScore,
                      })}
                    >
                      <div className="betDetails">
                        <Typography
                          color="white"
                          fontFamily="avenirNextBold"
                          size={"md2"}
                          noWrap={true}
                          isEllipsis={true}
                          width="80%"
                        >
                          {getTeamName(betItems.oddsDetails.win as string)} / {betItems.oddsDetails.value}
                        </Typography>
                        <Typography color="green" fontFamily="avenirNextBold" size="md2">
                          {betItems?.odd}
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={classNames("marketCol", {
                        hideEl: betItems.marketName !== configData?.correctScoree,
                        flexCol: true,
                        "!w-[45%]": betItems.marketName.length > 15,
                      })}
                    >
                      <div className="betDetails">
                        <Typography color="white" fontFamily="avenirNextBold" size="md2">
                          {betItems.oddsDetails.team1} - {betItems.oddsDetails.team2}
                        </Typography>
                        <Typography color="green" fontFamily="avenirNextBold" size="md2">
                          {betItems?.odd}
                        </Typography>
                      </div>
                      <Typography color="white" fontFamily="avenirFont" size="md2" isEllipsis={true} width="100%">
                        {getTeamName(betItems.oddsDetails.win as string)}
                      </Typography>
                    </div>

                    <div
                      className={classNames("marketCol", {
                        hideEl: betItems.marketType !== configData?.firstLastAnytimeGoalScorerType,
                        flexCol: true,
                      })}
                    >
                      <div className="betDetails">
                        <Typography
                          color="white"
                          fontFamily="avenirNextBold"
                          size="md2"
                          isEllipsis={true}
                          width="100%"
                          noWrap={true}
                        >
                          {getPlayerName(betItems.oddsDetails.playerId, match?.allPlayers)}
                        </Typography>
                        <Typography color="green" fontFamily="avenirNextBold" size="md2">
                          {betItems?.odd}
                        </Typography>
                      </div>
                      <Typography color="white" fontFamily="avenirFont" size="md2" isEllipsis={true} width="100%">
                        {betItems?.oddsDetails?.playerId && getTeamName(betItems.oddsDetails.teamId as string)}
                      </Typography>
                    </div>

                    <div
                      className={classNames("marketCol", {
                        hideEl: betItems.marketName !== configData?.handicaps,
                        flexCol: true,
                      })}
                    >
                      <div className="betDetails">
                        <Typography
                          color="white"
                          fontFamily="avenirNextBold"
                          size={"md2"}
                          isEllipsis={true}
                          width="50%"
                        >
                          {getTeamName(betItems.oddsDetails.win as string)}
                        </Typography>
                        <Typography color="white" fontFamily="avenirNextBold" size={"md2"}>
                          {`${String(betItems.oddsDetails.handicap).startsWith("-") ? "" : "+"}${betItems.oddsDetails.handicap}`}
                        </Typography>
                        <Typography color="green" fontFamily="avenirNextBold" size={"md2"}>
                          {betItems?.odd}
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={classNames("marketCol", {
                        hideEl: betItems.marketName !== configData?.firstGoalScorerCorrectScore,
                        flexCol: true,
                      })}
                    >
                      <div className="betDetails">
                        {" "}
                        <Typography color="white" fontFamily="avenirNextBold" size="md2">
                          {getPlayerName(betItems.oddsDetails?.playerId, match?.allPlayers)} /
                          {` ${betItems.oddsDetails.team1}-${betItems.oddsDetails.team2} `}
                        </Typography>
                        <Typography color="green" fontFamily="avenirNextBold" size="md2">
                          {betItems?.odd}
                        </Typography>
                      </div>
                      <Typography color="white" fontFamily="avenirFont" size="md2" isEllipsis={true} width="100%">
                        {betItems?.oddsDetails?.playerId && getTeamName(betItems.oddsDetails.teamId as string)}
                      </Typography>
                    </div>

                    <div className="flex pt-1">
                      <Typography fontFamily="avenirNextMedium" size="xs2" color="grayPlus" noWrap={true}>
                        {`${getContentV2(translationKeys.BET_REF)} : ${betItems.betId}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classNames("cols")}>
                <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
                  {getContentV2(translationKeys.STAKE_TITLE)}
                </Typography>
                <Typography fontFamily="avenirNextBold" size={"md2"} isEllipsis={true} width="100%" noWrap={true}>
                  {`${setCurrencyFormat({ currency: betItems?.currency || "", getCurrencySymbol: true, value: betItems?.betAmount || 0, decimal: 2 })}`}
                </Typography>
              </div>
              <div className={classNames("cols")}>
                <Typography color="grayPlus" size="md2" fontFamily="avenirFont" noWrap={true}>
                  {getContentV2(translationKeys.TO_RETURN_TITLE)}
                </Typography>
                <Typography
                  fontFamily="avenirNextBold"
                  size={"md2"}
                  color={betItems?.toReturn && betItems?.toReturn > 0 ? "green" : "white"}
                  isEllipsis={true}
                  width="100%"
                  noWrap={true}
                >
                  {`${setCurrencyFormat({ currency: betItems?.currency || "", getCurrencySymbol: true, value: betItems?.toReturn || 0, decimal: 2 })}`}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Playcheck;
