import React from "react";
import GameRulesModal from "components/modals/GameRulesModal";
import StakeInfoModal from "components/modals/StakeInfoModal";
import ErrorPopUpModal from "components/modals/ErrorPopUpModal";
import MinMaxStakeModal from "components/modals/MinMaxStakeModal";
import VaultXpModal from "components/modals/VaultXpModal";
import CanvasPopUpModal from "./CanvasPopUpModal";

const ModalRenderer: React.FC = () => {
  return (
    <>
      <GameRulesModal />
      <StakeInfoModal />
      <ErrorPopUpModal />
      <CanvasPopUpModal />
      <MinMaxStakeModal />
      <VaultXpModal />
    </>
  );
};

export default ModalRenderer;
