import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/btts.module.css";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import sessionHelper from "service/sessionHelper";
import { debug } from "util/miscUtils";
import { OddsDisplayEnum, useAddUpdateBetMutation } from "generated/graphql";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { matchStatusEnums } from "core/enums";
import { getContent } from "i18n";
import { translationKeys } from "i18n/translationKeys.enums";

const BTTS = () => {
  const keyName = sessionHelper.oddsDetails?.btts?.name as string;
  const [openContent, setOpenContent] = useState(true);
  const [loadingIds, setLoadingIds] = useState(new Set()); // Tracks loading state by item ID
  const selectedBttsOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const BttsOddsDetails = sessionHelper.oddsDetails?.btts;
  debug({ event: "calling", message: "BTTS" });
  const { setSelectedOddsOptions, selectedOddsDisplay, matchStatus, setActiveOddForEnteringStake } = useGameStore(
    (state) => ({
      selectedOddsDisplay: state.selectedOddsDisplay,
      matchStatus: state.matchStatus,
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      setActiveOddForEnteringStake: state.setActiveOddForEnteringStake,
    }),
    shallow,
  );

  const { handleBoxClick, isAddingBetLoading, isRemovingBetLoading, error } = useHandleAddUpdateOdds();

  const handleUserInteraction = async (id: string, keyName: string) => {
    if (matchStatus === matchStatusEnums.betPlaced) return; // If the match is already placed, do nothing
    try {
      setLoadingIds((prev) => new Set(prev).add(id)); // Mark the specific box as loading
      let resp = await handleBoxClick(id, keyName, [...selectedBttsOptions]);
      setSelectedOddsOptions(resp || [], keyName);
      let getUpdatedOddsOptions = useGameStore.getState();
      const result = Object.entries(getUpdatedOddsOptions.selectedOddsOptions).find(([_, value]) => value.length);
      if (result) {
        //  the result will be like ["key", [array of values]];
        let activeInputBoxDetails = result[1][0];
        setActiveOddForEnteringStake({
          oddId: activeInputBoxDetails?.oddId,
          key: activeInputBoxDetails?.marketName,
          betId: activeInputBoxDetails?.betId,
        });
      } else {
        setActiveOddForEnteringStake({ oddId: "", key: "", betId: "" });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingIds((prev) => {
        const updated = new Set(prev);
        updated.delete(id); // Remove loading state for the box
        return updated;
      });
    }
  };

  return (
    <section className={classNames(styles.root)}>
      <Accordion
        open={openContent}
        boxHeading={getContent(translationKeys.BOTH_TEAMS_TO_SCORE) || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.boxList}>
          {BttsOddsDetails?.data.map((item, itemIndex) => (
            <section
              key={`${itemIndex}-${item.__typename}`}
              className={classNames(styles.list, {
                [styles.marginBottom]: itemIndex < BttsOddsDetails.data.length - 1, // Apply marginBottom to all except the last item
              })}
            >
              <Typography color="grayPlus" size="md" fontFamily="avenirFont">
                {getContent(item?.value?.toLowerCase())}
              </Typography>
              <div className={styles.col}>
                <BoxSelection
                  active={
                    selectedBttsOptions.length && selectedBttsOptions?.some((ele) => ele.oddId === item.id)
                      ? true
                      : false
                  }
                  onClick={() => handleUserInteraction(item.id, keyName)}
                  disableClick={isAddingBetLoading || isRemovingBetLoading}
                  value={
                    selectedOddsDisplay
                      ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                        ? item.fractionalOdd.toString()
                        : selectedOddsDisplay === OddsDisplayEnum.Decimal
                          ? item.decimalOdd.toString()
                          : item.americanOdd.toString()
                      : ""
                  }
                  isClicked={loadingIds.has(item.id)} // Check if this box is in a loading state
                />
              </div>
            </section>
          ))}
        </div>
      </Accordion>
    </section>
  );
};

export default React.memo(BTTS);
